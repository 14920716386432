import React, { useState, useEffect } from "react";

/** 
 * A small helper to delay rendering children by `delay` ms. 
 * Until that delay finishes, it shows a simple "Loading..." placeholder. 
 */
function LazyLoad({ delay, children }) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShouldRender(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  if (!shouldRender) {
    return <div style={{ color: "#999", fontStyle: "italic" }}>Loading chart...</div>;
  }
  return children;
}

function HeartRateAlwaysDisplay({ analysis }) {
  if (!analysis) {
    return <div>No Heart Rate Analysis available.</div>;
  }

  const avgBPM = analysis.getAverageBPM();
  const isStressed = analysis.isStressed();
  const stressColor = isStressed ? "red" : "green";
  
  const rawScore = analysis.getRelaxationScore ? analysis.getRelaxationScore() : null;
  const stressPercent =
    rawScore !== null && !isNaN(rawScore)
      ? (rawScore * 100).toFixed(0) + "%"
      : "-";

  return (
    <div
      style={{
        border: "1px solid #ccc",
        padding: "1em",
        marginTop: "1em"
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "1em"
        }}
      >
        <div style={{ flex: 1 }}>
          <p>
            Avg Heart Rate: <strong>{avgBPM}</strong>
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <p style={{ color: stressColor }}>
            State: <strong>{isStressed ? "Stressed" : "Calm"}</strong>
          </p>
        </div>
        <div style={{ flex: 1 }}>
          <p>
            Stress: <strong>{stressPercent}</strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HeartRateAlwaysDisplay;

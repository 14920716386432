// BackgroundMusicManager.js
import axios from "axios";

const backgroundMusicDefaultVolume = 0.05;
const customMusicDefaultVolume = 0.5;

const DEBUG = false; // Toggle as desired

function debugLog(...args) {
  if (DEBUG) {
    console.log("[BackgroundMusicManager]", ...args);
  }
}

/**
 * Calls the remote API to generate background music items using a custom prompt.
 */
async function generateAudioByPrompt(payload) {
  const url = `/api/generate`;
  debugLog("Calling generate API at:", url, "with payload:", payload);
  try {
    const response = await axios.post(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    debugLog("Response from generate API:", response.data);
    return response.data;
  } catch (err) {
    console.error(
      "Error in generateAudioByPrompt:",
      err.response ? err.response.data : err
    );
    throw err;
  }
}

/**
 * Retrieves audio information for the given audio IDs.
 */
async function getAudioInformation(audioIds) {
  const url = `/api/get?ids=${audioIds}`;
  debugLog("Fetching audio information from:", url);
  try {
    const response = await axios.get(url);
    debugLog("Audio information response:", response.data);
    return response.data;
  } catch (err) {
    console.error(
      "Error in getAudioInformation:",
      err.response ? err.response.data : err
    );
    throw err;
  }
}

/**
 * Stores the custom generated music on the local storage server.
 */
async function storeCustomMusic(audioUrl, prompt, title) {
  const url = `/api/music/store`;
  debugLog("Calling store-music API at:", url, "with prompt:", prompt, "title:", title, "and audioUrl:", audioUrl);
  try {
    const response = await axios.post(
      url,
      { prompt, audioUrl, title },
      { headers: { "Content-Type": "application/json" } }
    );
    debugLog("Response from store-music API:", response.data);
    return response.data;
  } catch (err) {
    console.error(
      "Error in storeCustomMusic:",
      err.response ? err.response.data : err
    );
    throw err;
  }
}

/**
 * Retrieves a playlist of local music files.
 * Expects the response in the format: { musicUrls: [...], selectionMethod: "gpt" or "random" }
 */
async function getLocalMusicPlaylist(prompt) {
  const url = `/api/music/playlist${prompt ? "?prompt=" + encodeURIComponent(prompt) : ""}`;
  debugLog("Fetching local music playlist from:", url);
  try {
    const response = await axios.get(url);
    debugLog("Local music playlist response:", response.data);
    if (response.data && Array.isArray(response.data.musicUrls)) {
      response.data.musicUrls = response.data.musicUrls.map(musicUrl => {
        if (musicUrl.startsWith("/music/")) {
          return `${musicUrl}`;
        }
        return musicUrl;
      });
    }
    return response.data;
  } catch (err) {
    console.error("Error in getLocalMusicPlaylist:", err.response ? err.response.data : err);
    throw err;
  }
}

export class BackgroundMusicManager {
  /**
   * @param {function(string):void} onProgress - Callback for progress updates.
   */
  constructor(onProgress) {
    debugLog("BackgroundMusicManager instance created.");
    this.onProgress = onProgress || ((msg) => debugLog("onProgress:", msg));
    // Target volumes
    this.backgroundTargetVolume = backgroundMusicDefaultVolume;
    this.customTargetVolume = customMusicDefaultVolume;
    // Playlists
    this.backgroundPlaylist = []; // Regular background music URLs.
    this.customPlaylist = [];     // Custom (stopMusic) track URLs.
    // Playback indices
    this.currentBackgroundIndex = 0;
    this.currentCustomIndex = 0;
    // Audio objects for background playback (using two for crossfade).
    this.activeBackgroundAudio = null;
    this.inactiveBackgroundAudio = null;
    // Audio objects for custom music (for single-track or crossfade).
    this.currentCustomAudio = null;
    this.inactiveCustomAudio = null;
    // Store current prompt for resuming.
    this.currentPrompt = "";
    // (Optionally, you can set an onCustomMusicStart callback here.)
    this.onCustomMusicStart = null;
  }

  // Add this method to your BackgroundMusicManager class:
  setGlobalVolume(volume) {
    // Adjust your base target volumes by multiplying with the global volume.
    this.backgroundTargetVolume = backgroundMusicDefaultVolume * volume;
    this.customTargetVolume = customMusicDefaultVolume * volume;
    
    // Update currently playing audio elements if they exist.
    if (this.activeBackgroundAudio) {
      this.activeBackgroundAudio.volume = this.backgroundTargetVolume;
    }
    if (this.inactiveBackgroundAudio) {
      this.inactiveBackgroundAudio.volume = this.backgroundTargetVolume;
    }
    if (this.currentCustomAudio) {
      this.currentCustomAudio.volume = this.customTargetVolume;
    }
  }

  pauseMusic() {
    // Pause both background audio objects if they exist.
    if (this.activeBackgroundAudio) {
      this.activeBackgroundAudio.pause();
    }
    if (this.inactiveBackgroundAudio) {
      this.inactiveBackgroundAudio.pause();
    }
    // Optionally, pause custom music as well if it's playing.
    if (this.currentCustomAudio) {
      this.currentCustomAudio.pause();
    }
    if (this.inactiveCustomAudio) {
      this.inactiveCustomAudio.pause();
    }
  }
  
  resumeMusic() {
    // Resume playback on the active background audio if it exists.
    if (this.activeBackgroundAudio) {
      this.activeBackgroundAudio.play().catch(err => console.error(err));
    }
    // Resume playback on the inactive audio as well.
    if (this.inactiveBackgroundAudio) {
      this.inactiveBackgroundAudio.play().catch(err => console.error(err));
    }
    // Optionally, resume custom music as well.
    if (this.currentCustomAudio) {
      this.currentCustomAudio.play().catch(err => console.error(err));
    }
    if (this.inactiveCustomAudio) {
      this.inactiveCustomAudio.play().catch(err => console.error(err));
    }
  }  

  /**
   * Stops any currently playing music (both background and custom) using a fade-out.
   */
  async stopMusicWithFade(fadeDuration = 5000) {
    debugLog("stopMusicWithFade");
    this.onProgress("Fading out all music...");
    const fadeOutAudio = (audio, targetVolume) => {
      return new Promise((resolve) => {
        const interval = 50;
        const steps = fadeDuration / interval;
        let currentStep = 0;
        const fadeInterval = setInterval(() => {
          currentStep++;
          audio.volume = Math.max(targetVolume - (targetVolume * (currentStep / steps)), 0);
          if (currentStep >= steps) {
            clearInterval(fadeInterval);
            resolve();
          }
        }, interval);
      });
    };
    const audios = [this.activeBackgroundAudio, this.inactiveBackgroundAudio, this.currentCustomAudio].filter(Boolean);
    await Promise.all(
      audios.map(audio =>
        fadeOutAudio(
          audio,
          audio === this.currentCustomAudio ? this.customTargetVolume : this.backgroundTargetVolume
        )
      )
    );
    audios.forEach((audio) => {
      audio.pause();
      audio.currentTime = 0;
    });
    this.activeBackgroundAudio = null;
    this.inactiveBackgroundAudio = null;
    this.currentCustomAudio = null;
    debugLog("finished stopMusicWithFade");
    this.onProgress("All music stopped.");
  }

  /**
   * Generates background music items using the remote API.
   */
  async generateBackgroundMusic(userPrompt, waitAudio = false) {
    debugLog("Generating background music for prompt:", userPrompt);
    this.onProgress("Initiating background music generation...");
    const payload = {
      prompt: "Background music for a meditation session on " + userPrompt,
      make_instrumental: true,
      wait_audio: waitAudio,
    };
    const data = await generateAudioByPrompt(payload);
    this.onProgress("Background music items generated.");
    return data;
  }

  /**
   * Loads and sets the background playlist using the local playlist API.
   */
  async loadBackgroundPlaylist(prompt) {
    this.currentPrompt = prompt;
    this.onProgress("Fetching background music playlist...");
    const fullPlaylist = await getLocalMusicPlaylist(prompt);
    if (!fullPlaylist || !fullPlaylist.musicUrls || fullPlaylist.musicUrls.length === 0) {
      throw new Error("No background music available.");
    }
    this.backgroundPlaylist = fullPlaylist.musicUrls;
    this.currentBackgroundIndex = 0;
    this.onProgress("Background playlist selected: " + this.backgroundPlaylist.join(", "));
  }

  /**
   * Plays the background playlist sequentially with crossfade.
   * Always only a single crossfade occurs between one track and the next.
   */
  async playSequentialBackgroundMusic(prompt) {
    await this.loadBackgroundPlaylist(prompt);
    const playTrack = async (audio, url) => {
      audio.src = url;
      audio.load();
      audio.volume = 0;
      await audio.play();
    };
    const crossfade = (outAudio, inAudio, duration = 5000) => {
      return new Promise((resolve) => {
        const interval = 50;
        const steps = duration / interval;
        let currentStep = 0;
        const fadeInterval = setInterval(() => {
          currentStep++;
          outAudio.volume = Math.max(this.backgroundTargetVolume * (1 - currentStep / steps), 0);
          inAudio.volume = Math.min(this.backgroundTargetVolume * (currentStep / steps), this.backgroundTargetVolume);
          if (currentStep >= steps) {
            clearInterval(fadeInterval);
            resolve();
          }
        }, interval);
      });
    };

    // Ensure we have two audio objects.
    if (!this.activeBackgroundAudio) {
      this.activeBackgroundAudio = new Audio();
    }
    if (!this.inactiveBackgroundAudio) {
      this.inactiveBackgroundAudio = new Audio();
    }
    let activeAudio = this.activeBackgroundAudio;
    let inactiveAudio = this.inactiveBackgroundAudio;
    const playlist = this.backgroundPlaylist;
    let index = this.currentBackgroundIndex;

    // Play the first track.
    await playTrack(activeAudio, playlist[index]);
    activeAudio.volume = this.backgroundTargetVolume;
    this.onProgress("Playing background music: " + playlist[index]);

    // Define a stable timeupdate handler.
    const timeUpdateHandler = async () => {
      if (activeAudio.duration - activeAudio.currentTime <= 5) {
        activeAudio.removeEventListener("timeupdate", timeUpdateHandler);
        index = playlist.length > 1 ? (index + 1) % playlist.length : index;
        const nextUrl = playlist[index];
        inactiveAudio.pause();
        inactiveAudio.src = "";
        inactiveAudio.volume = 0;
        await playTrack(inactiveAudio, nextUrl);
        this.onProgress("Crossfading to background track: " + nextUrl);
        await crossfade(activeAudio, inactiveAudio, 5000);
        activeAudio.pause();
        // Swap the audio objects.
        [activeAudio, inactiveAudio] = [inactiveAudio, activeAudio];
        this.currentBackgroundIndex = index;
        activeAudio.addEventListener("timeupdate", timeUpdateHandler);
      }
    };

    activeAudio.addEventListener("timeupdate", timeUpdateHandler);
  }

  // Add this new method to BackgroundMusicManager class:
async addToCustomPlaylist(customAudioUrl) {
  if (!this.customPlaylist) {
    debugLog("Creating custom playlist array...");
    this.customPlaylist = [];
  }
  this.customPlaylist.push(customAudioUrl);
  debugLog("Added custom music item: " + customAudioUrl);
  this.onProgress("Added custom music item: " + customAudioUrl);
}

  /**
   * Sets the custom music playlist.
   * @param {Array<string>} playlist - Array of custom music track URLs.
   */
  async setCustomPlaylist(playlist) {
    if (!playlist || playlist.length === 0) {
      throw new Error("No custom music available.");
    }
    this.customPlaylist = playlist;
    this.currentCustomIndex = 0;
    this.onProgress("Custom music playlist set: " + this.customPlaylist.join(", "));
  }

  /**
   * Plays the custom music playlist sequentially with crossfade.
   * If only one track is available, it fades into itself.
   */
  async playSequentialCustomMusic() {
    if (!this.customPlaylist || this.customPlaylist.length === 0) {
      throw new Error("Custom playlist is empty.");
    }
    const targetVolume = this.customTargetVolume;
    const playTrack = async (audio, url) => {
      audio.src = url;
      audio.load();
      audio.volume = 0;
      await audio.play();
    };
    const crossfade = (outAudio, inAudio, duration = 5000) => {
      return new Promise((resolve) => {
        const interval = 50;
        const steps = duration / interval;
        let currentStep = 0;
        const fadeInterval = setInterval(() => {
          currentStep++;
          outAudio.volume = Math.max(targetVolume * (1 - currentStep / steps), 0);
          inAudio.volume = Math.min(targetVolume * (currentStep / steps), targetVolume);
          if (currentStep >= steps) {
            clearInterval(fadeInterval);
            resolve();
          }
        }, interval);
      });
    };

    if (this.customPlaylist.length === 1) {
      if (!this.currentCustomAudio) {
        this.currentCustomAudio = new Audio();
      }
      const audio = this.currentCustomAudio;
      const url = this.customPlaylist[0];
      await playTrack(audio, url);
      audio.volume = targetVolume;
      this.onProgress("Playing custom music (single track): " + url);
      audio.addEventListener("ended", async () => {
        await crossfade(audio, audio, 5000);
        audio.currentTime = 0;
        await audio.play();
      });
    } else {
      if (!this.currentCustomAudio) {
        this.currentCustomAudio = new Audio();
      }
      if (!this.inactiveCustomAudio) {
        this.inactiveCustomAudio = new Audio();
      }
      let activeAudio = this.currentCustomAudio;
      let inactiveAudio = this.inactiveCustomAudio;
      const playlist = this.customPlaylist;
      let index = this.currentCustomIndex;
      await playTrack(activeAudio, playlist[index]);
      activeAudio.volume = targetVolume;
      this.onProgress("Playing custom music: " + playlist[index]);

      const timeUpdateHandler = async () => {
        if (activeAudio.duration - activeAudio.currentTime <= 5) {
          activeAudio.removeEventListener("timeupdate", timeUpdateHandler);
          index = (index + 1) % playlist.length;
          const nextUrl = playlist[index];
          inactiveAudio.pause();
          inactiveAudio.src = "";
          inactiveAudio.volume = 0;
          await playTrack(inactiveAudio, nextUrl);
          this.onProgress("Crossfading to custom track: " + nextUrl);
          await crossfade(activeAudio, inactiveAudio, 5000);
          activeAudio.pause();
          [activeAudio, inactiveAudio] = [inactiveAudio, activeAudio];
          this.currentCustomIndex = index;
          activeAudio.addEventListener("timeupdate", timeUpdateHandler);
        }
      };

      activeAudio.addEventListener("timeupdate", timeUpdateHandler);
    }
  }

  /**
   * Fades from background music to custom music.
   * This method stops any background music, then sets a single-item custom playlist
   * and begins custom music playback.
   */
  async fadeToCustomMusic(customAudioUrl, fadeDuration = 5000) {
    debugLog("Fading to custom music...");
    this.onProgress("Fading to custom music...");
    await this.stopMusicWithFade(fadeDuration);
    // Set custom playlist to the provided track.
    if (customAudioUrl) {
      debugLog("Setting custom playlist to single track:", customAudioUrl);
      await this.setCustomPlaylist([customAudioUrl]);
    }
    // Play the custom music.
    await this.playSequentialCustomMusic();
    
    if (this.onCustomMusicStart && typeof this.onCustomMusicStart === "function") {
      this.onCustomMusicStart();
    }
  }

  /**
   * Generates custom music via the remote API, stores it, and returns the chosen track.
   */
  async getAndStoreCustomMusic(userPrompt, title) {
    const musicData = await this.generateBackgroundMusic(userPrompt, false);
    if (!musicData || !musicData[0] || !musicData[1]) {
      throw new Error("Incomplete music data.");
    }
    const trackPromises = musicData.map(track => {
      if (track.audio_url) return Promise.resolve(track.audio_url);
      return this.waitForStreamingAudioForId(track.id);
    });
    const chosenResult = await Promise.race(
      trackPromises.map(p => p.then(url => ({ url })))
    );
    const chosenUrl = chosenResult.url;
    try {
      await Promise.all(
        trackPromises.map(urlPromise => urlPromise.then(url => storeCustomMusic(url, userPrompt, title)))
      );
      this.onProgress("Custom music stored. Music list updated.");
    } catch (err) {
      console.error("Error storing custom music:", err);
    }
    this.onProgress("Custom music generation complete. Custom music is available for later use.");
    return { chosenUrl, musicData };
  }

  /**
   * Waits for streaming audio information for a given track id.
   */
  async waitForStreamingAudioForId(id, pollInterval = 1000, maxPolls = 20) {
    for (let i = 0; i < maxPolls; i++) {
      this.onProgress(`Polling for track ${id} to stream... (${i + 1}/${maxPolls})`);
      const audioInfo = await getAudioInformation(id);
      if (Array.isArray(audioInfo)) {
        if (audioInfo[0]?.status === "streaming" && audioInfo[0]?.audio_url) {
          return audioInfo[0].audio_url;
        }
      } else {
        if (audioInfo?.status === "streaming" && audioInfo?.audio_url) {
          return audioInfo.audio_url;
        }
      }
      await new Promise((resolve) => setTimeout(resolve, pollInterval));
    }
    throw new Error("Timeout waiting for track id " + id + " to stream.");
  }
}

export default BackgroundMusicManager;

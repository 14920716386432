// HRVToolTips.js
class HRVToolTips {
    static sdnn =
      "SDNN (Standard Deviation of NN intervals, ms) – Represents overall HRV. In short-term readings (e.g. 5 min), typical SDNN is around 20–50 ms for a healthy adult. Higher SDNN generally means more variability (a relaxed state), while lower SDNN may suggest stress or reduced autonomic function.";
  
    static rmssd =
      "RMSSD (Root Mean Square of Successive Differences, ms) – Reflects short-term beat-to-beat variability. Typical values are roughly 20–90 ms. Higher RMSSD indicates strong parasympathetic tone and a relaxed state, while lower RMSSD suggests stress or fatigue.";
  
    static pnn50 =
      "pNN50 – The percentage of successive NN intervals that differ by more than 50 ms. A higher percentage generally indicates better parasympathetic activity (relaxation), whereas a lower percentage can imply stress.";
  
    static apen =
      "ApEn (Approximate Entropy) – Measures the regularity/complexity of the heart rate time series. Values usually range between 0 and 2. Higher ApEn indicates more complexity (healthy variability), while lower ApEn suggests a more predictable, possibly stressed, rhythm.";
  
    static sampen =
      "SampEn (Sample Entropy) – A refined measure of time-series irregularity. Higher SampEn suggests healthy complexity in heart rate dynamics, while lower values indicate increased regularity which may be associated with stress.";
  
    static dfa =
      "DFA α1 (Detrended Fluctuation Analysis) – Reflects fractal-like correlations in the heart rate series. Values near 1.0 (±0.2) are considered normal. Values significantly lower (≈0.5) or higher (≈1.3–1.5) may indicate stress or altered autonomic balance.";
  
    static ac =
      "Acceleration Capacity (AC, ms) – Measures the heart’s ability to speed up. Healthy values are usually a few milliseconds. Higher AC may indicate strong sympathetic responsiveness, which in a resting state might suggest a heightened or stressed state.";
  
    static dc =
      "Deceleration Capacity (DC, ms) – Measures the heart’s ability to slow down. A moderate, positive DC (around 5–10 ms) indicates good vagal tone and relaxation. Very low DC can signal stress or impaired parasympathetic function.";
  
    static coherence =
      "Coherence Score – The ratio of the peak power to total power in the 0.04–0.4 Hz range. Higher coherence suggests a more ordered and relaxed heart rate variability pattern.";
  
    static rsa =
      "RSA Amplitude – Reflects the amplitude of respiratory sinus arrhythmia. Higher values indicate stronger parasympathetic (relaxation) influence.";
  }
  
  export default HRVToolTips;
  
import ECGToHR from './ECGToHR.js';

class HeartRateMonitorPolar {
  constructor() {
    // Polar PMD service UUIDs
    this.PMD_SERVICE = 'fb005c80-02e7-f387-1cad-8acd2d8df0c8';
    this.PMD_CONTROL_CHAR = 'fb005c81-02e7-f387-1cad-8acd2d8df0c8';
    this.PMD_DATA_CHAR = 'fb005c82-02e7-f387-1cad-8acd2d8df0c8';
    // For fallback PPI streaming (alias to same as control)
    this.PMD_CTRL_CHAR = this.PMD_CONTROL_CHAR;

    // Device and GATT server handles
    this.device = null;
    this.server = null;
    // Standard GATT HR measurement characteristic (UUID 2A37)
    this.hrmChar = null;
    
    // Characteristics for ECG/HR notifications
    this.ecgControlChar = null;
    this.ecgDataChar = null;

    // Characteristics for fallback PPI streaming
    this.pmdControl = null;
    this.pmdData = null;

    // Fallback/SDK mode state
    this._sdkModeAttempted = false;

    // Minimal event emitter pattern
    this._listeners = {};

    // Instantiate the ECG-to-HR processor (e.g., using Pan–Tompkins)
    this.ecgProcessor = new ECGToHR(130);

    // For optional debugging
    this._debug = false;

    // Internal queue for serializing GATT operations
    this._gattQueue = Promise.resolve();

    // New fields to support standard HR filtering for ECG beats
    this.lastStandardHR = null;         // Last BPM from the GATT heart rate service
    this.ecgProcessingEnabled = false;  // Will be enabled once a standard HR beat is received

    // Track the timestamp of the last ECG data received
    this.lastEcgDataTimestamp = Date.now();
    
    this.lastEcgConnectTimestamp = Date.now();

    this.ecgAvailable = false;

    // Set a threshold (in milliseconds) for what counts as "no data"
    this._dataTimeoutThreshold = 5000; // e.g., 5 seconds

    // Start a watchdog timer to monitor data flow
    this._dataWatchdogInterval = setInterval(() => {
      const now = Date.now();
      const elapsed = now - this.lastEcgDataTimestamp;
      const elapsedSinceConnect = now - this.lastEcgConnectTimestamp;
      if (this.ecgAvailable && elapsed > this._dataTimeoutThreshold && elapsedSinceConnect > this._dataTimeoutThreshold) {
        console.warn(`No ECG data received for ${elapsed} ms.`);
        // Emit an event so that other parts of your app can react
        this._emit('status', { type: 'no_data_timeout', elapsed });
        // Attempt to restart the connection if needed
        this._restartGatt();
      }
    }, this._dataTimeoutThreshold);
  }

  /**
   * Attempts to gracefully restart the GATT connection.
   * This could involve disconnecting and then reconnecting.
   */
  async _restartGatt() {
    console.log("Attempting to restart GATT connection due to no data...");
    try {
      if (this.device && this.device.gatt && this.device.gatt.connected) {
        this.disconnect();
      }
      // Optionally, wait a short period before attempting to reconnect
      setTimeout(async () => {
        try {
          // Depending on your workflow, you might need to request the device again
          // or you might be able to reconnect directly if you have a reference.
          await this.connect(this.device);
          console.log("GATT connection restarted successfully.");
        } catch (reconnectError) {
          console.error("Failed to restart GATT connection:", reconnectError);
        }
      }, 1000);
    } catch (error) {
      console.error("Error during GATT restart:", error);
    }
  }

  // Ensure you clear the watchdog timer when disconnecting
  disconnect() {
    if (this._dataWatchdogInterval) {
      clearInterval(this._dataWatchdogInterval);
      this._dataWatchdogInterval = null;
    }
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      this.device.gatt.disconnect();
      console.log('Polar device disconnected by user request.');
    }
  }

  /**
   * Queue a GATT operation so only one is active at a time.
   */
  // Add a helper method to delay execution:
  _delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


  async _queueGattOperation(operation) {
    this._gattQueue = this._gattQueue.then(async () => {
      const result = await operation();
      // Wait 100ms (adjust as needed) before processing the next operation
      await this._delay(500);
      return result;
    });
    return this._gattQueue;
  }


  setAnalysis(analysisInstance) {
    this.analysis = analysisInstance;
  }

  /**
   * Register event listeners:
   *  - 'reading': fired once per beat (parameter is BPM)
   *  - 'connected', 'disconnected'
   *  - 'fallback': indicates fallback to standard HR (or PPI) streaming.
   */
  on(eventName, callback) {
    if (!this._listeners[eventName]) {
      this._listeners[eventName] = [];
    }
    this._listeners[eventName].push(callback);
  }

  _emit(eventName, data) {
    const listeners = this._listeners[eventName];
    if (listeners) {
      listeners.forEach(cb => cb(data));
    }
  }

  /**
   * Connect to the given device and try to start ECG streaming first.
   * If ECG streaming is available, we also subscribe to the standard heart rate service.
   * The ECG-to-HR algorithm is only activated after receiving a standard HR beat.
   */
  async connect(device) {
    this.device = device;
    this.device.addEventListener('gattserverdisconnected', this._onDisconnected.bind(this));
    this.server = await device.gatt.connect();
    console.log(`Polar device GATT server connected: ${device.name}`);
    this._emit('connected', device);

    this.ecgAvailable = false;
    try {
      // --- Attempt to set up ECG streaming via the Polar PMD service ---
      const pmdService = await this.server.getPrimaryService(this.PMD_SERVICE);
      // Get control and data characteristics for ECG
      this.ecgControlChar = await pmdService.getCharacteristic(this.PMD_CONTROL_CHAR);
      this.ecgDataChar = await pmdService.getCharacteristic(this.PMD_DATA_CHAR);
      this.lastEcgConnectTimestamp = Date.now();
      // Subscribe to ECG data notifications
      await this.ecgDataChar.startNotifications();
      this.lastEcgConnectTimestamp = Date.now();
      this.ecgDataChar.addEventListener('characteristicvaluechanged', this.handleEcgData.bind(this));
      // Subscribe to ECG control notifications for ACKs/responses
      await this.ecgControlChar.startNotifications();
      this.lastEcgConnectTimestamp = Date.now();
      this.ecgControlChar.addEventListener('characteristicvaluechanged', this.handleEcgControl.bind(this));

      console.log('ECG service found, starting ECG stream...');
      // Write control commands to request ECG streaming:
      const reqStream = new Uint8Array([0x01, 0x02]); // Request streaming mode
      const reqEcg = new Uint8Array([0x01, 0x00]);    // Request ECG measurement type
      await this._queueGattOperation(() => this.ecgControlChar.writeValue(reqStream));
      await this._queueGattOperation(() => this.ecgControlChar.writeValue(reqEcg));
      this.lastEcgConnectTimestamp = Date.now();
      this.ecgAvailable = true;
    } catch (error) {
      console.error('ECG stream not available, falling back to HR/PPI only:', error);
      this.ecgAvailable = false;
    }

    if (this.ecgAvailable) {
      // --- Subscribe to the standard Heart Rate service notifications ---
      try {
        const hrService = await this.server.getPrimaryService('heart_rate');
        this.hrmChar = await hrService.getCharacteristic('heart_rate_measurement');
        await this.hrmChar.startNotifications();
        this.hrmChar.addEventListener('characteristicvaluechanged', this.handleStandardHRMeasurement.bind(this));
        console.log('Standard HR service notifications subscribed.');
      } catch (e) {
        console.warn('Standard HR service not available:', e);
      }
    } else {
      // --- Fallback to PPI Streaming if ECG is unavailable ---
      try {
        const pmdService = await this.server.getPrimaryService(this.PMD_SERVICE);
        this.pmdControl = await pmdService.getCharacteristic(this.PMD_CTRL_CHAR);
        this.pmdData = await pmdService.getCharacteristic(this.PMD_DATA_CHAR);

        await this.pmdControl.startNotifications().catch(e => {});
        this.pmdControl.addEventListener('characteristicvaluechanged', this._handlePmdControlResponse.bind(this));

        await this.pmdData.startNotifications();
        this.pmdData.addEventListener('characteristicvaluechanged', this._handlePmdData.bind(this));

        console.log('PPI streaming starting...');
        await this._startPpiStream();
      } catch (err) {
        console.error('Failed to start fallback PPI streaming:', err);
        throw err;
      }
    }
  }

  _onDisconnected(event) {
    const dev = event.target;
    console.warn(`Polar device disconnected: ${dev.name}`);
    this._emit('disconnected', dev);
  }

  disconnect() {
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      this.device.gatt.disconnect();
      console.log('Polar device disconnected by user request.');
    }
  }

  // ============================================================
  // Standard HR Measurement Handler (GATT heart rate service)
  // ============================================================
  handleStandardHRMeasurement(event) {
    const data = event.target.value;
    if (!data) return;
    const dv = new DataView(data.buffer);
    let index = 0;
    const flags = dv.getUint8(index++);
    let hr;
    if (flags & 0x01) {
      hr = dv.getUint16(index, true);
      index += 2;
    } else {
      hr = dv.getUint8(index++);
    }
    // Update our baseline standard HR value.
    this.lastStandardHR = hr;
    // Once we receive our first standard HR beat, enable ECG-to-HR processing.
    if (!this.ecgProcessingEnabled) {
      this.ecgProcessingEnabled = true;
      console.log('Standard HR reading received, enabling ECG processing.');
    }
    // DON'T EMIT THIS, IT'S ONLY FOR CHECKING ECG    
    console.log('Standard HR reading received (unused except for comparing with ECG): ' + hr);
    //this._emit('reading', hr);
  }

  // ============================================================
  // ECG and Standard HR Methods
  // ============================================================

  /**
   * Handler for ECG control notifications.
   * Waits for the ACK (0xF0 0x01) then sends the start command.
   */
  handleEcgControl(event) {
    const data = new Uint8Array(event.target.value.buffer);
    if (data.length >= 2 && data[0] === 0xF0 && data[1] === 0x01) {
      console.log('ECG control ACK received, sending start command...');
      // Prepare the start command: 130 Hz sampling, 14-bit resolution, etc.
      const startEcg = new Uint8Array([0x02, 0x00, 0x00, 0x01, 0x82, 0x00, 0x01, 0x01, 0x0E, 0x00]);
      this._queueGattOperation(() => this.ecgControlChar.writeValue(startEcg))
        .then(() => {
          console.log('ECG streaming started!');
        })
        .catch(e => {
          console.error('Failed to write ECG start command:', e);
        });
    }
  }

  /**
   * Handler for incoming ECG data frames.
   * Processes 3-byte ECG samples (after skipping a 10-byte header)
   * through the ECGToHR algorithm.
   *
   * ECG processing is only active once a standard HR beat has been received.
   * Furthermore, any ECG-derived BPM is only emitted if it is within ±40%
   * of the standard HR service value.
   */
  handleEcgData(event) {
    const value = event.target.value;
    if (!value) return;
    
    // Update the last data timestamp on each notification
    this.lastEcgDataTimestamp = Date.now();

    // Only process ECG data if standard HR is available.
    if (!this.ecgProcessingEnabled) {
      return;
    }
    const data = new DataView(value.buffer);
    const frameLength = data.byteLength;
    if (frameLength < 13) {
      // Frame too short; ignore.
      return;
    }
    // Skip the 10-byte header:
    const startIndex = 10;
    for (let i = startIndex; i + 2 < frameLength; i += 3) {
      const b0 = data.getUint8(i);
      const b1 = data.getUint8(i + 1);
      const b2 = data.getInt8(i + 2); // signed most significant byte
      const rawEcg = b0 + (b1 << 8) + (b2 << 16);

      // Optionally pass the raw ECG sample to an analysis instance
      if (this.analysis && typeof this.analysis.addECGSample === 'function') {
        this.analysis.addECGSample(rawEcg);
      }

      // Process the sample with the ECG-to-HR algorithm.
      const maybeBpm = this.ecgProcessor.processSample(rawEcg);
      
      if (maybeBpm != null) {
        // Only emit the ECG-derived beat if we have a baseline standard HR
        // and the value is within ±40% of that standard HR.
        if (this.lastStandardHR !== null) {
          const lowerBound = this.lastStandardHR * 0.5;
          const upperBound = this.lastStandardHR * 2.0;
          const multipleOfAveragedBeat = maybeBpm / this.lastStandardHR;
          if (maybeBpm >= lowerBound && maybeBpm <= upperBound) {
            this._emitReading(maybeBpm);
            if (this._debug) {
              console.log(`ECG beat accepted: BPM=${maybeBpm} Multiple of standard: ${multipleOfAveragedBeat} of ${this.lastStandardHR})`);
            }
          } else {
            console.log(`DISCARDING ECG beat: ${maybeBpm} Multiple of standard: ${multipleOfAveragedBeat} of ${this.lastStandardHR})`);
          }
        } else {
          console.log("Discarding ECG beat because no standard HR available yet.");
        }
      }
    }
  }

  /**
   * Helper to emit BPM readings only if they are below 250 BPM.
   */
  _emitReading(bpm) {
    if (bpm > 250) {
      console.log(`Ignoring BPM value above threshold: ${bpm}`);
      return;
    }
    this._emit('reading', bpm);
  }

  // ============================================================
  // Fallback PPI Streaming Methods
  // ============================================================
  async _enableSdkMode() {
    console.log("Attempting to enable SDK mode...");
    const cmd = new Uint8Array([0x02, 0x09]); // Start, measurement type = SDK mode
    await this._queueGattOperation(() => this.pmdControl.writeValue(cmd));
    console.log("SDK mode command sent.");
    this._sdkModeAttempted = true;
  }

  async _startPpiStream() {
    await this._getPpiSettings();
    const cmd = new Uint8Array([0x02, 0x03]); // Start measurement for PPI
    await this._queueGattOperation(() => this.pmdControl.writeValue(cmd));
    console.log("PPI start command written (step 2). Waiting for data...");
  }

  async _getPpiSettings() {
    const cmd = new Uint8Array([0x01, 0x03]); // Get measurement settings, type = PPI
    await this._queueGattOperation(() => this.pmdControl.writeValue(cmd));
    console.log("Requested PPI settings (step 1)...");
  }

  _handlePmdData(event) {
    const dv = event.target.value;
    const arr = new Uint8Array(dv.buffer);
    if (arr[0] !== 0x03) {
      return;
    }
    let offset = 1;
    const sampleCount = arr[offset];
    offset += 1;
    for (let i = 0; i < sampleCount; i++) {
      if (offset + 5 < arr.length) {
        const flags = arr[offset];
        const ppLow = arr[offset + 1];
        const ppHigh = arr[offset + 2];
        const ppi = (ppHigh << 8) | ppLow;  // in ms
        const errLow = arr[offset + 3];
        const errHigh = arr[offset + 4];
        const ppiError = (errHigh << 8) | errLow;
        const bpm = arr[offset + 5];
        offset += 6;
        this._emitReading(bpm);
        console.log(
          `PPI sample: BPM=${bpm}, PP=${ppi}ms, Error=${ppiError}, Flags=0x${flags.toString(16)}`
        );
      } else {
        console.warn("PPI frame parsing error: truncated data");
        break;
      }
    }
  }

  _handlePmdControlResponse(event) {
    const arr = new Uint8Array(event.target.value.buffer);
    if (arr[0] === 0xF0) {
      const op = arr[1];
      const type = arr[2];
      const status = arr[3];
      if (status !== 0x00) {
        console.error(
          `PMD Control error 0x${status.toString(16)} for type=0x${type.toString(16)}`
        );
        if (status === 0x03 && op === 0x02 && type === 0x03) {
          if (!this._sdkModeAttempted) {
            this._enableSdkMode().then(() => {
              this._startPpiStream().catch(e => {
                console.error("Still failed to start PPI after SDK mode:", e);
                this._emit('fallback', this.device);
              });
            }).catch(e => {
              console.error("Failed to enable SDK mode:", e);
              this._emit('fallback', this.device);
            });
          } else {
            console.warn("PPI not supported even after SDK mode => fallback.");
            this._emit('fallback', this.device);
          }
        } else {
          console.warn("PMD Control gave an error => fallback to standard HR.");
          this._emit('fallback', this.device);
        }
      } else {
        if (op === 0x02 && type === 0x03) {
          console.log("PMD Control response: success (0x00). PPI streaming started!");
        } else if (op === 0x01 && type === 0x03) {
          console.log("PPI settings response: success (0x00). Proceeding to start PPI...");
        }
      }
    }
  }
}

export default HeartRateMonitorPolar;

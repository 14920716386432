/**
 * HeartRateMonitor.js
 *
 * Main entry point for connecting to a BLE heart rate sensor.
 * This version integrates improvements from Java code:
 *  - We parse *all* RR intervals from each notification (if present).
 *  - We optionally support a delayed queue approach (delayedRRIntervalQueue).
 *  - We have fallback logic if intervals disappear.
 */

import HeartRateMonitorPolar from './HeartRateMonitorPolar.js';

function isAndroidBrowser() {
  // Make sure navigator is defined and we have a userAgent string
  if (typeof navigator !== 'undefined' && navigator.userAgent) {
    return /Android/i.test(navigator.userAgent);
  }
  return false;
}

class HeartRateMonitor {
  constructor(options = {}) {
    // Standard UUIDs
    this.HEART_RATE_SERVICE = 'heart_rate';               // 0x180D
    this.HEART_RATE_MEASUREMENT = 'heart_rate_measurement'; // 0x2A37
    this.BATTERY_SERVICE = 'battery_service';             // 0x180F
    this.BATTERY_LEVEL_CHAR = 'battery_level';            // 0x2A19

    this.device = null;
    this.server = null;
    this.hrChar = null;
    this._listeners = {};

    // If we detect a specifically SDK-supported Polar device, we delegate:
    this.polarMonitor = null;

    // --- RR Interval / fallback handling ---
    // If `useRRIntervals` is true, we parse RR intervals from standard HR service.
    // If the device is a supported Polar model, we automatically delegate to HeartRateMonitorPolar.
    // Check if the user explicitly passed useRRIntervals,
    // otherwise default to 'true' if we're on Android, 'false' otherwise:
    const defaultUseRR = isAndroidBrowser();
    this.useRRIntervals = (typeof options.useRRIntervals === 'boolean')
      ? options.useRRIntervals
      : defaultUseRR;

    // If `delayedRRIntervalQueue` is true, we batch newly discovered intervals
    // into a single array and emit them once the entire packet is processed.
    this.delayedRRIntervalQueue = options.delayedRRIntervalQueue || false;

    // For fallback if intervals disappear
    this.lastRRTime = 0;
    this.lastRRs = [];
    this.supportsRR = false; // Will be set to true if the device sets bit4
    this.RR_FALLBACK_TIMEOUT = 3000; // 3 seconds
  }

  /**
   * Register event listeners:
   *  - "reading": param is BPM, called once per beat or per new interval(s).
   *  - "connected", "disconnected"
   */
  on(eventName, callback) {
    if (!this._listeners[eventName]) {
      this._listeners[eventName] = [];
    }
    this._listeners[eventName].push(callback);
  }

  _emit(eventName, data) {
    const list = this._listeners[eventName];
    if (list) {
      list.forEach(cb => cb(data));
    }
  }

  /**
   * Opens the browser's BLE device picker and attempts connection.
   * If device is a modern Polar with PMD, delegate to HeartRateMonitorPolar.
   * Otherwise, we do the standard approach with optional RR parsing.
   */
  async connect() {
    if (!navigator.bluetooth) {
      console.error('Web Bluetooth API not supported in this browser.');
      return;
    }

    try {
      // 1) Include all relevant services in "optionalServices" to avoid SecurityError
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [this.HEART_RATE_SERVICE] }],
        optionalServices: [
          this.HEART_RATE_SERVICE,
          this.BATTERY_SERVICE,
          'device_information',
          // Polar PMD (for raw ECG, PPI, etc.)
          'fb005c80-02e7-f387-1cad-8acd2d8df0c8',
          'fb005c81-02e7-f387-1cad-8acd2d8df0c8',
          'fb005c82-02e7-f387-1cad-8acd2d8df0c8',
        ],
      });

      this.device = device;
      console.log(`Selected device: ${device.name || 'Unnamed'} (ID: ${device.id})`);

      device.addEventListener('gattserverdisconnected', this._onDisconnected.bind(this));

      // Small delay—some devices misbehave if we connect too quickly
      await new Promise(resolve => setTimeout(resolve, 1500));

      const isSupportedPolar = this._isPolarSdkDevice(device.name);
      if (isSupportedPolar) {
        console.log(`Using HeartRateMonitorPolar for ${device.name}.`);
        this.polarMonitor = new HeartRateMonitorPolar();

        // Mirror events from the polar monitor to this instance
        this.polarMonitor.on('reading', bpm => this._emit('reading', bpm));
        this.polarMonitor.on('connected', dev => this._emit('connected', dev));
        this.polarMonitor.on('disconnected', dev => this._emit('disconnected', dev));

        // If PMD PPI fails, fallback to standard approach
        this.polarMonitor.on('fallback', async (dev) => {
          console.warn('PPI not supported or failed. Fallback to standard Heart Rate service.');
          await this._setupStandardHeartRate(dev);
        });

        await this.polarMonitor.connect(device);
      } else {
        // Standard approach
        await this._setupStandardHeartRate(device);
      }
    } catch (error) {
      console.error('Failed to connect or retrieve services:', error);
      throw error;
    }
  }

  /**
   * If not using PMD, set up standard HRM notifications. Optionally parse RR intervals.
   */
  async _setupStandardHeartRate(device) {
    if (!device.gatt.connected) {
      this.server = await device.gatt.connect();
      console.log('GATT server connected (standard HR).');
      this._emit('connected', device);
    } else {
      this.server = device.gatt;
      console.log('Already connected to GATT (standard HR).');
    }

    let hrService, hrMeasurementChar;
    try {
      hrService = await this.server.getPrimaryService(this.HEART_RATE_SERVICE);
    } catch (e) {
      console.error('Heart Rate service not found:', e);
      throw e;
    }

    try {
      hrMeasurementChar = await hrService.getCharacteristic(this.HEART_RATE_MEASUREMENT);
    } catch (e) {
      console.error('Heart Rate Measurement characteristic not found:', e);
      throw e;
    }

    this.hrChar = hrMeasurementChar;

    // Subscribe to notifications
    await this.hrChar.startNotifications();
    this.hrChar.addEventListener(
      'characteristicvaluechanged',
      this._handleHeartRateMeasurement.bind(this)
    );
    console.log('Subscribed to heart rate notifications.');

    // Attempt to read battery level
    try {
      const batteryService = await this.server.getPrimaryService(this.BATTERY_SERVICE);
      const batteryChar = await batteryService.getCharacteristic(this.BATTERY_LEVEL_CHAR);
      const batteryValue = await batteryChar.readValue();
      const batteryPercent = batteryValue.getUint8(0);
      console.log(`Battery Level: ${batteryPercent}%`);
    } catch (err) {
      console.warn('Battery service not found or unavailable:', err);
    }
  }

  /**
   * Check if device is a known Polar model that supports the Polar SDK PMD approach
   */
  _isPolarSdkDevice(deviceName = '') {
    const lowerName = deviceName.toLowerCase();
    const sdkDevices = [
      'polar 360',
      'polar h10',
      'polar h9',
      'polar verity sense',
      'polar oh1',
      'polar ignite 3',
      'polar vantage v3',
      'polar grit x2 pro',
      'polar pacer',
      'polar pacer pro'
    ];
    return sdkDevices.some(model => lowerName.includes(model));
  }

  /**
   * Parse the standard Heart Rate Measurement characteristic:
   * - Byte0 => flags (bit0 => 16-bit HR, bit4 => RR intervals)
   * - Byte1 => heart rate (8-bit) or first two bytes => heart rate (16-bit)
   * - Additional bytes for EE, then pairs of bytes for RR intervals
   */
  _parseHeartRateValue(dataView) {
    if (!dataView || dataView.byteLength < 2) return null;

    let offset = 0;
    const flags = dataView.getUint8(offset);
    offset += 1;

    // bit0 => 16-bit HR format
    let hr;
    if (flags & 0x01) {
      hr = dataView.getUint16(offset, true);
      offset += 2;
    } else {
      hr = dataView.getUint8(offset);
      offset += 1;
    }

    // bit3 => Energy Expended (skip if present)
    if (flags & 0x08) {
      offset += 2;
    }

    // bit4 => RR intervals
    const hasRR = (flags & 0x10) !== 0;
    let rrs = [];
    if (hasRR) {
      this.supportsRR = true;
      while (offset + 1 < dataView.byteLength) {
        const rrRaw = dataView.getUint16(offset, true);
        offset += 2;
        const rrMs = (rrRaw * 1000) / 1024; // 1/1024 second units
        rrs.push(rrMs);
      }
    }

    return { hr, rrs };
  }

  /**
   * Return how many initial elements match between two arrays
   */
  _commonPrefixLength(arr1, arr2) {
    let i = 0;
    while (i < arr1.length && i < arr2.length && arr1[i] === arr2[i]) {
      i++;
    }
    return i;
  }

  /**
   * Handle each characteristic notification:
   * - If we're not using RR intervals, just read BPM from the second byte.
   * - If we *are* using RR intervals, parse them from the packet.
   * - If the device supports them but stops sending new intervals, fallback.
   * - We ensure multiple unique intervals in a single packet are each processed/broadcast.
   */
  _handleHeartRateMeasurement(event) {
    const dataView = event.target.value;
    if (!dataView || dataView.byteLength < 2) return;

    // If we're ignoring RR intervals, do the simple fallback:
    if (!this.useRRIntervals) {
      // Basic approach: flags in byte0, BPM in byte1
      const flags = dataView.getUint8(0);
      let heartRate;
      if (flags & 0x01) {
        // 16-bit
        heartRate = dataView.getUint16(1, true);
      } else {
        // 8-bit
        heartRate = dataView.getUint8(1);
      }
      console.log(`(No RR) Heart Rate: ${heartRate} BPM`);
      this._emit('reading', heartRate);
      return;
    }

    // Otherwise, parse the entire measurement
    const parsed = this._parseHeartRateValue(dataView);
    if (!parsed) return;
    const { hr, rrs } = parsed;
    const now = performance.now();

    // If we have new RR intervals
    if (rrs.length > 0) {
      const prefixLen = this._commonPrefixLength(this.lastRRs, rrs);
      const newRRs = rrs.slice(prefixLen); // only intervals we haven't processed yet

      if (newRRs.length > 0) {
        if (this.delayedRRIntervalQueue) {
          // -- Delayed approach (batch) --
          // Combine them into an array event (like the Java code does with "onDataPacketReceived")
          console.log(`Got ${newRRs.length} new RR intervals. Emitting in batch.`);
          // You could also pass the entire array if you prefer => or just pass them one by one
          this._emit('reading', {
            hr,            // the BPM
            rrs: newRRs,   // the new intervals
          });
        } else {
          // -- Immediate approach (one event per interval) --
          console.log(`Got ${newRRs.length} new RR intervals. Emitting individually.`);
          newRRs.forEach(() => {
            this._emit('reading', hr);
          });
        }

        this.lastRRs = rrs;
        this.lastRRTime = now;
      } else {
        // No truly new intervals => maybe fallback
        this._maybeFallback(hr, now);
      }
    } else {
      // No intervals => fallback check
      this._maybeFallback(hr, now);
    }
  }

  /**
   * Fallback logic if device claims RR but doesn't send them, or hasn't in a while
   */
  _maybeFallback(hr, now) {
    if (!this.supportsRR) {
      // device never signaled RR intervals => always fallback
      this._emit('reading', hr);
    } else {
      // device does support RR, but we got none this time
      // => if it's been too long, fallback to BPM
      const elapsed = now - this.lastRRTime;
      if (elapsed > this.RR_FALLBACK_TIMEOUT) {
        console.warn(`No new RR intervals in ${elapsed}ms, falling back to single BPM reading.`);
        this._emit('reading', hr);
      }
    }
  }

  _onDisconnected(event) {
    const device = event.target;
    console.warn(`Device ${device.name || device.id} disconnected.`);
    this._emit('disconnected', device);
    // Optional: auto-reconnect logic could go here
  }

  disconnect() {
    if (this.polarMonitor) {
      this.polarMonitor.disconnect();
      this.polarMonitor = null;
      return;
    }
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      this.device.gatt.disconnect();
      console.log('Device disconnected by user request.');
    }
  }
}

export default HeartRateMonitor;

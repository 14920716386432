//App.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import StreamingMeditation from "./StreamingMeditation";
import ChatDialog from "./ChatDialog";
import BackgroundMusicManager from "./BackgroundMusicManager";
import VideoPlaylist from "./VideoPlaylist";
import io from 'socket.io-client';
import Slider from '@mui/material/Slider';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

// Import the BLE modules we created
import BLEManager from "./BLEManager";
import HeartRateMonitor from "./HeartRateMonitor";
import HeartRateAnalysis from "./HeartRateAnalysis";
import HeartRateDisplay from "./HeartRateDisplay";
import HeartRateAlwaysDisplay from "./HeartRateAlwaysDisplay";

const DEBUG = false;
const DEBUG_SOCKET = false;

function logEvent(msg) {
  if (DEBUG) {
    console.log(`[${new Date().toISOString()}] ${msg}`);
  }
}
function logEventSocket(msg) {
  if (DEBUG_SOCKET) {
    console.log(`[${new Date().toISOString()}] ${msg}`);
  }
}

// Synchronously initialize sessionId from URL or generate one immediately.
const generateSessionId = () => {
  const params = new URLSearchParams(window.location.search);
  let sid = params.get("sessionID");
  if (!sid) {
    sid = crypto.randomUUID
      ? crypto.randomUUID()
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
  }
  logEventSocket("Using sessionID:", sid);
  return sid;
};

function App() {
  const SHOW_DEBUG_INFO = false;
  const SHOW_MUSIC_DEBUG_INFO = false;
  const params = new URLSearchParams(window.location.search);
  const sessionIdFromParams = params.get("sessionID");
  const passwordFromParams = params.get("password");

  const [prompt, setPrompt] = useState("");
  const [submittedPrompt, setSubmittedPrompt] = useState("");
  const [meditationText, setMeditationText] = useState("");
  const [musicStatus, setMusicStatus] = useState("");
  const [apiError, setApiError] = useState("");
  const [musicError, setMusicError] = useState("");
  const [bleStatus, setBleStatus] = useState(""); // New state for BLE status
  const [updateCount, setUpdateCount] = useState(0); // Use to force update for heart rate measurements
  const [showHeartRate, setShowHeartRate] = useState(false);
  const [socket, setSocket] = useState(null);
  const [sessionId, setSessionId] = useState(generateSessionId);
  const [iom2Connected, setIom2Connected] = useState(false);
  const [latestNonPriorityText, setLatestNonPriorityText] = useState("");
  const [isSwitchingMusic, setIsSwitchingMusic] = useState(false);
  const heartRateAnalysisRef = useRef(null);
  const [debugLogs, setDebugLogs] = useState([]); // State to capture console logs
  const logBufferRef = useRef([]);
  const meditationContainerRef = useRef(null);
  const [streamingVisibleText, setStreamingVisibleText] = useState("");
  const [videoHeight, setVideoHeight] = useState(421);
  const videoContainerRef = useRef(null);
  const [selectedVoice, setSelectedVoice] = useState("shimmer");
  const [globalVolume, setGlobalVolume] = useState(1.0);
  const previousVolumeRef = useRef(1.0);
  const [isPaused, setIsPaused] = useState(false);
  const [isExternallyPaused, setIsExternallyPaused] = useState(false);
  // We can store who paused it, if needed:
  const [pauseOwner, setPauseOwner] = useState(null);
  const socketRef = useRef(socket);
  const sessionIdRef = useRef(sessionId);
  const lastTTSCompleteCalledRef = useRef(false);
  const generatedPlaylistFromTitle = useRef(false);
  const generatedPlaylistFromMeditation = useRef(false);
  const [hasStarted, setHasStarted] = useState(false);
  

  // Whether custom crossfade music is currently active.
  const [isCustomMusicActive, setIsCustomMusicActive] = useState(false);
  // Tracks if custom music has *ever* started.
  const [hasEverPlayedCustomMusic, setHasEverPlayedCustomMusic] = useState(false);
  // Control the visibility of the ChatDialog.
  const [showChat, setShowChat] = useState(false);

  const [showWaitingMessage, setShowWaitingMessage] = useState(false);
  const waitingTriggered = useRef(false);

  // A reference to StreamingMeditation so we can call its public methods.
  const streamingRef = useRef(null);
  // Refs for the video playlist and BLE handlers
  const playlistRef = useRef(null);
  const bleManagerRef = useRef(null);
  const heartRateMonitorRef = useRef(null);

  // Wrap the playlist in an object so we can flag media-command insertions.
  const [videoPlaylist, setVideoPlaylist] = useState({
    items: [],
    isMediaCommand: false,
  });
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentIndexRef = useRef(currentIndex);

  // Handler to pause both audio and video playback
  const handlePause = () => {
    // Pause the TTS audio in StreamingMeditation:
    if (streamingRef.current && streamingRef.current.pausePlayback) {
      streamingRef.current.pausePlayback();
    }
    // Pause the video in VideoPlaylist:
    if (playlistRef.current && playlistRef.current.pauseVideo) {
      playlistRef.current.pauseVideo();
    }
    // Pause the background music
    if (window.bgMusicManager && typeof window.bgMusicManager.pauseMusic === "function") {
      window.bgMusicManager.pauseMusic();
    }
    setIsPaused(true);
  };

  // Handler to resume playback for both audio and video
  const handleResume = () => {
    if (streamingRef.current && streamingRef.current.resumePlayback) {
      streamingRef.current.resumePlayback();
    }
    if (playlistRef.current && playlistRef.current.resumeVideo) {
      playlistRef.current.resumeVideo();
    }
    if (window.bgMusicManager && typeof window.bgMusicManager.resumeMusic === "function") {
      window.bgMusicManager.resumeMusic();
    }
    setIsPaused(false);
  };

  // A unified handler for the button click
  const handleButtonClick = (e) => {
    e.preventDefault();
    
    logEventSocket("Generate Meditation Button Clicked, or Generate Meditation Form Submitted");
    
    if (!hasStarted) {
      setHasStarted(true);
    }

    // If meditation has not yet been started, that means "Generate Meditation"
    if (!submittedPrompt) {
      logEventSocket("Sending start FOR GENERATE MEDITATION to external clients");
      socket.emit("start", { sessionId });
      handleSubmit(e);
      return;
    }

    // If meditation has finished (showChat is true), that means "Replay"
    else if (showChat) {
      logEventSocket("Sending start FOR REPLAY to external clients");
      socket.emit("start", { sessionId });
      handleRestart(e);
      return;
    }

    // Otherwise, we are in the middle of a meditation => toggle pause/resume
    if (isPaused) {
      // If we are externally paused (someone else paused us),
      // do not allow a local resume.
      if (isExternallyPaused) {
        logEventSocket("We can't resume because another client owns the pause.");
        return;
      }
      logEventSocket("Sending resume to external clients");
      // We own the pause => resume
      socket.emit("resume", { sessionId });
      handleResume();
    } else {
      logEventSocket("Sending pause to external clients");
      // We are not paused => local user is requesting a pause
      socket.emit("pause", { sessionId });
      handlePause();
    }
  };

  // Handler for volume slider changes:
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setGlobalVolume(newVolume);
    
    if (newVolume !== 0) {
      previousVolumeRef.current = newVolume;
    }
    
    // Update volume on BackgroundMusicManager (assuming it's stored globally)
    if (window.bgMusicManager && typeof window.bgMusicManager.setGlobalVolume === 'function') {
      window.bgMusicManager.setGlobalVolume(newVolume);
    }
    
    // If StreamingMeditation exposes a method to update the playing audio’s volume:
    if (streamingRef.current && typeof streamingRef.current.updateGlobalVolume === 'function') {
      streamingRef.current.updateGlobalVolume(newVolume);
    }
  };

  const toggleMute = () => {
    const newVolume = globalVolume === 0 ? previousVolumeRef.current : 0;
    // Create a synthetic event to reuse handleVolumeChange logic
    const syntheticEvent = { target: { value: newVolume } };
    handleVolumeChange(syntheticEvent);
  };

  // Handler for the select element:
  const handleVoiceChange = (e) => {
    setSelectedVoice(e.target.value);
  };

  const handleNewNonPriorityText = useCallback((text) => {
    setLatestNonPriorityText(text);
    if (heartRateAnalysisRef.current && heartRateAnalysisRef.current.setLastNonPrioritySpokenText) {
      heartRateAnalysisRef.current.setLastNonPrioritySpokenText(text);
    }
  }, []);

  useEffect(() => {
    if (!socket || !hasStarted) return;
  
    const intervalId = setInterval(() => {
      if (showChat) {
        socket.emit("notrecording", { sessionId });
        logEventSocket("Sending notrecording command...");
      } else {
        socket.emit("recording", { sessionId });
        logEventSocket("Sending recording command...");
      }
    }, 1000);
  
    return () => clearInterval(intervalId);
  }, [socket, showChat, sessionId, hasStarted]);

  useEffect(() => {
    if (!socket || !sessionId) return;
    // -- Another client started the session
    socket.on("start", (data) => {
      logEventSocket("Received 'start' event from another client (WE DON'T RESPOND TO THIS):", data);
    });

    // -- Another client paused the session
    socket.on("pause", (data) => {
      logEventSocket("Received 'pause' event from another client:", data);
      const { pausedBy } = data || {};
      setIsPaused(true);

      // Mark that we are externally paused by someone else’s socket.
      if (pausedBy !== socket.id) {
        setIsExternallyPaused(true);
        setPauseOwner(pausedBy);
      }

      // Actually pause the local audio/video
      handlePause();
    });

    // -- Another client resumed the session
    socket.on("resume", (data) => {
      logEventSocket("Received 'resume' event from another client:", data);
      const { resumedBy } = data || {};
      setIsPaused(false);

      // If we were externally paused, but now someone resumed,
      // we go back to normal.
      if (pauseOwner === resumedBy) {
        logEventSocket("we were externally paused, but now someone resumed, we go back to normal");
        setIsExternallyPaused(false);
        setPauseOwner(null);
      }

      // Actually resume local audio/video
      handleResume();
    });

    // -- Another client stopped the session
    socket.on("stop", (data) => {
      logEventSocket("Received 'stop' event from another client (WE DON'T RESPOND TO THIS):", data);
      /*console.log("Received 'stop' event from another client:", data);
      // You can treat this like onLastTTSComplete. For example:
      setIsPaused(false);
      setIsExternallyPaused(false);
      setPauseOwner(null);

      // Possibly show chat UI or set some "stopped" state. 
      // This is up to you:
      setShowChat(true);
      // Optionally do local finalization of audio, or handleStop() if you have it
      if (streamingRef.current && streamingRef.current.pausePlayback) {
        streamingRef.current.pausePlayback();
      }
      if (playlistRef.current && playlistRef.current.pauseVideo) {
        playlistRef.current.pauseVideo();
      }
      console.log("Meditation forcibly stopped externally.");*/
    });

    return () => {
      socket.off("start");
      socket.off("pause");
      socket.off("resume");
      socket.off("stop");
    };
  }, [socket, sessionId, submittedPrompt, handlePause, handleResume, pauseOwner]);
    
  useEffect(() => {
    if (!socket || !sessionId) return;
    const errorHandler = data => {
      if (data.error) {
        setApiError(data.error);
      }
    };
    socket.on("streamData", errorHandler);
    return () => socket.off("streamData", errorHandler);
  }, [socket, sessionId]);

  useEffect(() => {
    let timer;
    // Only start the timer if we're not connected, have a sessionId, and haven't already triggered the waiting message.
    if (!iom2Connected && sessionIdFromParams && !waitingTriggered.current) {
      timer = setTimeout(() => {
        setShowWaitingMessage(true);
        waitingTriggered.current = true; // Prevent future triggers
      }, 10000);
    }
    return () => clearTimeout(timer);
  }, [iom2Connected, sessionIdFromParams]);

  useEffect(() => {
    const updateVideoHeight = () => {
      if (videoContainerRef.current) {
        setVideoHeight(videoContainerRef.current.clientHeight);
      }
    };

    updateVideoHeight(); // Initial measure
    window.addEventListener("resize", updateVideoHeight);
    return () => window.removeEventListener("resize", updateVideoHeight);
  }, []);

  useEffect(() => {
    currentIndexRef.current = currentIndex;
  }, [currentIndex]);

  const musicBaseUrl = process.env.REACT_APP_MUSIC_URL;

  // -----------------------------------------------------------
  // Override console.log and console.error to capture debug logs
  // -----------------------------------------------------------
  useEffect(() => {
    if (SHOW_DEBUG_INFO) {
      const originalLog = console.log;
      console.log = function(...args) {
        originalLog.apply(console, args);
        logBufferRef.current.push(args.join(" "));
      };
    
      const flushInterval = setInterval(() => {
        if (logBufferRef.current.length > 0) {
          setDebugLogs(prev => [...prev, ...logBufferRef.current]);
          logBufferRef.current = [];
        }
      }, 100);
    
      return () => {
        console.log = originalLog;
        clearInterval(flushInterval);
      };
    }
  }, []);

  // Socket connection
  useEffect(() => {
    logEventSocket("Connecting to socket...");
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const socketInstance = io(SERVER_URL);

    socketInstance.on("connect", () => {
      logEventSocket(`Socket connected with id: ${socketInstance.id}`);
     
      if (sessionIdFromParams) {
        logEventSocket("Overriding default session id with new id from params:", sessionIdFromParams);
        setSessionId(sessionIdFromParams);
      }

      // Join session once we have a sessionId.
      if (sessionId) {
        logEventSocket("Sending localClientJoinSession to external clients");
        socketInstance.emit("localClientJoinSession", sessionId, passwordFromParams);
        logEventSocket(`Joined session ${sessionId}`);
      }
    });

    socketInstance.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    socketInstance.on("reconnect_attempt", (attempt) => {
      logEventSocket(`Socket reconnection attempt: ${attempt}`);
    });

    socketInstance.on("disconnect", (reason) => {
      logEventSocket("Socket disconnected:", reason);
    });

    socketInstance.on("heartBeat", (data) => {
      setBleStatus("Connected to iom2. Heart Rate: " + data.bpm);
      //console.log(`Received Heart Beat from External Sender: ${data.bpm}`);
      if (heartRateAnalysisRef.current) {
        logEventSocket(`Analyzing Heart Beat from External Sender: ${data.bpm}`);
        handleHRBeat();
        heartRateAnalysisRef.current.addBPM(data.bpm);
        if (data.resonance) {
          // Convert to a float just in case
          const coherenceVal = parseFloat(data.resonance);
          heartRateAnalysisRef.current.addCoherenceExternal(coherenceVal);
        }
        setUpdateCount((prev) => prev + 1);
      } else {
        //initialize heartRateAnalysis and anything else we need to do
        logEventSocket(`Heart Beat from External Sender confirms iom2 connection: ${data.bpm}`);
        handleConnectiom2();
      }
    });

    socketInstance.on("priorityTTS", (data) => {
      logEvent("Received priority TTS:", data);
      if (streamingRef.current && typeof streamingRef.current.insertPriorityAudio === "function") {
        streamingRef.current.insertPriorityAudio(data);
      }
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
      logEventSocket("Socket connection closed.");
    };
  }, [sessionId]); // Depend on sessionId so joinSession fires when available.

  // -----------------------------------------------------------
  // Check remote API connectivity on mount
  // -----------------------------------------------------------
  useEffect(() => {
    // (Your existing API connectivity check can go here.)
  }, [musicBaseUrl]);

  // -----------------------------------------------------------
  // Fetch default video playlist on mount
  // -----------------------------------------------------------
  useEffect(() => {
    const fetchDefaultPlaylist = async () => {
      try {
        const response = await axios.get(`/api/playlist?sessionId=${sessionId}`);
        //console.log("Default video playlist fetched:", response.data);
        //console.log("Starting playlist:", response.data.content.video.videoUrls);
        setVideoPlaylist({
          items: response.data.content.video.videoUrls,
          isMediaCommand: false,
        });
      } catch (err) {
        console.error("Error fetching default playlist:", err);
      }
    };
    fetchDefaultPlaylist();
  }, []);

  // -----------------------------------------------------------
  // Create BackgroundMusicManager on mount
  // -----------------------------------------------------------
  useEffect(() => {
    const bgMusicManager = new BackgroundMusicManager((status) => {
      setMusicStatus(status);
      logEvent("Music progress:", status);
    });
    // Set the onCustomMusicStart callback so that when custom music starts,
    // we set the flag that custom music has ever played.
    bgMusicManager.onCustomMusicStart = () => {
      logEvent("Custom music started from BackgroundMusicManager.");
      setHasEverPlayedCustomMusic(true);
    };
    window.bgMusicManager = bgMusicManager;
  }, []);

  // -----------------------------------------------------------
  // Check for Web Bluetooth support on mount
  // -----------------------------------------------------------
  useEffect(() => {
    if (!navigator.bluetooth) {
      alert("Web Bluetooth API not supported in this browser. Please use Chrome or a compatible browser.");
      setBleStatus("Web Bluetooth API not supported. Please use Chrome or a compatible browser.");
    }
  }, []);

  // -----------------------------------------------------------
  // BLE Handlers
  // -----------------------------------------------------------
  const handleConnectBLE = async () => {
    if (!navigator.bluetooth) {
      alert("Web Bluetooth API not supported in this browser. Please use Chrome or a compatible browser.");
      return;
    }
    try {
      const bleManager = new BLEManager();
      bleManagerRef.current = bleManager;
      await bleManager.connect();
      setBleStatus("Connected to generic BLE device.");
      bleManager.monitorGenericNotifications();
    } catch (error) {
      console.error("Error connecting generic BLE device:", error);
      setBleStatus("Error connecting generic BLE device.");
    }
  };

  const handleConnectiom2 = async () => {
    try {
      logEvent("Connecting to iom2...");
      if (!heartRateAnalysisRef.current) {
        logEvent("Creating new HeartRateAnalysis instance for iom2.");
        heartRateAnalysisRef.current = new HeartRateAnalysis(sessionId);
        logEvent("HeartRateAnalysis instance created for iom2.");
      }
      setIom2Connected(true);
    } catch (error) {
      console.error("Error connecting heart rate monitor:", error);
      setBleStatus("Error connecting heart rate monitor.");
    }
  };

  const handleConnectHeartRate = async () => {
    if (!navigator.bluetooth) {
      //alert("Web Bluetooth API not supported in this browser. Please use Chrome or a compatible browser.");
      return;
    }
    try {
      const hrMonitor = new HeartRateMonitor();
      heartRateMonitorRef.current = hrMonitor;
      await hrMonitor.connect();
      setBleStatus("Connected to heart rate monitor.");
      if (!heartRateAnalysisRef.current) {
        heartRateAnalysisRef.current = new HeartRateAnalysis(sessionId);
      }
      if (hrMonitor.polarMonitor && typeof hrMonitor.polarMonitor.setAnalysis === "function" && heartRateAnalysisRef.current) {
        hrMonitor.polarMonitor.setAnalysis(heartRateAnalysisRef.current);
      }
      hrMonitor.on("reading", (bpm) => {
        if (heartRateAnalysisRef.current) {
          handleHRBeat();
          heartRateAnalysisRef.current.addBPM(bpm);
          setUpdateCount((prev) => prev + 1);
        }
      });
    } catch (error) {
      console.error("Error connecting heart rate monitor:", error);
      setBleStatus("Error connecting heart rate monitor.");
    }
  };

  useEffect(() => {
    if (heartRateAnalysisRef.current && heartRateAnalysisRef.current.setCurrentPrompt) {
      heartRateAnalysisRef.current.setCurrentPrompt(prompt);
    }
  }, [prompt]);

  // -----------------------------------------------------------
  // Other handlers (prompt change, media commands, etc.)
  // -----------------------------------------------------------
  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleIndexChange = (newIndex) => {
    logEvent(`Parent sees new currentIndex: ${newIndex}`);
    setCurrentIndex(newIndex);
  };

  const handleHRBeat = () => {
    if (playlistRef.current && playlistRef.current.getCurrentVideoFileName) {
      const fileName = playlistRef.current.getCurrentVideoFileName();
      if (heartRateAnalysisRef.current && heartRateAnalysisRef.current.setVideoPlaying) {
        heartRateAnalysisRef.current.setVideoPlaying(fileName);
      }
    }
  };

  const handleMediaCommand = useCallback((mediaFile) => {
    if (mediaFile.stopMusic) {
      logEvent("onMediaCommand: Received stopMusic flag. Stopping background music.");
      if (window.bgMusicManager) {
        window.bgMusicManager.stopMusicWithFade();
      }
      return;
    }
    logEvent("Media command received in App.js => " + mediaFile);
  
    // The entire insertion + forced transition is replaced with:
    if (playlistRef.current) {
      playlistRef.current.enqueuePriorityVideo(mediaFile);
    }
  }, [playlistRef]);

  const handleMeditationComplete = (completeText) => {
    logEvent("Meditation text complete:" + completeText);
    setMeditationText(completeText);
    const playedUntil = currentIndexRef.current;
    
    if (generatedPlaylistFromMeditation.current) {
      //don't generate the playlist twice
    } else {
      generatedPlaylistFromMeditation.current = true;
      axios
        .get(`/api/playlist?prompt=${encodeURIComponent(completeText)}&sessionId=${sessionId}`)
        .then((response) => {
          const newList = response.data?.content?.video?.videoUrls || [];
          logEvent("Received Final playlist from complete meditation text");//, newList);
          setVideoPlaylist((prev) => {
            const playedItems = prev.items.slice(0, playedUntil + 1);
            return { items: [...playedItems, ...newList], isMediaCommand: false };
          });
        })
        .catch((err) => {
          console.error("Error generating new playlist after final text:", err);
        });
    }
  };

  const handleCustomMusicAvailable = () => {
    logEvent("Custom music is now available in the custom playlist.");
    setHasEverPlayedCustomMusic(true);
  };

  const handleCustomMusicStart = () => {
    logEvent("App.js: custom music started in StreamingMeditation.");
    setIsCustomMusicActive(true);
    setHasEverPlayedCustomMusic(true);
  };

  const handleCustomMusicStop = () => {
    logEvent("App.js: custom music stopped in StreamingMeditation.");
    setIsCustomMusicActive(false);
  };

  // Add this helper function inside your App component:
  const forceBackgroundMusic = async () => {
    if (window.bgMusicManager) {
      try {
        // Fade out whatever is currently playing
        await window.bgMusicManager.stopMusicWithFade();
        // Fade in the sequential background music using the current prompt
        await window.bgMusicManager.playSequentialBackgroundMusic(prompt);
        setIsCustomMusicActive(false);
        logEvent("Forced switch to background music.");
      } catch (error) {
        console.error("Error forcing switch to background music:", error);
      }
    }
  };


  const handleSwitchMusic = () => {
    if (isSwitchingMusic) return; // Prevent duplicate actions
    setIsSwitchingMusic(true);
  
    if (!isCustomMusicActive) {
      // Since custom music items are already added to the playlist, call fadeToCustomMusic without a URL.
      logEvent("Switching to custom music using the existing custom playlist...");
      if (window.bgMusicManager && typeof window.bgMusicManager.fadeToCustomMusic === "function") {
        window.bgMusicManager.fadeToCustomMusic(undefined, 5000)
          .then(() => {
            logEvent("Custom music started from custom playlist.");
            setIsCustomMusicActive(true);
            setIsSwitchingMusic(false);
          })
          .catch(err => {
            console.error("Error fading to custom music:", err);
            setIsSwitchingMusic(false);
          });
      } else {
        setIsSwitchingMusic(false);
      }
    } else {
      // If custom music is active, switch back to quiet background music.
      if (window.bgMusicManager) {
        window.bgMusicManager.stopMusicWithFade().then(() => {
          return window.bgMusicManager.playSequentialBackgroundMusic(prompt);
        }).then(() => {
          logEvent("Switched back to background music.");
          setIsCustomMusicActive(false);
          setIsSwitchingMusic(false);
        }).catch((err) => {
          console.error("Failed to switch back to background music:", err);
          setIsSwitchingMusic(false);
        });
      } else {
        setIsSwitchingMusic(false);
      }
    }
  };
  
  // ---------------------------
  // TEST BUTTON HANDLER
  // ---------------------------
  // Add this function near your other handlers in App.js:
  const handleTestMeditationTTS = async () => {
    try {
      const testData = {
        sessionId: sessionId, // reuse current sessionId
        fullSpokenText: "Welcome to your meditation session. Today, we will focus on the breath and the present moment.",
        spokenTextHistory: [
          {
            startTime: Date.now() - 5000,
            endTime: Date.now(),
            text: "Welcome to your meditation session.",
            avgBPM: 70,
            avgRelaxation: 50
          }
        ],
        meditationMsg: "Take a deep breath.",
        suggestedMessage: "Take a deep breath.",
        currentBPM: 70,
        currentRelaxationScore: 0.5, // using a 0..1 scale
        lastNonPrioritySpokenText: "Welcome to your meditation session."
      };
      const response = await axios.post("/api/meditationGPTTTS", testData);
      console.log("Test call to meditationGPTTTS successful:", response.data);
    } catch (err) {
      console.error("Test call failed:", err);
    }
  };

  const handleTestCustomMusic = async () => {
    const testUrl1 = "https://somaticvisionai.com/music/musicWithLyrics/Golden_Light_2-24-2025-05-21_0.mp3";
    const testUrl2 = "https://somaticvisionai.com/music/musicWithLyrics/Test_of_Time_2-24-2025-10-56_0.mp3";
    console.log("Test button pressed. Adding two custom music test items.");

    // Add both test URLs to the custom playlist.
    if (window.bgMusicManager && typeof window.bgMusicManager.addToCustomPlaylist === "function") {
      try {
        await window.bgMusicManager.addToCustomPlaylist(testUrl1);
        await window.bgMusicManager.addToCustomPlaylist(testUrl2);
        console.log("Test URLs added to the custom music playlist.");
      } catch (err) {
        console.error("Error adding test URLs to custom playlist:", err);
        return;
      }
    } else {
      console.error("BackgroundMusicManager not available or missing addToCustomPlaylist.");
      return;
    }

    // Now, simulate switching to custom music using the custom playlist.
    if (window.bgMusicManager && typeof window.bgMusicManager.fadeToCustomMusic === "function") {
      try {
        // Calling fadeToCustomMusic with undefined so it uses the custom playlist.
        await window.bgMusicManager.fadeToCustomMusic(undefined, 5000);
        console.log("Custom music faded in successfully via test button.");
        // Set the custom music active flag so that the switch button shows the correct label.
        setIsCustomMusicActive(true);
        console.log("Simulating onLastTTSComplete callback.");
        setShowChat(true);
      } catch (err) {
        console.error("Error fading to custom music:", err);
      }
    } else {
      console.error("BackgroundMusicManager not available.");
    }
  };

  const simulateSocketHeartBeat = () => {
    // Generate a random BPM between 60 and 100 (for example)
    const simulatedBpm = Math.floor(Math.random() * 41) + 60;
    setBleStatus("Simulated heart beat.");
    console.log(`Simulated Heart Beat: ${simulatedBpm}`);
    
    // Simulate the same behavior as when a heartBeat event is received
    if (heartRateAnalysisRef.current) {
      console.error("Add beat.");
      handleHRBeat();
      heartRateAnalysisRef.current.addBPM(simulatedBpm);
      setUpdateCount((prev) => prev + 1);
    } else {
      console.error("Heart Rate Analysis not available.");
    }
  };

  const handleSubmit = async (e) => {
    logEventSocket("Submitting prompt:" + prompt);
    e.preventDefault();
    setApiError(null);
    if (prompt.trim()) {
      setSubmittedPrompt(prompt);
      if (window.bgMusicManager) {
        window.bgMusicManager
          .playSequentialBackgroundMusic(prompt)
          .catch((err) => {
            console.error("Error playing sequential background music:", err);
            setMusicError("Error playing sequential background music.");
          });
      }
      if (generatedPlaylistFromTitle.current) {
        //don't generate the title based playlist twice
      } else {
        generatedPlaylistFromTitle.current = true;

        try {
          const response = await axios.get(`/api/playlist?prompt=${encodeURIComponent(prompt)}&sessionId=${sessionId}`);
          logEvent("Custom video playlist received:" + response.data);
          //console.log("New custom video playlist:", response.data.content.video.videoUrls);
          setVideoPlaylist({
            items: response.data.content.video.videoUrls,
            isMediaCommand: false,
          });
          setCurrentIndex(0);
        } catch (err) {
          console.error("Error generating content:", err);
          setApiError("Error generating meditation content. Please try again later.");
        }
      }
    }
  };

  const handleRestart = (e) => {
    e.preventDefault();
    
    lastTTSCompleteCalledRef.current = false;

    if (showChat) {
      // This path corresponds to "Replay Meditation"
      if (
        streamingRef.current &&
        typeof streamingRef.current.resetForReplay === "function"
      ) {
        if (heartRateAnalysisRef.current) {
          logEvent("Resetting spoken text history...");
          heartRateAnalysisRef.current.resetSpokenTextHistory();
        }

        streamingRef.current.resetForReplay();
        if (socket && sessionId) {
          logEventSocket("Requesting replayMeditation from server...");
          socket.emit("replayMeditation", { sessionId });
        }
        setShowChat(false);
        forceBackgroundMusic();
      } else {
        console.error("resetForReplay function is not available.");
      }
    } else {
      // This path corresponds to "Reset Meditation"
      window.location.reload();
    }
  };

  const toggleFullScreen = () => {
    const wrapper = videoContainerRef.current;
    if (!document.fullscreenElement) {
      wrapper.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    } else {
      document.exitFullscreen().catch((err) => {
        console.error("Error attempting to exit full-screen mode:", err);
      });
    }
  };

  useEffect(() => {
    if (meditationContainerRef.current) {
      meditationContainerRef.current.scrollTop =
        meditationContainerRef.current.scrollHeight;
    }
  }, [streamingVisibleText]);

  useEffect(() => {
    socketRef.current = socket;
  }, [socket]);
  
  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  const onLastTTSComplete = useCallback(() => {
    if (lastTTSCompleteCalledRef.current) return;
    lastTTSCompleteCalledRef.current = true;

    logEvent("Last TTS audio finished playing. Showing chat interface.");
    setShowChat(true);
    
    // Emit 'stop' so the external client(s) know the session ended
    logEventSocket("Emitting 'stop' event to external clients.");
    socketRef.current.emit("stop", { sessionId: sessionIdRef.current });

    if (heartRateAnalysisRef.current) {
      heartRateAnalysisRef.current.setMeditationComplete(true);
    }
  }, []);
  
  const onTTSPlay = useCallback((ttsText) => {
    if (heartRateAnalysisRef.current) {
      heartRateAnalysisRef.current.setSpokenText(ttsText);
    }
  }, [heartRateAnalysisRef]);

  // -----------------------------------------------------------
  // Render
  // -----------------------------------------------------------
  return (
    <div className="app-container">
      {/* BLE Controls Section */}
      {!sessionIdFromParams && (
        <div style={{ margin: "1em 0", padding: "1em", border: "1px solid #ccc" }}>
          <button onClick={handleConnectHeartRate}>Connect Heart Rate Monitor</button>
          {bleStatus && <span style={{ marginLeft: "1em" }}>{bleStatus}</span>}
          {heartRateAnalysisRef.current && (
            <HeartRateAlwaysDisplay analysis={heartRateAnalysisRef.current} />
          )}
          {/* <button onClick={handleConnectBLE}>Connect Generic BLE Device</button> */}
          { /* <button onClick={handleConnectiom2}>Connect iom2</button> */}
          { /* <button onClick={() => setShowHeartRate((prev) => !prev)}>
          {showHeartRate ? "Hide Heart Rate Display" : "Show Heart Rate Display"}
          </button> */ }
          {showHeartRate && heartRateAnalysisRef.current && (
            <HeartRateDisplay analysis={heartRateAnalysisRef.current} />
          )}
        </div>
      )}
      {apiError && <p style={{ color: "red" }}>{apiError}</p>}

      {iom2Connected && <div style={{ height: '35px' }} />}
      
      <div
        className="media-container"
        style={{ display: "flex", gap: "20px", justifyContent: "center" }}
      >
        {/* Left Column: Video */}
        <div className="ai-video-container" style={{ flex: "0 0 55%" }}>
          <div
            className="fullscreen-video-wrapper"
            ref={videoContainerRef}
            onDoubleClick={toggleFullScreen}
          >
            {videoPlaylist.items && videoPlaylist.items.length > 0 ? (
              <VideoPlaylist
                ref={playlistRef}
                playlist={videoPlaylist}
                crossfadeDuration={2000}
                onIndexChange={handleIndexChange}
              />
            ) : (
              <p>Loading video content...</p>
            )}
          </div>
        </div>

        {/* Right Column: Streaming Meditation Text */}
        <div
          className="meditation-container"
          style={{
            flex: "0 0 40%",
            height: `${videoHeight}px`, // Set height to match video container
            minHeight: "300px",
            overflowY: "auto",
            border: "1px solid #ccc",
            padding: "10px"
          }}
          ref={meditationContainerRef}
        >
          <StreamingMeditation
            ref={streamingRef}
            prompt={submittedPrompt}
            voice={selectedVoice} 
            onReceivedCompleteText={handleMeditationComplete}
            onMediaCommand={handleMediaCommand}
            onCustomMusicAvailable={handleCustomMusicAvailable}
            onCustomMusicStart={handleCustomMusicStart}
            onCustomMusicStop={handleCustomMusicStop}
            onLastTTSComplete={onLastTTSComplete}
            onTTSPlay={onTTSPlay}
            onNewNonPriorityText={handleNewNonPriorityText}
            onVisibleTextChange={setStreamingVisibleText}
            socket={socket}
            sessionId={sessionId}
            globalVolume={globalVolume}
            isExistingSession={!!sessionIdFromParams} 
          />
        </div>
      </div>



      {SHOW_MUSIC_DEBUG_INFO && musicStatus && <p>Background Music: {musicStatus}</p>}
      {SHOW_MUSIC_DEBUG_INFO && musicError && <p style={{ color: "red" }}>{musicError}</p>}

      {/* TEST BUTTON */}
      { /*<div className="test-container" style={{ margin: "1em 0", padding: "1em", border: "1px solid #ccc" }}>
        <button onClick={handleTestCustomMusic}>Test Custom Music</button>
      </div> */}
      { /* <div className="test-container" style={{ margin: "1em 0", padding: "1em", border: "1px solid #ccc" }}>
        <button onClick={handleTestMeditationTTS}>Test Meditation GPT TTS</button>
      </div> */ }

      {
        (iom2Connected || !sessionIdFromParams) ? (
        <div className="prompt-container">
          <form onSubmit={handleButtonClick}>
            <input
              type="text"
              value={prompt}
              onChange={handlePromptChange}
                  placeholder="Type here. What’s on your mind or in your heart? Share your intention, feeling, or challenge here..."
              className="prompt-input"
              disabled={!!submittedPrompt}
            />   
            {/* Volume Slider & Voice Selection */}
            <div className="action-group">
              <button
                type="button"
                className="prompt-button"
                onClick={handleButtonClick}
                disabled={isExternallyPaused}
              >
                { !submittedPrompt
                    ? "Generate Meditation"
                    : showChat
                      ? "Replay Meditation"
                      : (isPaused ? "Resume Meditation" : "Pause Meditation")
                }
              </button>   
              <div className="voice-selection">
                <select
                  id="voiceSelect"
                  value={selectedVoice}
                  onChange={handleVoiceChange}
                  disabled={!!submittedPrompt}
                >
                  <option value="shimmer">Female Voice</option>
                  <option value="ash">Male Voice</option>
                </select>
              </div>
              <div className="volume-slider">
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* Make the icon clickable */}
                  <div onClick={toggleMute} style={{ cursor: "pointer" }}>
                    {globalVolume === 0 ? <VolumeOffIcon /> : <VolumeUpIcon />}
                  </div>
                  <Slider
                    value={globalVolume}
                    onChange={handleVolumeChange}
                    aria-labelledby="volume-slider"
                    style={{ marginLeft: 8, width: 100 }}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                </div>
              </div>      
          </div>
        </form>
      </div>
      ) : (
        showWaitingMessage && (
          <div>
            <p>
              Waiting for Heart Beat... If you are wearing the iom2 clip and this
              message lasts for more than 30 seconds, restart the Personalized Meditation App.
            </p>
          </div>
        )
        )
      }

      {showChat && (
        <div style={{ margin: "1em 0" }}>
          <button
            disabled={!hasEverPlayedCustomMusic || isSwitchingMusic}
            onClick={handleSwitchMusic}
          >
            {hasEverPlayedCustomMusic ? (
              isSwitchingMusic 
                ? (isCustomMusicActive 
                    ? "Fading to Quiet Background Music..." 
                    : "Fading to Your Custom Meditation Song...")
                : (isCustomMusicActive 
                    ? "Switch to Quiet Background Music" 
                    : "Switch to Your Custom Meditation Song")
            ) : (
              "If You Wait, A Custom Meditation Song May Be Created..."
            )}
          </button>
        </div>
      )}

      {showChat && (
        <ChatDialog
          sessionId={sessionId}
          chatTitle="Do you want to talk about, or ask any questions about, this meditation?"
          meditationText={meditationText}
          sessionStats={
            heartRateAnalysisRef.current && heartRateAnalysisRef.current.getReadableSpokenTextHistory
              ? heartRateAnalysisRef.current.getReadableSpokenTextHistory()
              : ""
          }
        />
      )}
      
      {SHOW_DEBUG_INFO && (
        <div style={{ whiteSpace: "pre-wrap", background: "#f0f0f0", padding: "1em", marginTop: "1em" }}>
          <h3>Debug Logs</h3>
          {debugLogs.map((log, index) => (
            <div key={index}>{log}</div>
          ))}
        </div>
      )}

      {iom2Connected && <div style={{ height: '150px' }} />}

      
    </div>
  );
}

export default App;

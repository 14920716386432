// VideoPlaylist.js
import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import Hls from "hls.js";

const DEBUG = false;
function logEvent(msg) {
  if (DEBUG) {
    console.log(`[VideoPlaylist] ${msg}`);
  }
}

// Helper: remove consecutive duplicates from an array in-place
function removeConsecutiveDuplicates(arr) {
  for (let i = arr.length - 1; i > 0; i--) {
    if (arr[i] === arr[i - 1]) {
      arr.splice(i, 1);
    }
  }
}

const VideoPlaylist = forwardRef(function VideoPlaylist(
  { playlist, crossfadeDuration = 2000, onIndexChange },
  ref
) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activePlayer, setActivePlayer] = useState("A");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [pendingForceTransitionIndex, setPendingForceTransitionIndex] =
    useState(null);

  // The main list of items we are playing
  const prevPlaylistRef = useRef(playlist.items || []);

  //store if the videos should be paused
  const [paused, setPaused] = useState(false);
  const pausedRef = useRef(paused);

  // Priority tracking
  const priorityQueueRef = useRef([]);
  const prioritySetRef = useRef(new Set());

  // Merges
  const replacedInitialPlaylist = useRef(false);
  const queuedUrgentIndexRef = useRef(null);

  const doublePlayDoneRef = useRef(new Set());

  // The two video elements
  const videoARef = useRef(null);
  const videoBRef = useRef(null);

  // For logging/queries
  const lastPlayedVideoNameRef = useRef("");

  // -------------- Basic Utilities --------------

  function getPlayers() {
    return activePlayer === "A"
      ? { active: videoARef.current, inactive: videoBRef.current }
      : { active: videoBRef.current, inactive: videoARef.current };
  }

  function getActiveVideoElement() {
    const { active } = getPlayers();
    return active;
  }

  function isPriorityItem(url) {
    return prioritySetRef.current.has(url);
  }

  function tryPlayActive(reasonLabel) {
    const activeVideo = getActiveVideoElement();
    if (!activeVideo) return;

    if (paused) {
      logEvent("tryPlayActive => paused, so not playing active video.");
      return;
    }

    const initialTime = activeVideo.currentTime;

    activeVideo
      .play()
      .then(() => {
        logEvent(
          `Active video .play() success: ${reasonLabel} => waiting for 'playing' event...`
        );
        const handlePlaying = () => {
          logEvent("Active video 'playing' => definitely playing now.");
          activeVideo.removeEventListener("playing", handlePlaying);
        };
        activeVideo.addEventListener("playing", handlePlaying);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          console.warn("Active video .play() aborted => possibly background tab. Will retry.");
        } else {
          console.error("Error playing active video =>", err);
        }
      });
  }

  function tryPlayInactive(videoEl, reason) {
    if (!videoEl) return;

    if (paused) {
      logEvent(`tryPlayInactive => paused, so not playing inactive video. (${reason})`);
      return;
    }

    videoEl
      .play()
      .then(() => {
        logEvent(`Inactive video playing OK => ${reason}`);
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          logEvent(`Inactive video .play() aborted => ignoring. (${reason})`);
        } else {
          console.error("Error playing inactive video =>", err);
        }
      });
  }

  useEffect(() => {
    pausedRef.current = paused;
  }, [paused]);

  // 1-second check for active video playing
  useEffect(() => {
    const checkInterval = setInterval(() => {
      if (pausedRef.current) {
        logEvent("1s check => paused, so not playing anything and making sure videos are paused.");
        getPlayers().active.pause();
        getPlayers().inactive.pause();
      } else {
        logEvent("1s check => play both videos");
        tryPlayActive("1s check");
        tryPlayInactive(getPlayers().inactive, "1s check");
      }
    }, 1000);

    return () => clearInterval(checkInterval);
  }, []);

  // If user returns to the tab => resume active
  useEffect(() => {
    function handleVisibilityChange() {
      if (!document.hidden) {
        logEvent("Tab became visible => try playing active...");
        tryPlayActive("tab visible");
      }
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // -----------------------------------------------------
  // Exposed Methods (via forwardRef)
  // -----------------------------------------------------
  useImperativeHandle(ref, () => ({
    forceTransitionTo: (targetIndex) => {
      forceTransitionToIndex(targetIndex);
    },
    getCurrentVideoFileName: () => lastPlayedVideoNameRef.current,
    pauseVideo: () => {
      getPlayers().active.pause();
      getPlayers().inactive.pause();
      setPaused(true);
      logEvent("pauseVideo()");
    },
    resumeVideo: () => {
      logEvent("resumeVideo()");
      setPaused(false);
      tryPlayActive("resumeVideo()");
      tryPlayInactive(getPlayers().inactive, "resumeVideo()");
    },

    // The priority approach => user calls this with a newly arrived priority URL
    enqueuePriorityVideo: (mediaUrl) => {
      logEvent(`[enqueuePriorityVideo] => ${mediaUrl}`);
      prioritySetRef.current.add(mediaUrl);
      priorityQueueRef.current.push(mediaUrl);
      logEvent(`=> priorityQueue now: ${JSON.stringify(priorityQueueRef.current)}`);

      // Remove duplicates from the *future* portion of the playlist
      const list = prevPlaylistRef.current;
      const start = currentIndex + 1;
      let removedCount = 0;
      for (let i = list.length - 1; i >= start; i--) {
        if (list[i] === mediaUrl) {
          list.splice(i, 1);
          removedCount++;
        }
      }
      if (removedCount > 0) {
        logEvent(
          `[enqueuePriorityVideo] Removed ${removedCount} future duplicates of ${mediaUrl}`
        );
      }
      prevPlaylistRef.current = list;
    },
  }));

  // -----------------------------------------------------
  // attachVideoSource
  // -----------------------------------------------------
  function attachVideoSource(videoEl, url, autoPlay = true) {
    if (!videoEl) return;
    if (videoEl._hls) {
      videoEl._hls.destroy();
      videoEl._hls = null;
    }
    const isHls = url.trim().toLowerCase().endsWith(".m3u8");
    if (!isHls) {
      // plain mp4
      videoEl.src = url;
      videoEl.load();
      if (autoPlay) {
        if (videoEl === getActiveVideoElement()) {
          tryPlayActive(`attachVideoSource MP4 (active: ${url})`);
        } else {
          videoEl.currentTime = 0;
        }
      }
      return;
    }
    // HLS case
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(url);
      hls.attachMedia(videoEl);
      videoEl._hls = hls;
      videoEl.load();
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        if (autoPlay) {
          if (videoEl === getActiveVideoElement()) {
            tryPlayActive(`attachVideoSource HLS (active: ${url})`);
          } else {
            videoEl.currentTime = 0;
          }
        }
      });
      hls.on(Hls.Events.ERROR, (evt, data) => {
        if (data.fatal) {
          console.error("HLS fatal => fallback MP4:", data);
          hls.destroy();
          const mp4Url = url.replace("playlist.m3u8", "play_720p.mp4");
          videoEl.src = mp4Url;
          videoEl.load();
          if (autoPlay) {
            if (videoEl === getActiveVideoElement()) {
              tryPlayActive(`fallback MP4 (active: ${mp4Url})`);
            } else {
              videoEl.currentTime = 0;
            }
          }
        }
      });
    } else if (videoEl.canPlayType("application/vnd.apple.mpegurl")) {
      // Safari
      videoEl.src = url;
      videoEl.load();
      if (autoPlay) {
        if (videoEl === getActiveVideoElement()) {
          tryPlayActive(`Safari HLS (active: ${url})`);
        } else {
          videoEl.currentTime = 0;
        }
      }
    } else {
      // fallback
      const mp4Url = url.replace("playlist.m3u8", "play_720p.mp4");
      videoEl.src = mp4Url;
      videoEl.load();
      if (autoPlay) {
        if (videoEl === getActiveVideoElement()) {
          tryPlayActive(`fallback MP4 (active: ${mp4Url})`);
        } else {
          videoEl.currentTime = 0;
        }
      }
    }
  }

  // -----------------------------------------------------
  // On mount => load first item(s)
  // -----------------------------------------------------
  useEffect(() => {
    if (playlist.items && playlist.items.length > 0) {
      const { active, inactive } = getPlayers();
      logEvent("Playlist mount => load " + playlist.items[0]);
      attachVideoSource(active, playlist.items[0], true);
      active.style.opacity = 1;

      if (playlist.items.length > 1) {
        attachVideoSource(inactive, playlist.items[1], false);
        inactive.style.opacity = 0;
      } else {
        attachVideoSource(inactive, playlist.items[0], false);
        inactive.style.opacity = 0;
      }
    }
  }, []);

  // -----------------------------------------------------
  // handle new main playlist
  // => remove all NON-priority items after currentIndex, then add new items
  // => also remove consecutive duplicates
  // -----------------------------------------------------
  useEffect(() => {
    if (!playlist || !playlist.items || playlist.items.length === 0) {
      return;
    }

    if (playlist.isMediaCommand) {
      logEvent("Media command => skip reinit, just store items");
      prevPlaylistRef.current = playlist.items;
      return;
    }

    const oldItems = [...prevPlaylistRef.current];
    const newItems = [...playlist.items];
    logEvent("===== handleNewPlaylist START =====");
    logEvent(`Old playlist (length ${oldItems.length}):\n  ${JSON.stringify(oldItems, null, 2)}`);
    logEvent(`Incoming new playlist (length ${newItems.length}):\n  ${JSON.stringify(newItems, null, 2)}`);
    logEvent(`CurrentIndex = ${currentIndex}`);

    const keepUpToCurrent = oldItems.slice(0, currentIndex + 1);
    const afterCurrent = oldItems.slice(currentIndex + 1);
    const onlyPrioritySuffix = afterCurrent.filter((url) => isPriorityItem(url));

    // 1) Start with the portion we want to keep
    let finalMerged = [...keepUpToCurrent, ...onlyPrioritySuffix];

    // 2) Append new incoming items, skipping duplicates
    for (const incomingUrl of newItems) {
      // If we've already got that URL in finalMerged, skip it
      if (!finalMerged.includes(incomingUrl)) {
        finalMerged.push(incomingUrl);
      }
    }

    // Remove consecutive duplicates (normal or priority)
    removeConsecutiveDuplicates(finalMerged);

    // -- HERE is the special check for "old playlist was exactly one item" --
    if (oldItems.length < 3) {
      logEvent("Old playlist had less than three items (probably just the first default item) => discarding it & using only the new items");
      finalMerged = [...playlist.items]; // completely replace
      prevPlaylistRef.current = finalMerged;
      replacedInitialPlaylist.current = true;

      // Because we just replaced everything, we also want to IMMEDIATELY
      // transition to index=0 of the new playlist
      // We'll do it on a small setTimeout so that the new finalMerged is in place.
      // If there's more than 1 item now, do the forced transition. Otherwise, skip it.
      if (finalMerged.length > 0) {
        setTimeout(() => {
          logEvent("Forcing immediate transition => multiple items now");
          forceTransitionToIndex(0);
        }, 20);
      } else {
        logEvent("We have 0 items => skipping forced transition");
      }
      return;
    }

    logEvent(
      `[handleNewPlaylist] removing non-priority items after current => 
       keepUpToCurrent=${JSON.stringify(keepUpToCurrent)} 
       onlyPrioritySuffix=${JSON.stringify(onlyPrioritySuffix)} 
       finalMerged=${JSON.stringify(finalMerged)}`
    );

    prevPlaylistRef.current = finalMerged;
    replacedInitialPlaylist.current = true;
  }, [playlist]);

  // -----------------------------------------------------
  // startTransition
  // => if nextUrl == oldUrl => remove nextUrl and skip
  // -----------------------------------------------------
  function startTransition() {
    const updatedItems = prevPlaylistRef.current;
    if (!updatedItems || updatedItems.length === 0) {
      logEvent("startTransition => no items in playlist, aborting.");
      return;
    }

    logEvent(
      `[startTransition] updatedItems.length = ${updatedItems ? updatedItems.length : 0}`
    );

    const oldIndex = currentIndex;
    const nextIndex = (oldIndex + 1) % updatedItems.length;

    const oldUrl = updatedItems[oldIndex];
    let nextUrl = updatedItems[nextIndex];

    logEvent(
      `[startTransition] updatedItems=${JSON.stringify(updatedItems)}`
    );
    logEvent(
      `[startTransition] 
         updatedItems.length=${updatedItems.length}
         from index=${oldIndex} (URL=${oldUrl})
         to index=${nextIndex} (URL=${nextUrl})`
    );

    // If nextUrl = oldUrl => remove it from updatedItems to avoid infinite loop
    if (nextUrl === oldUrl && updatedItems.length > 1) {
      logEvent(
        `startTransition => nextUrl == oldUrl => removing repeated item: ${nextUrl}`
      );
      updatedItems.splice(nextIndex, 1);
      // If we removed the next item, let's try again:
      if (updatedItems.length <= 1) {
        logEvent(
          "startTransition => no more items or only 1 left, can't crossfade. Returning."
        );
        return;
      }
      // recalc nextIndex with the new array
      const newNextIndex = (oldIndex + 1) % updatedItems.length;
      nextUrl = updatedItems[newNextIndex];
      prevPlaylistRef.current = updatedItems;
    }

    // If you also want to check for duplicates:
    const duplicates = findDuplicates(updatedItems);
    if (duplicates.length > 0) {
      logEvent(
        `[startTransition] Detected Duplicates in updatedItems => ${JSON.stringify(duplicates)}`
      );
    }

    logEvent(
      `startTransition => from index=${oldIndex} (${oldUrl}) to next => ${nextUrl}`
    );

    lastPlayedVideoNameRef.current = nextUrl;
    setIsTransitioning(true);

    const { active, inactive } = getPlayers();
    logEvent(
      `startTransition => crossfading. activeEl=${activePlayer}, oldUrl=${oldUrl}, newUrl=${nextUrl}`
    );

    
    // Start crossfade
    inactive.currentTime = 0;
    logEvent("Regular transition => make sure inactive is playing right before crossfading in so it crossfades to a playing video...");
    tryPlayInactive(inactive, "regular transition preload");

    active.style.transition = `opacity ${crossfadeDuration}ms ease-in-out`;
    inactive.style.transition = `opacity ${crossfadeDuration}ms ease-in-out`;
    active.style.opacity = 0;
    inactive.style.opacity = 1;

    setTimeout(() => {
      // Crossfade done
      active.style.opacity = 0;
      inactive.style.opacity = 1;

      // We must find the newNextIndex again if we removed items:
      const newNextIndex = (oldIndex + 1) % updatedItems.length;
      // Actually update state
      setCurrentIndex(newNextIndex);
      if (onIndexChange) onIndexChange(newNextIndex);

      const newActive = activePlayer === "A" ? "B" : "A";
      setActivePlayer(newActive);

      // Preload the next item
      const upcoming = (newNextIndex + 1) % updatedItems.length;
      if (updatedItems[upcoming]) {
        const upNextUrl = updatedItems[upcoming];
        logEvent(
          `startTransition => preloading upcoming index=${upcoming} url=${upNextUrl}`
        );
        const newPlayers =
          newActive === "A"
            ? { active: videoARef.current, inactive: videoBRef.current }
            : { active: videoBRef.current, inactive: videoARef.current };
        attachVideoSource(newPlayers.inactive, upNextUrl, false);
      }

      setIsTransitioning(false);
      tryPlayActive("after crossfade");

      // If the OLD index was a priority clip => remove from the set
      if (isPriorityItem(oldUrl)) {
        logEvent(`startTransition => removing old priority item from set: ${oldUrl}`);
        prioritySetRef.current.delete(oldUrl);
      }

      logEvent(
        `[startTransition] complete => new index=${nextIndex}, newActiveEl=${newActive}, nextUrl=${updatedItems[nextIndex]}`
      );
    }, crossfadeDuration);
  }

  function findDuplicates(arr) {
    const seen = new Set();
    const duplicates = [];
    arr.forEach((item) => {
      if (seen.has(item)) duplicates.push(item);
      else seen.add(item);
    });
    return duplicates;
  }

  // -----------------------------------------------------
  // doForceTransition
  // => also handle the nextUrl==oldUrl if that occurs
  // -----------------------------------------------------
  function doForceTransition(targetIndex) {
    logEvent(`Forced transition to index ${targetIndex} => ${prevPlaylistRef.current[targetIndex]}`);
    const updatedItems = prevPlaylistRef.current;
    logEvent(
      `[doForceTransition] targetIndex=${targetIndex}, length=${updatedItems ? updatedItems.length : 0}`
    );
    if (!updatedItems || !updatedItems[targetIndex]) {
      logEvent("[doForceTransition] invalid targetIndex or empty list => abort");
      console.warn("Invalid forced transition =>", targetIndex);
      return;
    }
    setIsTransitioning(true);

    const targetUrl = updatedItems[targetIndex];
    const oldUrl = updatedItems[currentIndex];
    logEvent(`[doForceTransition] oldUrl=${oldUrl} => targetUrl=${targetUrl}`);

    //WHEN REPLACING THE INITIAL PLAYLIST THE OLD URL INCORRECTLY SHOWS AS THE SAME AS THE NEW URL FOR ITEM 0, TRANSITION ANYWAY
    if (targetIndex !== 0 && targetIndex != currentIndex && targetUrl === oldUrl) {
      logEvent(
        `[doForceTransition] targetUrl == oldUrl => skipping insertion: ${targetUrl}`
      );
      setIsTransitioning(false);
      return;
    }

    lastPlayedVideoNameRef.current = targetUrl;

    const { active, inactive } = getPlayers();
    attachVideoSource(inactive, targetUrl, true);

    // Start crossfade
    inactive.currentTime = 0;
    logEvent("Forced transition => make sure inactive video is playing right before crossfading in so it crossfades to a playing video...");
    tryPlayInactive(inactive, "forced transition preload");

    active.style.transition = `opacity ${crossfadeDuration}ms ease-in-out`;
    inactive.style.transition = `opacity ${crossfadeDuration}ms ease-in-out`;
    active.style.opacity = 0;
    inactive.style.opacity = 1;

    setTimeout(() => {
      active.style.opacity = 0;
      inactive.style.opacity = 1;

      setCurrentIndex(targetIndex);
      if (onIndexChange) onIndexChange(targetIndex);

      const newActive = activePlayer === "A" ? "B" : "A";
      setActivePlayer(newActive);

      const upcoming = (targetIndex + 1) % updatedItems.length;
      const newPlayers =
        newActive === "A"
          ? { active: videoARef.current, inactive: videoBRef.current }
          : { active: videoBRef.current, inactive: videoARef.current };
      if (updatedItems[upcoming]) {
        attachVideoSource(newPlayers.inactive, updatedItems[upcoming], false);
      }

      setIsTransitioning(false);
      tryPlayActive("after forced transition");
    }, crossfadeDuration);
  }

  function forceTransitionToIndex(idx) {
    logEvent("forceTransitionToIndex: " + idx);
    const updatedItems = prevPlaylistRef.current || [];
    if (!updatedItems || idx < 0 || idx >= updatedItems.length) {
      console.warn("Invalid targetIndex for forced transition:", idx);
      return;
    }
    if (!replacedInitialPlaylist.current) {
      replacedInitialPlaylist.current = true;
      doForceTransition(idx);
      return;
    }
    if (isTransitioning) {
      queuedUrgentIndexRef.current = idx;
      return;
    }
    const { active } = getPlayers();
    if (active && active.currentTime < 4) {
      setPendingForceTransitionIndex(idx);
      return;
    }
    doForceTransition(idx);
  }

  // -----------------------------------------------------
  // handleTimeUpdate => near-end crossfade or immediate priority
  // -----------------------------------------------------
  useEffect(() => {
    const { active } = getPlayers();
    if (!active) return;

    function handleTimeUpdate() {
      const activeVideo = getActiveVideoElement();
      const updatedItems = prevPlaylistRef.current;
      if (!activeVideo || !updatedItems) return;

      // --- DEBUG: Output currentTime, duration, timeRemaining ---
      const currentUrl = updatedItems[currentIndex] || "(no url)";
      const duration = activeVideo.duration || 0;
      const currentTime = activeVideo.currentTime || 0;
      const timeRemaining = duration ? (duration - currentTime) : Infinity;

      // --- NEW: If it's a priority item, we haven't double-played it yet,
      //     and we're near the end => force crossfade to the same index.
      if (
        !isTransitioning &&
        isPriorityItem(currentUrl) &&
        !doublePlayDoneRef.current.has(currentUrl) &&
        timeRemaining <= crossfadeDuration / 1000
      ) {
        logEvent(`[handleTimeUpdate] Priority item => double-play: forcing re-play of ${currentUrl}`);
        doublePlayDoneRef.current.add(currentUrl); // mark done, so we don’t keep doing it forever
        doForceTransition(currentIndex);           // crossfade to the same index
        return;
      }

      /*logEvent(
        `[handleTimeUpdate] 
         => currentIndex=${currentIndex}, 
         url=${currentUrl}, 
         currentTime=${currentTime.toFixed(2)}s, 
         duration=${duration.toFixed(2)}s, 
         timeRemaining=${timeRemaining.toFixed(2)}s`
      );*/

      // A) If we have a pending forced transition at 4s
      if (pendingForceTransitionIndex != null && activeVideo.currentTime >= 4) {
        const idx = pendingForceTransitionIndex;
        logEvent(
          `handleTimeUpdate => pendingForceTransitionIndex=${idx}, activeVideo.currentTime=${activeVideo.currentTime}`
        );
        setPendingForceTransitionIndex(null);
        doForceTransition(idx);
        return;
      }

      // B) If the current item is priority, near the end, and more priority is queued
      if (
        !isTransitioning &&
        timeRemaining <= crossfadeDuration / 1000 &&
        isPriorityItem(updatedItems[currentIndex]) &&
        priorityQueueRef.current.length > 0
      ) {
        const nextPrioUrl = priorityQueueRef.current.shift();
        logEvent(
          `[priority->priority] near end => forcibly insert nextPrioUrl=${nextPrioUrl}`
        );

        const insertPos = currentIndex + 1;
        // Quick check if next item is exactly the same
        if (insertPos < updatedItems.length && updatedItems[insertPos] === nextPrioUrl) {
          logEvent("[priority->priority] The nextPrioUrl is already next => ignoring");
          return;
        }

        updatedItems.splice(insertPos, 0, nextPrioUrl);
        prioritySetRef.current.add(nextPrioUrl);
        prevPlaylistRef.current = updatedItems;

        doForceTransition(insertPos);
        return;
      }

      // C) If we've played >=4s of a non-priority item, and have a priority in queue => immediate
      if (
        !isTransitioning &&
        activeVideo.currentTime >= 4 &&
        !isPriorityItem(updatedItems[currentIndex]) &&
        priorityQueueRef.current.length > 0
      ) {
        const nextPrioUrl = priorityQueueRef.current.shift();
        logEvent(
          `[handleTimeUpdate] Found priority clip, splicing it in => ${nextPrioUrl}`
        );

        const insertPos = currentIndex + 1;
        // Avoid duplicates
        if (insertPos < updatedItems.length && updatedItems[insertPos] === nextPrioUrl) {
          logEvent("Already next => ignoring priority insertion");
          return;
        }
        updatedItems.splice(insertPos, 0, nextPrioUrl);
        prioritySetRef.current.add(nextPrioUrl);
        prevPlaylistRef.current = updatedItems;

        doForceTransition(insertPos);
        return;
      }

      // D) Normal near-end crossfade
      if (!isTransitioning && timeRemaining <= crossfadeDuration / 1000) {
        const nextIndex = (currentIndex + 1) % updatedItems.length;
        const nextUrl = updatedItems[nextIndex];

        // If next item is NOT priority, but queue has priority => insert
        if (!isPriorityItem(nextUrl) && priorityQueueRef.current.length > 0) {
          const newPrioUrl = priorityQueueRef.current.shift();
          logEvent(
            `[handleTimeUpdate] Inserting next priority *in front of* normal clip => ${newPrioUrl}`
          );
          if (updatedItems[nextIndex] !== newPrioUrl) {
            updatedItems.splice(nextIndex, 0, newPrioUrl);
            prioritySetRef.current.add(newPrioUrl);
            prevPlaylistRef.current = updatedItems;
            doForceTransition(nextIndex);
          }
        } else {
          logEvent(
            `handleTimeUpdate => normal near-end crossfade, timeRemaining=${timeRemaining}, 
             oldIndex=${currentIndex} => nextIndex=${nextIndex}, nextUrl=${nextUrl}`
          );
          startTransition();
        }
      }
    }

    active.addEventListener("timeupdate", handleTimeUpdate);
    return () => active.removeEventListener("timeupdate", handleTimeUpdate);
  }, [
    activePlayer,
    currentIndex,
    isTransitioning,
    pendingForceTransitionIndex,
  ]);

  // -----------------------------------------------------
  // Render
  // -----------------------------------------------------
  if (!playlist.items || playlist.items.length === 0) {
    return <p>No videos available.</p>;
  }

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "1280px",
        aspectRatio: "16/9",
        margin: "0 auto",
        background: "black",
      }}
    >
      <video
        ref={videoARef}
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "contain",
          opacity: activePlayer === "A" ? 1 : 0,
          transition: `opacity ${crossfadeDuration}ms ease-in-out`,
        }}
      />
      <video
        ref={videoBRef}
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "contain",
          opacity: activePlayer === "B" ? 1 : 0,
          transition: `opacity ${crossfadeDuration}ms ease-in-out`,
        }}
      />
    </div>
  );
});

export default VideoPlaylist;

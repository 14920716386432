import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import ReactMarkdown from "react-markdown";
import { Buffer } from "buffer";
import axios from "axios";

const DEBUG = false; // Toggle as desired
const DEBUG_FULL_AUDIO_FILES = false; // Toggle as desired
const DEBUG_QUEUE_WAITING = false; // Toggle as desired
const DEBUG_CUSTOM_MUSIC = false; // Toggle as desired

function debugLog(...args) {
  if (DEBUG) {
    console.log("[StreamingMeditation]", ...args);
  }
}

function debugLogFullAudioFiles(...args) {
  if (DEBUG_FULL_AUDIO_FILES) {
    console.log("[StreamingMeditation]", ...args);
  }
}

function debugLogQueueWaiting(...args) {
  if (DEBUG_QUEUE_WAITING) {
    console.log("[StreamingMeditation]", ...args);
  }
}

function debugLogCustomMusic(...args) {
  if (DEBUG_CUSTOM_MUSIC) {
    console.log("[StreamingMeditation]", ...args);
  }
}

function mergeTextWithNewlines(current, newChunk) {
  return current.trim().length > 0 ? current + "\n\n" + newChunk : newChunk;
}

const StreamingMeditation = forwardRef(function StreamingMeditation(
  {
    prompt,
    voice,
    onMediaCommand,
    onCustomMusicAvailable,
    onCustomMusicStop,
    onReceivedCompleteText,
    onLastTTSComplete,
    onTTSPlay,
    onNewNonPriorityText,
    onVisibleTextChange,
    socket,
    sessionId,
    globalVolume,
    isExistingSession
  },
  ref
) {
  const [fullText, setFullText] = useState("");
  const [visibleText, setVisibleText] = useState("");

  // The main queue of clips (audio/pause/media/finalTTS lines)
  const [audioQueue, setAudioQueue] = useState([]);
  const [sentIdleLine, setSentIdleLine] = useState(false);

  // Have we received finalTTS? (So we can handle pending stopMusic, etc.)
  const [finalTTSReceived, setFinalTTSReceived] = useState(false);

  // Tracks if we are currently in the middle of a pause or an audio playback
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  // Tracks if the user explicitly paused. If true, no new audio will start.
  const [manuallyPaused, setManuallyPaused] = useState(false);

  // Holds any stopMusic items until finalTTS is done:
  const pendingStopMusicRef = useRef([]);
  // Only call /api/stream once
  const hasCalledApiRef = useRef(false);

  // References to current item/timer/audio
  const currentClipRef = useRef(null);
  const currentPauseTimerRef = useRef(null);
  const currentAudioRef = useRef(null);

  // Keep a ref to the global volume
  const globalVolumeRef = useRef(globalVolume);
  useEffect(() => {
    globalVolumeRef.current = globalVolume;
  }, [globalVolume]);

  // -----------------------------------------------------------
  // base64 -> data URI
  // -----------------------------------------------------------
  function base64ToDataUrl(base64String) {
    return `data:audio/mp3;base64,${base64String}`;
  }

  // -----------------------------------------------------------
  // processData => merges lines, sets up queue items
  // -----------------------------------------------------------
  function processData(data, isPriority = false) {
    debugLog("processData =>", data);
  
    // If the server signals the entire text
    if (data.meditationText) {
      onReceivedCompleteText?.(data.meditationText);
    }
  
    // Convert base64 => data URI if needed
    if (data.audio && !data.audioUrl) {
      data.audioUrl = base64ToDataUrl(data.audio);
    }
  
    // Gather any relevant info from data
    let finalTTSFlag = false;
    const pauseCommands = [];
    const mediaCommands = [];
  
    // -----------------------------------------------------------
    // 1) Pull out preCommands => pause / media / finalTTS
    // -----------------------------------------------------------
    if (Array.isArray(data.preCommands)) {
      data.preCommands.forEach(cmd => {
        if (cmd.type === "pause") {
          // If you expect multiple (pause) commands in the same chunk,
          // collect them into an array. You can also just keep the
          // last one, depending on your desired behavior.
          pauseCommands.push(cmd.value);
        }
        if (cmd.type === "media") {
          mediaCommands.push(cmd.value);
        }
        if (cmd.type === "finalTTS") {
          finalTTSFlag = true;
          setFinalTTSReceived(true);
        }
      });
    }
  
    // -----------------------------------------------------------
    // 2) Stop music => if we need to queue them after finalTTS
    // -----------------------------------------------------------
    if (data.stopMusic) {
      // If data.audioUrl is still base64, convert it to data URI
      const audioUrl = data.audioUrl
        ? data.audioUrl
        : data.audio
          ? base64ToDataUrl(data.audio)
          : null;
    
      pendingStopMusicRef.current.push({
        stopMusic: true,
        finalTTS: finalTTSFlag,
        audioUrl: audioUrl,  // <-- store the actual music URL
      });
      return;
    }
  
    // -----------------------------------------------------------
    // 3) Enqueue pause clips first
    //    - Each pause is a separate clip
    // -----------------------------------------------------------
    pauseCommands.forEach(pValue => {
      if (pValue && typeof pValue === "number") {
        const pauseClip = {
          audioUrl: null,
          text: "",
          writtenText: "",
          pause: pValue,        // # of seconds
          media: null,
          finalTTS: false,      // not marking final yet
          stopMusic: false,
          priority: isPriority
        };
        enqueueClip(pauseClip);
      }
    });
  
    // -----------------------------------------------------------
    // 4) Enqueue all media commands (in sequence)
    //    - Each media is its own clip, with no text/audio/pause
    // -----------------------------------------------------------
    mediaCommands.forEach((mediaPath, idx) => {
      const mediaClip = {
        audioUrl: null,
        text: "",
        writtenText: "",
        pause: null,
        media: mediaPath,
        // If you prefer the last media clip to carry finalTTS (and no text),
        // you could do: finalTTS: (finalTTSFlag && idx === mediaCommands.length - 1)
        // But typically we’ll handle finalTTS on the text/audio clip below.
        finalTTS: false,
        stopMusic: false,
        priority: isPriority
      };
      enqueueClip(mediaClip);
    });
  
    // -----------------------------------------------------------
    // 5) Enqueue any text/audio in one final clip
    //    - This can also hold finalTTS if needed
    // -----------------------------------------------------------
    const hasTextOrAudio = !!(data.spokenText || data.writtenText || data.audioUrl);
    if (hasTextOrAudio) {
      const mainClip = {
        audioUrl: data.audioUrl || null,
        text: data.spokenText || "",
        writtenText: data.writtenText || "",
        pause: null,        // no pause in the text clip
        media: null,        // we already enqueued media above
        finalTTS: finalTTSFlag,
        stopMusic: false,
        priority: isPriority
      };
      enqueueClip(mainClip);
    } else if (finalTTSFlag && mediaCommands.length > 0) {
      // If there's no text/audio but we got a finalTTS flag,
      // we may want to attach finalTTS to the **last** media clip.
      // Or simply create a blank final clip. 
      // For example, you can do:
      debugLog("[processData] finalTTS with no text => attaching finalTTS to last media clip");
      setAudioQueue(prev => {
        // Find the last media clip in the queue and mark finalTTS = true
        // (This only works if you just enqueued the media above.)
        const newQueue = [...prev];
        for (let i = newQueue.length - 1; i >= 0; i--) {
          if (!newQueue[i].audioUrl && newQueue[i].media) {
            newQueue[i].finalTTS = true;
            break;
          }
        }
        return newQueue;
      });
    }
  
    // If anything was enqueued, reset the idle line
    if (
      pauseCommands.length > 0 ||
      mediaCommands.length > 0 ||
      hasTextOrAudio
    ) {
      setSentIdleLine(false);
    }
  }
  
  // Helper to insert the clip into the queue
  function enqueueClip(clip) {
    if (clip.priority) {
      setAudioQueue(prev => {
        const newQueue = [...prev];
        // Insert right after the last priority item (or at front)
        let lastPriorityIndex = -1;
        for (let i = 0; i < newQueue.length; i++) {
          if (newQueue[i].priority) lastPriorityIndex = i;
        }
        if (lastPriorityIndex === -1) {
          // If no priority items
          if (newQueue.length === 0) newQueue.push(clip);
          else newQueue.splice(1, 0, clip);
        } else {
          newQueue.splice(lastPriorityIndex + 1, 0, clip);
        }
        return newQueue;
      });
    } else {
      // Normal clip => append to end
      setAudioQueue(prev => [...prev, clip]);
    }
  }

  // If the displayed text changes, notify parent
  useEffect(() => {
    onVisibleTextChange?.(visibleText);
  }, [visibleText, onVisibleTextChange]);

  // -----------------------------------------------------------
  // #1: Listen for "streamData" from the socket
  // -----------------------------------------------------------
  useEffect(() => {
    if (!socket || !sessionId) return;
    debugLog("Attach 'streamData' => socket.id=", socket.id);

    const streamHandler = data => {
      debugLog("streamData =>", data);
      processData(data);
    };

    socket.on("streamData", streamHandler);

    return () => {
      debugLog("Detach 'streamData'");
      socket.off("streamData", streamHandler);
    };
  }, [socket, sessionId]);

  // -----------------------------------------------------------
  // #2: Call /api/stream once
  // -----------------------------------------------------------
  useEffect(() => {
    if (!socket || !sessionId || !prompt) return;
    if (hasCalledApiRef.current) return;

    hasCalledApiRef.current = true;
    debugLog("Calling /api/stream => prompt=", prompt);

    axios
      .get(
        `/api/stream?prompt=${encodeURIComponent(prompt)}&sessionId=${sessionId}&voice=${encodeURIComponent(
          voice
        )}`
      )
      .then(response => {
        debugLog("Streaming initiated =>", response.data.message);
      })
      .catch(error => {
        console.error("Error initiating stream =>", error);
      });
  }, [socket, sessionId, prompt, voice]);

  // -----------------------------------------------------------
  // processPendingStopMusic => flush stopMusic lines after finalTTS
  // -----------------------------------------------------------
  const processPendingStopMusic = () => {
    if (pendingStopMusicRef.current.length > 0) {
      debugLogCustomMusic("Flushing stopMusic => maybe custom music...");
      pendingStopMusicRef.current.forEach(clip => {
        if (window.bgMusicManager?.addToCustomPlaylist) {
          debugLogCustomMusic("Add custom music...");
          window.bgMusicManager.addToCustomPlaylist(clip.audioUrl);
        }
      });
      onCustomMusicAvailable?.();
      pendingStopMusicRef.current = [];
    }
  };

  // -----------------------------------------------------------
  // #3: Main queue effect => picks next clip
  // -----------------------------------------------------------
  useEffect(() => {
    // If user manually paused, do NOT pick the next clip
    if (manuallyPaused) {
      debugLog("[QUEUE EFFECT] Manually paused => Skip picking next clip");
      return;
    }
  
    // If something is already playing (or a pause timer is active), do nothing
    if (isAudioPlaying) {
      debugLogQueueWaiting("[QUEUE EFFECT] Currently isAudioPlaying => Do nothing");
      return;
    }
  
    // If queue is empty, do nothing
    if (audioQueue.length === 0) {
      debugLogQueueWaiting("[QUEUE EFFECT] audioQueue is empty => Nothing to process");
      return;
    }
  
    // Take the first item in the queue
    const clip = audioQueue[0];
    currentClipRef.current = clip;
    setIsAudioPlaying(true);
    debugLog("[QUEUE EFFECT] Processing clip =>", clip);
  
    // Validate numeric pause
    let pauseMs = 0;
    if (clip.pause && typeof clip.pause === "number" && !Number.isNaN(clip.pause)) {
      pauseMs = clip.pause * 1000;
      debugLog(`[QUEUE EFFECT] Detected pause => ${pauseMs} ms`);
    }
  
    // Start a timer for the pause (could be zero ms if no pause)
    const timerId = setTimeout(() => {
      currentPauseTimerRef.current = null;
  
      // If the queue changed mid‐pause, bail
      if (currentClipRef.current !== clip) {
        debugLog("[QUEUE EFFECT] Clip changed mid‐pause => skip old clip");
        setIsAudioPlaying(false);
        return;
      }
  
      // If there's text, append it
      if (clip.writtenText && clip.writtenText.trim().length > 0) {
        let appendedText = clip.writtenText;
        // If priority, optionally mark it in some special way
        if (clip.priority) {
          appendedText = appendedText
            .split("\n")
            .map(line => (line.trim() ? `*${line.trim()}*` : ""))
            .join("\n");
        }
        setFullText(prev => mergeTextWithNewlines(prev, appendedText));
        setVisibleText(prev => mergeTextWithNewlines(prev, appendedText));
        debugLog("[QUEUE EFFECT] Appended text =>", appendedText);
      }
  
      // If there's an audioUrl, play it
      if (clip.audioUrl && !clip.stopMusic) {
        debugLog("[QUEUE EFFECT] Playing audio");
        const audioObj = new Audio(clip.audioUrl);
        audioObj.volume = globalVolumeRef.current ?? 1.0;
        currentAudioRef.current = audioObj;
  
        let finishedNotified = false;
  
        audioObj.addEventListener("timeupdate", () => {
          // If we haven't already notified and near the end => notify server
          if (
            !finishedNotified &&
            (audioObj.duration - audioObj.currentTime <= 2)
          ) {
            finishedNotified = true;
            if (!clip.priority && socket && sessionId) {
              debugLog("[QUEUE EFFECT] near end => emit('finishedLine')");
              socket.emit("finishedLine", { sessionId });
            }
          }
        });
  
        audioObj.addEventListener("ended", () => {
          if (!finishedNotified && socket && sessionId && !clip.priority) {
            debugLog("[QUEUE EFFECT] ended => emit('finishedLine')");
            socket.emit("finishedLine", { sessionId });
          }
          // Remove the clip from queue and do a short gap
          setIsAudioPlaying(false);
          setAudioQueue(prev => {
            const newQueue = prev.slice(1); // remove first
            if (finalTTSReceived && newQueue.length === 0) {
              onLastTTSComplete?.();
              processPendingStopMusic();
              return newQueue;
            }
            // Insert a 1s pause clip so we don't butt up the next audio
            var isAudioClip = clip.audioUrl && clip.audioUrl.length > 0;
            if (isAudioClip) {
              newQueue.unshift({
                pause: 1,
                text: "",
                writtenText: "",
                audioUrl: null,
                media: null,
                stopMusic: false,
                finalTTS: false,
                priority: clip.priority
              });
            }
            return newQueue;
          });
          currentClipRef.current = null;
          currentAudioRef.current = null;
        });
  
        audioObj
          .play()
          .then(() => {
            if (clip.media) {
              debugLog("[QUEUE EFFECT] sending media command =>", clip.media);
              onMediaCommand?.(clip.media);
            }
            onTTSPlay?.(clip.text);
            if (!clip.priority && clip.writtenText) {
              onNewNonPriorityText?.(clip.writtenText);
            }
          })
          .catch(err => {
            debugLog("[QUEUE EFFECT] audioObj.play error =>", err);
            setAudioQueue(prev => prev.slice(1));
            setIsAudioPlaying(false);
          });
      } else {
        // No audio => Could be pause-only, media-only, or finalTTS
        debugLog("[QUEUE EFFECT] No audio in clip =>", clip);
  
        // If there's a media command, call it
        if (clip.media) {
          debugLog("[QUEUE EFFECT] sending media command =>", clip.media);
          onMediaCommand?.(clip.media);
        }
  
        // If finalTTS => we know we're done
        if (clip.finalTTS) {
          debugLog("[QUEUE EFFECT] finalTTS => setFinalTTSReceived(true)");
          setFinalTTSReceived(true);
        }
  
        // Remove the clip from the queue
        setAudioQueue(prev => {
          const newQueue = prev.slice(1);
          if (finalTTSReceived && newQueue.length === 0) {
            onLastTTSComplete?.();
            processPendingStopMusic();
          }
          return newQueue;
        });
        setIsAudioPlaying(false);
        currentClipRef.current = null;
        currentAudioRef.current = null;
      }
    }, pauseMs);
  
    currentPauseTimerRef.current = timerId;
  
    // On unmount => clear any leftover timer
    return () => {
      debugLog("component unmount => clearing timer:", timerId);
    };
  }, [
    audioQueue,
    finalTTSReceived,
    isAudioPlaying,
    manuallyPaused,
    onMediaCommand,
    onLastTTSComplete,
    onNewNonPriorityText,
    onTTSPlay,
    sessionId,
    socket
  ]);
  

  // -----------------------------------------------------------
  // #4: If no finalTTS, queue empty, not playing => poke server
  // -----------------------------------------------------------
  useEffect(() => {
    if (!finalTTSReceived && audioQueue.length === 0 && !isAudioPlaying && socket && sessionId) {
      const intervalId = setInterval(() => {
        if (
          !finalTTSReceived &&
          audioQueue.length === 0 &&
          !isAudioPlaying &&
          socket &&
          sessionId
        ) {
          if (!sentIdleLine) {
            socket.emit("finishedLine", { sessionId });
            setSentIdleLine(true);
          }
        }
      }, 2000);
      return () => clearInterval(intervalId);
    }
  }, [finalTTSReceived, audioQueue, isAudioPlaying, socket, sessionId, sentIdleLine]);

  // -----------------------------------------------------------
  // Public methods
  // -----------------------------------------------------------
  useImperativeHandle(ref, () => ({
    stopCustomMusicLoop: () => {
      pendingStopMusicRef.current = [];
      onCustomMusicStop?.();
    },
    restartCustomMusicLoop: () => {
      debugLog("restartCustomMusicLoop => not implemented");
    },
    insertPriorityAudio(priorityData) {
      // We simply pass 'true' for isPriority to processData
      processData(priorityData, true);
    },
    updateGlobalVolume: volume => {
      if (currentAudioRef.current) {
        currentAudioRef.current.volume = volume;
      }
    },
    pausePlayback: () => {
      setManuallyPaused(true);

      const clip = currentClipRef.current;

      // If an audio clip is actively playing, pause it but keep it in the queue
      if (clip?.audioUrl && currentAudioRef.current) {
        currentAudioRef.current.pause();
      }
      // If we were in a "pause" clip (timeout), clear that timer and remove from queue
      else if (clip?.pause && currentPauseTimerRef.current) {
        clearTimeout(currentPauseTimerRef.current);
        currentPauseTimerRef.current = null;

        setAudioQueue(prev => prev.slice(1));
        setIsAudioPlaying(false);
      }
    },
    resumePlayback: () => {
      setManuallyPaused(false);

      const clip = currentClipRef.current;
      if (!clip) return; // nothing to resume

      // If the clip was removed from the queue (like a timed pause), no resume
      if (!audioQueue.includes(clip)) {
        debugLog("resumePlayback => clip not in queue, skipping");
        return;
      }

      // If mid-audio, rewind slightly and resume
      if (clip.audioUrl && currentAudioRef.current) {
        const audioObj = currentAudioRef.current;
        let newTime = audioObj.currentTime - 2;
        if (newTime < 0) newTime = 0;
        audioObj.currentTime = newTime;
        audioObj.play().catch(err => console.error("Resume error:", err));
      }
    },
    resetForReplay: () => {
      debugLog("Resetting StreamingMeditation for replay...");
      setFullText("");
      setVisibleText("");
      setAudioQueue([]);
      setFinalTTSReceived(false);
      setIsAudioPlaying(false);
      setManuallyPaused(false);

      // Kill any active timers
      if (currentPauseTimerRef.current) {
        clearTimeout(currentPauseTimerRef.current);
        currentPauseTimerRef.current = null;
      }
      // Stop/clear any playing audio
      if (currentAudioRef.current) {
        currentAudioRef.current.pause();
        currentAudioRef.current = null;
      }
      currentClipRef.current = null;
    }
  }));

  return (
    <div>
      <div
        className="meditation-text"
        style={{ fontSize: visibleText === "" ? "0.95rem" : "1rem" }}
      >
        <ReactMarkdown>
          {visibleText === ""
            ? (
              isExistingSession 
                ? `Before generating a meditation, take a moment to reflect on what truly matters to you right now. Feel free to include any emotions, intentions, or challenges you’d like to explore.

The more details you share, the more personal and supportive your meditation will be.

Type these details into the text box below, choose Female or Male Voice, then press Generate Meditation.

You can double-click the video to toggle its size.

The meditation responds to Relaxation changes, which include Resonance and average heart rate.`
                :`Before generating a meditation, take a moment to reflect on what truly matters to you right now.

Feel free to include any emotions, intentions, or challenges you’d like to explore.

The more details you share, the more personal and supportive your meditation will be.

Type these details into the text box below, choose Female or Male Voice, then press Generate Meditation.

You can double-click the video to toggle its size.`
  )
            : visibleText}
        </ReactMarkdown>
      </div>
    </div>
  );
});

export default StreamingMeditation;

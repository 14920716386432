import React, { useState, useRef, useEffect } from 'react';

const DEBUG = false;

function logEvent(msg) {
  if (DEBUG) {
    console.log(`[${new Date().toISOString()}] ${msg}`);
  }
}

/**
 * 1) This component renders messages and the "Processing..." indicator.
 *    It is memoized, so it won't re-render unnecessarily.
 */
const ChatMessages = React.memo(function ChatMessages({ chatMessages, isLoading, messagesEndRef }) {
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages, messagesEndRef]);

  return (
    <div className="chat-messages" style={{ overflowY: 'auto', maxHeight: '300px' }}>
      {chatMessages.map((msg, index) => (
        <div key={index} className={`chat-message ${msg.sender}`}>
          <span>{msg.text}</span>
        </div>
      ))}
      {/* Keep this ref so that we can scroll to the bottom */}
      <div ref={messagesEndRef} />
      {isLoading && (
        <div className="chat-message ai">
          <span>Processing...</span>
        </div>
      )}
    </div>
  );
});

/**
 * 2) This component handles the text input and Send button.
 *    It is also memoized, so it only re-renders if its *own* props change.
 */
const ChatInput = React.memo(function ChatInput({
  chatInput,
  handleInputChange,
  handleSend,
  isLoading,
}) {
  const inputRef = useRef(null);

  // Whenever loading finishes, ensure the input regains focus.
  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  return (
    <div className="chat-input-container">
      <input
        ref={inputRef}
        type="text"
        value={chatInput}
        onChange={handleInputChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            handleSend();
          }
        }}
        placeholder="Chat about your experience..."
        className="chat-input"
        disabled={isLoading}
      />
      <button onClick={handleSend} className="chat-send-button" disabled={isLoading}>
        Send to AI
      </button>
    </div>
  );
});

/**
 * 3) Your main ChatDialog container component.
 *    Only minimal modifications here—everything else is the same streaming logic.
 */
function ChatDialog({ meditationText, sessionStats, sessionId }) {
  // Use default text if no meditationText is provided.
  const defaultMeditationText =
    "Default meditation text for testing:\n\n" +
    "This is a sample meditation exercise text that guides the user through a calming experience of mindfulness and relaxation.";

  const actualMeditationText =
    meditationText && meditationText.trim() ? meditationText : defaultMeditationText;

  // Append session statistics if provided.
  const promptMeditationText =
    sessionStats && sessionStats.trim().length > 0
      ? `The user just completed a meditation: ${actualMeditationText}\n\n${sessionStats}`
      : actualMeditationText;

  logEvent("Using meditation text:", promptMeditationText);

  const usingSessionStats = sessionStats && sessionStats.trim().length > 0;

  const [chatInput, setChatInput] = useState(
    usingSessionStats
      ? "When was I most relaxed during the meditation?"
      : ""
  );
  
  let defaultChatMessage = "Do you want to talk about, or ask any questions about, this meditation?";
  if (usingSessionStats) {
    defaultChatMessage += " Press 'Send to AI' or replace the question below.";
  }
  
  const [chatMessages, setChatMessages] = useState([
    { sender: 'ai', text: defaultChatMessage }
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  const handleInputChange = (e) => {
    setChatInput(e.target.value);
  };

  const handleSend = async () => {
    if (!chatInput.trim()) return;

    // Add the user's message to the conversation.
    const userMessage = chatInput;
    setChatMessages((prev) => [...prev, { sender: 'user', text: userMessage }]);
    setChatInput('');
    setIsLoading(true);

    try {
      const url = `/chat`;
      // Prepare conversation history in GPT format:
      const history = chatMessages.map((msg) => ({
        role: msg.sender === "user" ? "user" : "assistant",
        content: msg.text,
      }));

      // Include the most recent user message as well:
      history.push({ role: "user", content: userMessage });

      const response = await fetch(url, {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          history,
          meditationText: promptMeditationText,
          sessionId,
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Stream the response from the server.
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let aiReply = "";

      // Insert a placeholder for the AI response.
      setChatMessages((prev) => [...prev, { sender: "ai", text: "" }]);

      let done = false;
      while (!done) {
        const { value, done: doneReading } = await reader.read();
        done = doneReading;
        const chunk = decoder.decode(value);
        aiReply += chunk;
        // Update the last (AI) message with the new chunk.
        setChatMessages((prev) => {
          const updated = [...prev];
          updated[updated.length - 1].text = aiReply;
          return updated;
        });
      }
    } catch (error) {
      console.error("Error in ChatDialog streaming:", error);
      setChatMessages((prev) => [
        ...prev,
        { sender: "ai", text: "Sorry, there was an error processing your message." },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="chat-dialog">
      {/*
        4) We replace your old chat messages section
           with our separate ChatMessages component.
      */}
      <ChatMessages
        chatMessages={chatMessages}
        isLoading={isLoading}
        messagesEndRef={messagesEndRef}
      />

      {/*
        5) We replace your old input section
           with the new ChatInput component.
      */}
      <ChatInput
        chatInput={chatInput}
        handleInputChange={handleInputChange}
        handleSend={handleSend}
        isLoading={isLoading}
      />
    </div>
  );
}

export default React.memo(ChatDialog);

//EVENTUALLY WE USE THE SSF INSTEAD OF ALL OF THIS COMPLEX PAN-TOMPKINS STUFF.


/**
 * ECGToHR.js
 *
 * Enhanced version focusing on the Pan–Tompkins algorithm.
 * Includes bandpass (via chained highpass and lowpass filters)
 * + optional notch filtering, Hampel outlier reduction,
 * improved adaptive threshold, and search-back if beats are missed.
 */

import Fili from 'fili';

export default class ECGToHR {
  /**
   * @param {number} samplingRate - Sampling rate in Hz (default: 130).
   * @param {object} options - Configuration object for filters and improvements.
   *   options.bandpass: {low: number, high: number} for bandpass filter cutoffs in Hz.
   *   options.notch: number or null; set to 50 or 60 to apply a notch filter at that freq.
   *   options.hampelWindow: number of samples for the Hampel filter window.
   *   options.hampelThreshold: threshold factor for outlier detection in the Hampel filter.
   */
  constructor(samplingRate = 130, options = {}) {
    this._samplingRate = samplingRate;
    this._msPerSample = 1000 / samplingRate;

    // ----- Filtering options -----
    this._opts = {
      bandpass: options.bandpass || { low: 0.5, high: 40 }, // e.g. 0.5–40 Hz
      notch: options.notch || 60,                           // e.g. 60 Hz or null
      hampelWindow: options.hampelWindow || 5,
      hampelThreshold: options.hampelThreshold || 3,
    };

    // ------------- DSP Filters ---------------
    // Create bandpass filter by chaining highpass and lowpass filters using Fili.
    this._bandpassFilter = this._createBandpassFilter(
      this._opts.bandpass.low,
      this._opts.bandpass.high
    );
    // Create notch filter if needed.
    this._notchFilter = null;
    if (this._opts.notch) {
      this._notchFilter = this._createNotchFilter(this._opts.notch);
    }

    // ------------- Constants in samples ---------------
    this._BASELINE_WIN = this._samplingRate; // 1 second window
    this._LPF_WIN = 5;                       // for moving average low-pass
    this._DERIV_DELAY = 2;
    this._INTEGRATION_WIN = Math.floor(0.15 * this._samplingRate); // ~150ms
    this._REFRAC_PERIOD = Math.floor(0.2 * this._samplingRate);    // 200ms
    this._SEARCH_WIN = Math.floor(0.1 * this._samplingRate);       // 100ms
    this._MAX_RR_INTERVAL = Math.floor(1.5 * this._samplingRate);  // search-back window

    // ------------- Pan–Tompkins State ---------------
    this._pt = {
      sampleCount: 0,
      baseBuf: [],
      baseSum: 0,
      lpBuf: [],
      lpSum: 0,
      derivBuf: [],
      intBuf: [],
      intSum: 0,
      intPrev2: null,
      intPrev: null,
      signalLevel: 0,
      noiseLevel: 0,
      threshold: 0,
      lastBeatSample: null,
      initPeaks: [],
      initialized: false,
      rrBuf: [],
      avgRR: null,
      missedCount: 0,
    };

    // Slope Sum Function (SSF) window
    this._SSF_WINDOW = Math.floor(0.25 * this._samplingRate); // ~250ms

    // ------------- SSF State ---------------
    this._ssf = {
      sampleCount: 0,
      baseBuf: [],
      baseSum: 0,
      buf: [],
      sum: 0,
      prevVal: 0,
      threshold: 0,
      initVals: [],
      initialized: false,
      lastBeatSample: null,
      searching: false,
      rawPeakVal: -Infinity,
      rawPeakTime: 0,
      searchCount: 0,
    };
  }

  /**
   * Utility: Create a bandpass filter by chaining a high-pass and a low-pass filter.
   * Uses Fili.js to design both filters.
   * @param {number} lowHz - Lower cutoff frequency in Hz.
   * @param {number} highHz - Upper cutoff frequency in Hz.
   * @returns {Object} - Filter object with .process() method.
   */
  _createBandpassFilter(lowHz, highHz) {
    try {
      const iirCalc = new Fili.CalcCascades();
      // Design a 2nd-order highpass filter (one biquad) with cutoff = lowHz.
      const hpCoeffs = iirCalc.highpass({
        order: 1,
        characteristic: 'butterworth',
        Fs: this._samplingRate,
        Fc: lowHz
      });
      const hpFilter = new Fili.IirFilter(hpCoeffs);
      // Design a 2nd-order lowpass filter (one biquad) with cutoff = highHz.
      const lpCoeffs = iirCalc.lowpass({
        order: 1,
        characteristic: 'butterworth',
        Fs: this._samplingRate,
        Fc: highHz
      });
      const lpFilter = new Fili.IirFilter(lpCoeffs);
      console.log(`Bandpass filters created: HP cutoff=${lowHz}Hz, LP cutoff=${highHz}Hz`);
      // For logging process calls.
      let hpCallCount = 0, lpCallCount = 0;
      const logLimit = 5;
      return {
        process: (sample) => {
          let hpOut, lpOut;
          try {
            hpCallCount++;
            if (hpCallCount <= logLimit) {
              console.log(`Highpass process call ${hpCallCount}: input=${sample}`);
            }
            hpOut = hpFilter.multiStep([sample])[0];
            if (hpCallCount <= logLimit) {
              console.log(`Highpass process call ${hpCallCount}: output=${hpOut}`);
            }
          } catch (err) {
            console.error("Error in highpass processing:", err);
            hpOut = sample;
          }
          try {
            lpCallCount++;
            if (lpCallCount <= logLimit) {
              console.log(`Lowpass process call ${lpCallCount}: input=${hpOut}`);
            }
            lpOut = lpFilter.multiStep([hpOut])[0];
            if (lpCallCount <= logLimit) {
              console.log(`Lowpass process call ${lpCallCount}: output=${lpOut}`);
            }
          } catch (err) {
            console.error("Error in lowpass processing:", err);
            lpOut = hpOut;
          }
          return lpOut;
        }
      };
    } catch (err) {
      console.error("Error in _createBandpassFilter:", err);
      return { process: (sample) => sample };
    }
  }

  /**
   * Utility: Create a narrow notch filter using Fili.js bandstop design.
   * @param {number} freqHz - Center notch frequency (e.g. 50 or 60 Hz).
   * @returns {Object} - Filter object with .process() method.
   */
  _createNotchFilter(freqHz) {
    try {
      // For a Q ~ 30, approximate bandwidth BW = freqHz / 30.
      const BW = freqHz / 30;
      const iirCalc = new Fili.CalcCascades();
      const notchCoeffs = iirCalc.bandstop({
        order: 1,
        characteristic: 'butterworth',
        Fs: this._samplingRate,
        Fc: freqHz,
        BW: BW
      });
      const notchFilter = new Fili.IirFilter(notchCoeffs);
      console.log(`Notch filter created: center=${freqHz}Hz, BW=${BW.toFixed(2)}Hz`);
      let callCount = 0;
      const logLimit = 5;
      return {
        process: (sample) => {
          callCount++;
          if (callCount <= logLimit) {
            console.log(`Notch process call ${callCount}: input=${sample}`);
          }
          let output;
          try {
            output = notchFilter.multiStep([sample])[0];
          } catch (err) {
            console.error("Error in notch processing:", err);
            output = sample;
          }
          if (callCount <= logLimit) {
            console.log(`Notch process call ${callCount}: output=${output}`);
          }
          return output;
        }
      };
    } catch (err) {
      console.error("Error in _createNotchFilter:", err);
      return { process: (sample) => sample };
    }
  }

  /**
   * Utility: Simple Hampel filter for outlier rejection.
   * Replaces outlier (deviation > threshold * median absolute deviation) with window median.
   * @param {Array<number>} buffer - Rolling samples.
   * @param {number} windowSize
   * @param {number} threshold
   */
  _hampelFilter(buffer, windowSize, threshold) {
    if (buffer.length < windowSize) return;
    const k = Math.floor(windowSize / 2);
    for (let i = k; i < buffer.length - k; i++) {
      let window = buffer.slice(i - k, i + k + 1);
      let median = this._median(window);
      let absDevs = window.map((x) => Math.abs(x - median));
      let mad = this._median(absDevs);
      if (mad === 0) continue;
      let dev = Math.abs(buffer[i] - median);
      if (dev / mad > threshold) {
        buffer[i] = median;
      }
    }
  }

  /**
   * Utility: Compute median of an array.
   * @param {Array<number>} arr
   * @returns {number}
   */
  _median(arr) {
    if (!arr.length) return 0;
    const sorted = [...arr].sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 !== 0 ? sorted[mid] : 0.5 * (sorted[mid - 1] + sorted[mid]);
  }

  /**
   * Utility: Convert IBI (ms) to Heart Rate (BPM).
   * @param {number} ibi_ms - Inter-beat interval in ms.
   * @returns {number} BPM.
   */
  getHeartRate(ibi_ms) {
    return ibi_ms && ibi_ms > 0 ? Math.round(60000 / ibi_ms) : 0;
  }

  /**
   * Improved Pan–Tompkins:
   *   - Bandpass (via chained HP and LP filters) + optional notch filtering
   *   - Hampel outlier rejection
   *   - Standard PT pipeline
   *   - Search-back if no detection within ~1.5× average RR
   *
   * @param {number} rawSample - The raw ECG sample.
   * @returns {number|null} - IBI in ms if a beat is detected.
   */
  processSamplePanTompkins(rawSample) {
    try {
      const pt = this._pt;
      // 1. Apply bandpass filter
      let filtered = this._bandpassFilter.process(rawSample);
      // 2. Optional notch filtering
      if (this._notchFilter) {
        filtered = this._notchFilter.process(filtered);
      }
      // Baseline removal (moving average)
      if (pt.baseBuf.length >= this._BASELINE_WIN) {
        pt.baseSum -= pt.baseBuf.shift();
      }
      pt.baseBuf.push(filtered);
      pt.baseSum += filtered;
      const baseline = pt.baseSum / pt.baseBuf.length;
      let hpSignal = filtered - baseline;

      // Hampel outlier rejection
      if (!pt.hampelBuf) pt.hampelBuf = [];
      pt.hampelBuf.push(hpSignal);
      if (pt.hampelBuf.length > 2 * this._BASELINE_WIN) {
        pt.hampelBuf.shift();
      }
      if (pt.sampleCount % this._BASELINE_WIN === 0) {
        this._hampelFilter(pt.hampelBuf, this._opts.hampelWindow, this._opts.hampelThreshold);
        console.log(`Hampel filter applied at sampleCount=${pt.sampleCount}`);
      }
      hpSignal = pt.hampelBuf[pt.hampelBuf.length - 1];

      // 2) Low-pass filter (moving average)
      if (pt.lpBuf.length >= this._LPF_WIN) {
        pt.lpSum -= pt.lpBuf.shift();
      }
      pt.lpBuf.push(hpSignal);
      pt.lpSum += hpSignal;
      const lpSignal = pt.lpSum / pt.lpBuf.length;

      // 3) Derivative filter
      let diff = 0;
      if (pt.derivBuf.length >= this._DERIV_DELAY) {
        diff = lpSignal - pt.derivBuf[pt.derivBuf.length - this._DERIV_DELAY];
      }
      pt.derivBuf.push(lpSignal);
      if (pt.derivBuf.length > this._DERIV_DELAY) {
        pt.derivBuf.shift();
      }
      // 4) Squaring
      const squared = diff * diff;
      // 5) Moving window integration
      if (pt.intBuf.length >= this._INTEGRATION_WIN) {
        pt.intSum -= pt.intBuf.shift();
      }
      pt.intBuf.push(squared);
      pt.intSum += squared;
      const integrated = pt.intSum / pt.intBuf.length;

      let ibi = null;
      // 6) Peak detection logic
      if (pt.intPrev2 !== null && pt.intPrev !== null) {
        if (pt.intPrev > pt.intPrev2 && pt.intPrev > integrated) {
          const peakTime = pt.sampleCount - 1;
          const peakVal = pt.intPrev;
          if (!pt.initialized) {
            pt.initPeaks.push(peakVal);
          } else {
            if (
              peakVal > pt.threshold &&
              (pt.lastBeatSample === null ||
               peakTime - pt.lastBeatSample > this._REFRAC_PERIOD)
            ) {
              if (pt.lastBeatSample !== null) {
                ibi = Math.round((peakTime - pt.lastBeatSample) * this._msPerSample);
              }
              pt.lastBeatSample = peakTime;
              pt.signalLevel = 0.125 * peakVal + 0.875 * pt.signalLevel;
              if (ibi) {
                const rr = Math.round(peakTime - (pt.lastBeatSample - ibi / this._msPerSample));
                pt.rrBuf.push(rr);
                if (pt.rrBuf.length > 8) pt.rrBuf.shift();
                pt.avgRR = pt.rrBuf.reduce((a, b) => a + b, 0) / pt.rrBuf.length;
              }
              pt.missedCount = 0;
            } else if (
              peakVal > pt.threshold &&
              pt.lastBeatSample !== null &&
              peakTime - pt.lastBeatSample <= this._REFRAC_PERIOD
            ) {
              pt.signalLevel = 0.125 * peakVal + 0.875 * pt.signalLevel;
            } else {
              pt.noiseLevel = 0.125 * peakVal + 0.875 * pt.noiseLevel;
            }
            pt.threshold = pt.noiseLevel + 0.25 * (pt.signalLevel - pt.noiseLevel);
          }
        }
      }
      pt.intPrev2 = pt.intPrev;
      pt.intPrev = integrated;

      // 7) Initialization after ~2 seconds
      if (!pt.initialized && pt.sampleCount >= 2 * this._samplingRate) {
        if (pt.initPeaks.length > 0) {
          const maxPeak = Math.max(...pt.initPeaks);
          const signalPeaks = pt.initPeaks.filter((p) => p > 0.5 * maxPeak);
          const noisePeaks = pt.initPeaks.filter((p) => p <= 0.5 * maxPeak);
          pt.signalLevel = signalPeaks.length ? signalPeaks.reduce((a, b) => a + b, 0) / signalPeaks.length : 0;
          pt.noiseLevel = noisePeaks.length ? noisePeaks.reduce((a, b) => a + b, 0) / noisePeaks.length : 0;
          pt.threshold = pt.noiseLevel + 0.25 * (pt.signalLevel - pt.noiseLevel);
        } else {
          pt.signalLevel = 0.1;
          pt.noiseLevel = 0.01;
          pt.threshold = 0.05;
        }
        pt.initialized = true;
        pt.initPeaks = [];
        console.log("Pan-Tompkins initialization complete.");
      }

      // 8) Search-back:
      if (pt.initialized && pt.avgRR) {
        const timeSinceLast = pt.sampleCount - (pt.lastBeatSample || 0);
        if (timeSinceLast > Math.floor(1.5 * pt.avgRR)) {
          pt.missedCount++;
          const searchWindowSize = Math.floor(0.5 * pt.avgRR);
          const startIdx = Math.max(pt.intBuf.length - searchWindowSize, 0);
          let maxVal = 0;
          let maxIdx = -1;
          for (let i = startIdx; i < pt.intBuf.length; i++) {
            if (pt.intBuf[i] > maxVal) {
              maxVal = pt.intBuf[i];
              maxIdx = i;
            }
          }
          if (maxVal > 0.5 * pt.signalLevel) {
            const peakTime = pt.sampleCount - (pt.intBuf.length - maxIdx);
            ibi = Math.round((peakTime - (pt.lastBeatSample || 0)) * this._msPerSample);
            if (ibi >= 300 && ibi <= 2000) {
              pt.lastBeatSample = peakTime;
              pt.signalLevel = 0.125 * maxVal + 0.875 * pt.signalLevel;
              pt.threshold = pt.noiseLevel + 0.25 * (pt.signalLevel - pt.noiseLevel);
              pt.rrBuf.push(Math.round(peakTime - (peakTime - ibi / this._msPerSample)));
              if (pt.rrBuf.length > 8) pt.rrBuf.shift();
              pt.avgRR = pt.rrBuf.reduce((a, b) => a + b, 0) / pt.rrBuf.length;
              pt.missedCount = 0;
            }
          }
        }
      }

      pt.sampleCount++;
      return ibi;
    } catch (error) {
      console.error("Error in processSamplePanTompkins:", error);
      return null;
    }
  }

  /**
   * Process one raw ECG sample using the Slope Sum Function (SSF) algorithm.
   * @param {number} rawSample - The raw ECG sample.
   * @returns {number|null} - IBI in ms if a beat is detected; otherwise, null.
   */
  processSampleSSF(rawSample) {
    try {
      const ssf = this._ssf;
      if (ssf.baseBuf.length >= this._BASELINE_WIN) {
        ssf.baseSum -= ssf.baseBuf.shift();
      }
      ssf.baseBuf.push(rawSample);
      ssf.baseSum += rawSample;
      const baseline = ssf.baseSum / ssf.baseBuf.length;
      const hpSignal = rawSample - baseline;
      let diff = 0;
      if (ssf.baseBuf.length > 1) {
        const prev = ssf.baseBuf[ssf.baseBuf.length - 2];
        diff = hpSignal - prev;
      }
      const posSlope = diff > 0 ? diff : 0;
      if (ssf.buf.length >= this._SSF_WINDOW) {
        ssf.sum -= ssf.buf.shift();
      }
      ssf.buf.push(posSlope);
      ssf.sum += posSlope;
      const ssfVal = ssf.sum / ssf.buf.length;
      let ibi = null;
      if (!ssf.initialized) {
        ssf.initVals.push(ssfVal);
      } else {
        if (
          ssf.prevVal < ssf.threshold &&
          ssfVal >= ssf.threshold &&
          (ssf.lastBeatSample === null || ssf.sampleCount - ssf.lastBeatSample > this._REFRAC_PERIOD)
        ) {
          ssf.searching = true;
          ssf.searchCount = 0;
          ssf.rawPeakVal = -Infinity;
          ssf.rawPeakTime = ssf.sampleCount;
        }
        if (ssf.searching) {
          ssf.searchCount++;
          if (hpSignal > ssf.rawPeakVal) {
            ssf.rawPeakVal = hpSignal;
            ssf.rawPeakTime = ssf.sampleCount;
          }
          if (ssf.searchCount >= this._SEARCH_WIN) {
            ssf.searching = false;
            if (ssf.lastBeatSample !== null) {
              ibi = Math.round((ssf.rawPeakTime - ssf.lastBeatSample) * this._msPerSample);
              if (ibi < 300 || ibi > 3000) {
                ibi = null;
              }
            }
            if (ibi !== null || ssf.lastBeatSample === null) {
              ssf.lastBeatSample = ssf.rawPeakTime;
            }
          }
        }
      }
      ssf.prevVal = ssfVal;
      if (!ssf.initialized && ssf.sampleCount >= 2 * this._samplingRate) {
        if (ssf.initVals.length > 0) {
          ssf.initVals.sort((a, b) => a - b);
          const idx = Math.floor(0.95 * ssf.initVals.length);
          const peakSSF = ssf.initVals[idx] || 0;
          ssf.threshold = 0.3 * peakSSF;
          if (ssf.threshold <= 0) ssf.threshold = 0.001;
        }
        ssf.initialized = true;
        ssf.initVals = [];
        console.log("SSF initialization complete.");
      }
      ssf.sampleCount++;
      return ibi;
    } catch (error) {
      console.error("Error in processSampleSSF:", error);
      return null;
    }
  }

  /**
   * By default, we use the improved Pan–Tompkins for BPM.
   */
  processSample(rawSample) {
    const ibi = this.processSampleSSF(rawSample);
    if (ibi !== null) {
      return this.getHeartRate(ibi);
    }
    return null;
  }
}

export { ECGToHR };

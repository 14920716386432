//
// legacyCFFT.js – “Exact” 256-point FFT with optional cutoff/notch, 
//                 matching original DataFFT.cpp approach.
// 
// Usage:
//   const { realOut, imagOut, fftResult, freqArray } = legacyFftCompute(
//       inputArray,    // array of up to 256 IBI or HR values
//       baseFreq,      // e.g. average freq or 1.0 if you want
//       applyCutoffs,  // { cutoffHPF, cutoffLPF, notchFrequency, notchFrequencyMultiples }
//       constantFreq   // e.g. -1 or a positive integer
//   );
// 
//  – output.fftResult is an array of length 128, each entry is the final magnitude.
//  – freqArray[i] is the “index_to_frequency” value for that bin (before we do /60).
//
// Then call switchableHRV.updateFFT(result.fftResult, [2,3,4,5]) 
// exactly as in the old code.
//


////////////////////////////
// 1) Helper “Index_to_frequency” 
////////////////////////////
function Index_to_frequency(baseFreq, nSamples, index) {
  if (index >= nSamples) {
    return 0.0;
  } else if (index <= nSamples / 2) {
    return (index / nSamples) * baseFreq;
  } else {
    return -((nSamples - index) / nSamples) * baseFreq;
  }
}

////////////////////////////
// 2) Basic “is power of two” check 
////////////////////////////
function IsPowerOfTwo(x) {
  if (x < 2) return false;
  return (x & (x - 1)) === 0;
}

////////////////////////////
// 3) Counting bits
////////////////////////////
function NumberOfBitsNeeded(nSamples) {
  let i = 0;
  while ((1 << i) < nSamples) i++;
  return i;
}

////////////////////////////
// 4) Bit-reversal
////////////////////////////
function ReverseBits(index, numBits) {
  let rev = 0;
  for (let i = 0; i < numBits; i++) {
    rev = (rev << 1) | (index & 1);
    index >>= 1;
  }
  return rev;
}

////////////////////////////
// 5) The core Cooley-Tukey “fft_double”
////////////////////////////
function fft_double(p_nSamples, inverseTransform, realIn, imagIn) {
  if (!IsPowerOfTwo(p_nSamples)) {
    throw new Error("fft_double: input size must be a power of two");
  }

  let realOut = new Array(p_nSamples);
  let imagOut = new Array(p_nSamples);
  
  const angleNumerator = inverseTransform ? -2.0 * Math.PI : 2.0 * Math.PI;
  const numBits = NumberOfBitsNeeded(p_nSamples);

  // bit-reverse copy
  for (let i = 0; i < p_nSamples; i++) {
    const j = ReverseBits(i, numBits);
    realOut[j] = realIn[i];
    imagOut[j] = imagIn ? imagIn[i] : 0.0;
  }

  // Cooley-Tukey pass
  let blockEnd = 1;
  for (let blockSize = 2; blockSize <= p_nSamples; blockSize <<= 1) {
    const deltaAngle = angleNumerator / blockSize;
    const sm2 = Math.sin(-2 * deltaAngle);
    const sm1 = Math.sin(-deltaAngle);
    const cm2 = Math.cos(-2 * deltaAngle);
    const cm1 = Math.cos(-deltaAngle);
    const w = 2 * cm1;

    for (let i = 0; i < p_nSamples; i += blockSize) {
      let ar2 = cm2, ar1 = cm1;
      let ai2 = sm2, ai1 = sm1;

      for (let j = i, n = 0; n < blockEnd; j++, n++) {
        const ar0 = w * ar1 - ar2;
        ar2 = ar1;
        ar1 = ar0;

        const ai0 = w * ai1 - ai2;
        ai2 = ai1;
        ai1 = ai0;

        const k = j + blockEnd;
        const tr = ar0 * realOut[k] - ai0 * imagOut[k];
        const ti = ar0 * imagOut[k] + ai0 * realOut[k];

        realOut[k] = realOut[j] - tr;
        imagOut[k] = imagOut[j] - ti;

        realOut[j] += tr;
        imagOut[j] += ti;
      }
    }
    blockEnd = blockSize;
  }

  // If inverse, scale down
  if (inverseTransform) {
    for (let i = 0; i < p_nSamples; i++) {
      realOut[i] /= p_nSamples;
      imagOut[i] /= p_nSamples;
    }
  }

  return { realOut, imagOut };
}

////////////////////////////
// 6) Magnitude helper
////////////////////////////
function magnitude(re, im) {
  return Math.sqrt(re*re + im*im);
}

////////////////////////////
// 7) The main “legacyFftCompute” to replicate DataFFT.cpp
////////////////////////////
export function legacyFftCompute(inputArray, baseFreq, applyCutoffs, constantFreq) {
  // We want exactly 256 samples (like old code).
  const N = 256;

  // 1) If inputArray >= 256, take the last 256. If <256, pad from the front.
  let arr;
  if (inputArray.length >= N) {
    arr = inputArray.slice(inputArray.length - N);
  } else {
    const needed = N - inputArray.length;
    const firstVal = inputArray.length > 0 ? inputArray[0] : 0;
    arr = new Array(needed).fill(firstVal).concat(inputArray);
  }

  // 2) Prepare realIn/imagIn for fft
  const realIn = arr.slice();         // copy
  const imagIn = new Array(N).fill(0);

  // 3) Perform the FFT (non-inverse)
  const { realOut, imagOut } = fft_double(N, false, realIn, imagIn);

  // 4) Build frequency/magnitude arrays for “half” the spectrum => 128 bins
  const halfN = N / 2;
  let fftResult = new Array(halfN);
  let freqArray = new Array(halfN);

  for (let i = 0; i < halfN; i++) {
    // Optionally apply cutoff / notch
    let re = realOut[i];
    let im = imagOut[i];

    // Replicate the old “if we have cutoffs” logic
    if (applyCutoffs) {
      // replicate old “thisFrequency = Index_to_frequency(...) / 60.0 if constantFreq<=0”
      // or “Index_to_frequency(constantFreq, N, i)” if constantFreq>0
      let thisFrequency;
      if (constantFreq <= 0) {
        // DataFFT used average BPM as baseFreq in some code; so “/60.0” was typical
        thisFrequency = Index_to_frequency(baseFreq, N, i) / 60.0;
      } else {
        thisFrequency = Index_to_frequency(constantFreq, N, i);
      }

      // HPF/LPF check
      if ((applyCutoffs.cutoffHPF && thisFrequency < applyCutoffs.cutoffHPF) ||
          (applyCutoffs.cutoffLPF && thisFrequency > applyCutoffs.cutoffLPF)) {
        re = 0;
        im = 0;
      }

      // Notch freq check
      if (applyCutoffs.notchFrequency && constantFreq > 0) {
        const f1 = Math.floor(thisFrequency);
        const f2 = Math.floor(thisFrequency + 0.5);
        let notchHit = false;
        if (f1 === applyCutoffs.notchFrequency || f2 === applyCutoffs.notchFrequency) {
          notchHit = true;
        } else if (applyCutoffs.notchFrequencyMultiples) {
          if ((f1 % applyCutoffs.notchFrequency) === 0 ||
              (f2 % applyCutoffs.notchFrequency) === 0) {
            notchHit = true;
          }
        }
        if (notchHit) {
          // scale out / flatten it like original code (but we’ll simply zero for brevity)
          re = 0; 
          im = 0;
        }
      }
    }

    // Overwrite realOut/imagOut if we zeroed them
    realOut[i] = re;
    imagOut[i] = im;

    // Then compute final magnitude
    fftResult[i] = magnitude(re, im);
    freqArray[i] = Index_to_frequency(baseFreq, N, i);  // for reference
  }

  // Return the same structure the old code effectively had:
  return {
    realOut,
    imagOut,
    fftResult,  // length 128
    freqArray   // length 128
  };
}

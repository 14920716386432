import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import HRVToolTips from "./HRVToolTips";

/** 
 * A small helper to delay rendering children by `delay` ms. 
 * Until that delay finishes, it shows a simple "Loading..." placeholder. 
 */
function LazyLoad({ delay, children }) {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShouldRender(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  if (!shouldRender) {
    return <div style={{ color: "#999", fontStyle: "italic" }}>Loading chart...</div>;
  }
  return children;
}

function HeartRateDisplay({ analysis }) {
  if (!analysis) {
    return <div>No Heart Rate Analysis available.</div>;
  }

  // Basic numeric stats:
  const latestBPM = analysis.getLatestBPM();
  const avgBPM = analysis.getAverageBPM();
  const rmssd = analysis.getRMSSD().toFixed(1);
  const sdnn = analysis.getSDNN().toFixed(1);
  const pnn50 = analysis.getPNN50().toFixed(1);
  const apEn = analysis.getApproxEntropy().toFixed(2);
  const sampEn = analysis.getSampleEntropy().toFixed(2);
  const dfaAlpha1 = analysis.getDFAalpha1().toFixed(2);
  const accelCap = analysis.getAccelerationCapacity().toFixed(1);
  const decelCap = analysis.getDecelerationCapacity().toFixed(1);
  const coherence = analysis.getCoherenceScore().toFixed(2);
  const rsa = analysis.getRSAAmplitude().toFixed(1);
  const isStressed = analysis.isStressed();
  const stressColor = isStressed ? "red" : "green";

  const relaxationScore = analysis.getRelaxationScore
    ? analysis.getRelaxationScore().toFixed(2)
    : "N/A";
  const excitementScore = analysis.getExcitementScore
      ? analysis.getExcitementScore().toFixed(2)
      : "N/A";

  const lastEventInfo = analysis.getLastEventInfo ? analysis.getLastEventInfo() : null;
  const exciementLastEventInfo = analysis.getExcitementLastEventInfo ? analysis.getExcitementLastEventInfo() : null;

  // History data for charts
  const history = analysis.getBPMHistory();
  let hrChartData = [];
  if (history.length > 0) {
    const tStart = history[0].time;
    hrChartData = history.map((item) => {
      const tSec = (item.time - tStart) / 1000.0;
      return {
        t: +tSec.toFixed(1),
        bpm: Math.round(item.bpm),
      };
    });
  }

  const ecgData = analysis.getECGData();

  // FFT data
  const fftInterpolated = analysis.getFrequencyDataInterpolated();
  const interpolatedFFTData = fftInterpolated.map((pt) => ({
    frequency: +pt.frequency.toFixed(3),
    power: +pt.power.toFixed(2),
  }));

  const fftRaw = analysis.getFrequencyDataRaw();
  const rawFFTData = fftRaw.map((pt) => ({
    frequency: +pt.frequency.toFixed(3),
    power: +pt.power.toFixed(2),
  }));

  const fftLegacy = analysis.getLegacyFFT ? analysis.getLegacyFFT() : [];
  const legacyFFTData = fftLegacy.map((pt) => ({
    frequency: +pt.frequency.toFixed(3),
    power: +pt.power.toFixed(2),
  }));

  // Generic helper to convert numeric history arrays into chartable data
  const prepareHistoryData = (historyArray) => {
    return historyArray.map((value, index) => ({
      index,
      value: parseFloat(value.toFixed(2)),
    }));
  };

  const rmssdHistoryData = prepareHistoryData(analysis.getRMSSDHistory());
  const sdnnHistoryData = prepareHistoryData(analysis.getSDNNHistory());
  const pnn50HistoryData = prepareHistoryData(analysis.getPNN50History());
  const apenHistoryData = prepareHistoryData(analysis.getApproxEntropyHistory());
  const sampenHistoryData = prepareHistoryData(analysis.getSampleEntropyHistory());
  const dfaHistoryData = prepareHistoryData(analysis.getDFAalpha1History());
  const accelHistoryData = prepareHistoryData(analysis.getAccelerationCapacityHistory());
  const decelHistoryData = prepareHistoryData(analysis.getDecelerationCapacityHistory());
  const coherenceHistoryData = prepareHistoryData(analysis.getCoherenceHistory());
  const rsaHistoryData = prepareHistoryData(analysis.getRSAAmplitudeHistory());
  const lfHfHistoryData = prepareHistoryData(analysis.getLFHFRatioHistory());
  const lfOtherHistoryData = prepareHistoryData(analysis.getLFOtherHistory());
  const relaxationHistoryData = prepareHistoryData(analysis.getRelaxationScoreHistory());
  const excitementHistoryData = prepareHistoryData(analysis.getExcitementScoreHistory());

  return (
    <div style={{ border: "1px solid #ccc", padding: "1em", marginTop: "1em" }}>
      <h3>Heart Rate & HRV Metrics</h3>
      <div style={{ marginBottom: "1em" }}>
        <p>
          Latest BPM: <strong>{latestBPM}</strong>
        </p>
        <p>
          Avg BPM (simple): <strong>{avgBPM}</strong>
        </p>
        <p>
          Normalized Avg BPM: <strong>{analysis.getNormalizedAvgBPM()}</strong>
        </p>
        <p>
          LF/HF: <strong>{analysis.getLFHFRatio().toFixed(2)}</strong>
        </p>
        <p>
          LF/Other: <strong>{analysis.getLFOtherRatio().toFixed(2)}</strong>
        </p>
        <p style={{ color: stressColor }}>
          Stress: <strong>{isStressed ? "Stressed" : "Calm"}</strong>
        </p>
        <p>
          Relaxation Score: <strong>{relaxationScore}</strong>
        </p>
        <p>
          Excitement Score: <strong>{excitementScore}</strong>
        </p>

        {/* 1) ECG Waveform - Delay it slightly (e.g. 200ms) */}
        <h5>ECG Waveform</h5>
        <div style={{ width: "100%", height: 300 }}>
          <LazyLoad delay={500}>
            {ecgData.length > 0 ? (
              <ResponsiveContainer>
                <LineChart data={ecgData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="t"
                    label={{ value: "Time (s)", position: "insideBottomRight", offset: 0 }}
                  />
                  <YAxis
                    label={{ value: "ECG (a.u.)", angle: -90, position: "insideLeft" }}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="ecg"
                    stroke="#FF00FF"
                    dot={false}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <p>No ECG data available.</p>
            )}
          </LazyLoad>
        </div>

        {/* 2) BPM History & Relaxation Score - loaded a bit later */}
        <div
          style={{
            marginTop: "1em",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <div style={{ width: "100%", height: 300 }}>
            <LazyLoad delay={1000}>
              <ResponsiveContainer>
                <LineChart data={hrChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="t"
                    label={{
                      value: "Time (s)",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                  />
                  <YAxis
                    label={{
                      value: "BPM",
                      angle: -90,
                      position: "insideLeft",
                    }}
                    domain={["auto", "auto"]}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="bpm"
                    stroke="#FF0000"
                    dot={false}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </LazyLoad>
          </div>

          <div style={{ width: "100%", height: 300 }}>
            <LazyLoad delay={1500}>
              <ResponsiveContainer>
                <LineChart data={relaxationHistoryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="index"
                    label={{
                      value: "Measurement",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Relaxation Score",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#0000FF"
                    dot={false}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </LazyLoad>
          </div>
        
          <div style={{ width: "100%", height: 300 }}>
            <LazyLoad delay={1750}>
              <ResponsiveContainer>
                <LineChart data={excitementHistoryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="index"
                    label={{
                      value: "Measurement",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Excitement Score",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#0000FF"
                    dot={false}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </LazyLoad>
          </div>
        </div>

        {lastEventInfo && lastEventInfo.event !== 0 && (
          <div
            style={{
              marginTop: "1em",
              padding: "0.5em",
              border: "1px solid #ccc",
            }}
          >
            <p>
              <strong>Detected Relaxation State Change:</strong> {lastEventInfo.event} -{" "}
              {lastEventInfo.debugInfo}
            </p>
            <p>
              <em>{lastEventInfo.meditationMsg}</em>
            </p>
          </div>
        )}

        {exciementLastEventInfo && exciementLastEventInfo.event !== 0 && (
          <div
            style={{
              marginTop: "1em",
              padding: "0.5em",
              border: "1px solid #ccc",
            }}
          >
            <p>
              <strong>Detected Excitement State Change:</strong> {exciementLastEventInfo.event} -{" "}
              {exciementLastEventInfo.debugInfo}
            </p>
            <p>
              <em>{exciementLastEventInfo.excitementMsg}</em>
            </p>
          </div>
        )}
      </div>

      <hr />

      {/* Time-Domain HRV (basic stats) - no heavy charting, just text */}
      <div style={{ marginBottom: "1em" }}>
        <h4>Time-Domain HRV</h4>
        <p>
          SDNN: <strong>{sdnn} ms</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.sdnn})
          </span>
        </p>
        <p>
          RMSSD: <strong>{rmssd} ms</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.rmssd})
          </span>
        </p>
        <p>
          pNN50: <strong>{pnn50}%</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.pnn50})
          </span>
        </p>
      </div>

      {/* Nonlinear / Advanced */}
      <div style={{ marginBottom: "1em" }}>
        <h4>Nonlinear / Advanced</h4>
        <p>
          Approx. Entropy (ApEn): <strong>{apEn}</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.apen})
          </span>
        </p>
        <p>
          Sample Entropy (SampEn): <strong>{sampEn}</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.sampen})
          </span>
        </p>
        <p>
          DFA α1: <strong>{dfaAlpha1}</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.dfa})
          </span>
        </p>
      </div>

      {/* Acceleration / Deceleration */}
      <div style={{ marginBottom: "1em" }}>
        <h4>Acceleration / Deceleration</h4>
        <p>
          Acceleration Capacity (AC): <strong>{accelCap} ms</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.ac})
          </span>
        </p>
        <p>
          Deceleration Capacity (DC): <strong>{decelCap} ms</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.dc})
          </span>
        </p>
      </div>

      {/* Coherence / RSA */}
      <div style={{ marginBottom: "1em" }}>
        <h4>Coherence & RSA</h4>
        <p>
          Coherence Score: <strong>{coherence}</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.coherence})
          </span>
        </p>
        <p>
          RSA Amplitude: <strong>{rsa} ms</strong>{" "}
          <span style={{ fontSize: "0.8em", color: "#555", marginLeft: "0.5em" }}>
            ({HRVToolTips.rsa})
          </span>
        </p>
      </div>

      <hr />

      {/* FFT (Interpolated) as BAR chart */}
      <h4>FFT (Interpolated)</h4>
      <LazyLoad delay={2000}>
        {interpolatedFFTData.length > 1 ? (
          <div style={{ width: "100%", height: 250 }}>
            <ResponsiveContainer>
              <BarChart data={interpolatedFFTData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="frequency"
                  label={{
                    value: "Freq (Hz) [0-0.5]",
                    position: "insideBottomRight",
                    offset: 0,
                  }}
                />
                <YAxis
                  label={{
                    value: "Power",
                    angle: -90,
                    position: "insideLeft",
                  }}
                  domain={["auto", "auto"]}
                />
                <Tooltip />
                <Bar dataKey="power" fill="#0000FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <p>Not enough data for interpolated FFT.</p>
        )}
      </LazyLoad>

      {/* FFT (Raw) as BAR chart */}
      <h4>FFT (Raw - 1 sample/beat)</h4>
      <LazyLoad delay={2500}>
        {rawFFTData.length > 1 ? (
          <div style={{ width: "100%", height: 250 }}>
            <ResponsiveContainer>
              <BarChart data={rawFFTData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="frequency"
                  label={{
                    value: "Freq (Hz) [0-0.5]",
                    position: "insideBottomRight",
                    offset: 0,
                  }}
                />
                <YAxis
                  label={{
                    value: "Power",
                    angle: -90,
                    position: "insideLeft",
                  }}
                  domain={["auto", "auto"]}
                />
                <Tooltip />
                <Bar dataKey="power" fill="#008800" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <p>Not enough data for raw FFT.</p>
        )}
      </LazyLoad>

      {/* FFT (Legacy) as BAR chart */}
      <h4>FFT (Legacy)</h4>
      <LazyLoad delay={3000}>
        {legacyFFTData.length > 1 ? (
          <div style={{ width: "100%", height: 250 }}>
            <ResponsiveContainer>
              <BarChart data={legacyFFTData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="frequency"
                  label={{
                    value: "Freq (Hz) [0-0.5]",
                    position: "insideBottomRight",
                    offset: 0,
                  }}
                />
                <YAxis
                  label={{
                    value: "Power",
                    angle: -90,
                    position: "insideLeft",
                  }}
                  domain={["auto", "auto"]}
                />
                <Tooltip />
                <Bar dataKey="power" fill="#AA00AA" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <p>Not enough data for legacy FFT.</p>
        )}
      </LazyLoad>

      <hr />

      {/* Historical HRV Metrics Line Charts */}
      <h4>Historical HRV Metrics</h4>

      {/* Time-Domain HRV History */}
      <div style={{ marginBottom: "1em" }}>
        <h5>Time-Domain HRV History</h5>
        {/* We'll do a single LazyLoad to wrap the entire block of small charts */}
        <LazyLoad delay={3500}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            {/* BPM History */}
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <ResponsiveContainer>
                <LineChart data={hrChartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="t"
                    label={{
                      value: "Time (s)",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                  />
                  <YAxis
                    label={{
                      value: "BPM",
                      angle: -90,
                      position: "insideLeft",
                    }}
                    domain={["auto", "auto"]}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="bpm"
                    stroke="#FF0000"
                    dot={false}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {/* Relaxation Score */}
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <ResponsiveContainer>
                <LineChart data={relaxationHistoryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="index"
                    label={{
                      value: "Measurement",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Relaxation Score",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#0000FF"
                    dot={false}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {/* Excitement Score */}
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <ResponsiveContainer>
                <LineChart data={excitementHistoryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="index"
                    label={{
                      value: "Measurement",
                      position: "insideBottomRight",
                      offset: 0,
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Excitement Score",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#0000FF"
                    dot={false}
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {/* SDNN */}
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>SDNN (ms)</h6>
              {sdnnHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={sdnnHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "SDNN (ms)",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#007AFF"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No SDNN history available.</p>
              )}
            </div>

            {/* RMSSD */}
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>RMSSD (ms)</h6>
              {rmssdHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={rmssdHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "RMSSD (ms)",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#FF9500"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No RMSSD history available.</p>
              )}
            </div>

            {/* pNN50 */}
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>pNN50 (%)</h6>
              {pnn50HistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={pnn50HistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "pNN50 (%)",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#34C759"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No pNN50 history available.</p>
              )}
            </div>

            {/* DFA α1 */}
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>DFA α1</h6>
              {dfaHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={dfaHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "DFA α1",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#FF2D55"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No DFA history available.</p>
              )}
            </div>
          </div>
        </LazyLoad>
      </div>

      {/* LF/HF and LF/Other */}
      <div style={{ marginBottom: "1em" }}>
        <h5>LF/HF and LF/Other History</h5>
        <LazyLoad delay={4000}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>LF/HF Ratio</h6>
              {lfHfHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={lfHfHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "LF/HF",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#B22222"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No LF/HF history available.</p>
              )}
            </div>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>LF/Other Ratio</h6>
              {lfOtherHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={lfOtherHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "LF/Other",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#8B008B"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No LF/Other history available.</p>
              )}
            </div>
          </div>
        </LazyLoad>
      </div>

      {/* Nonlinear HRV History */}
      <div style={{ marginBottom: "1em" }}>
        <h5>Nonlinear HRV History</h5>
        <LazyLoad delay={4500}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>ApEn</h6>
              {apenHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={apenHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "ApEn",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#5856D6"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No ApEn history available.</p>
              )}
            </div>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>SampEn</h6>
              {sampenHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={sampenHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "SampEn",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#AF52DE"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No SampEn history available.</p>
              )}
            </div>
          </div>
        </LazyLoad>
      </div>

      {/* Acceleration/Deceleration & Coherence/RSA History */}
      <div style={{ marginBottom: "1em" }}>
        <h5>Acceleration/Deceleration & Coherence/RSA History</h5>
        <LazyLoad delay={5000}>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "1em" }}>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>Acceleration Capacity (AC)</h6>
              {accelHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={accelHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "AC (ms)",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#FF3B30"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No AC history available.</p>
              )}
            </div>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>Deceleration Capacity (DC)</h6>
              {decelHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={decelHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "DC (ms)",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#4CD964"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No DC history available.</p>
              )}
            </div>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>Coherence Score</h6>
              {coherenceHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={coherenceHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "Coherence",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#007AFF"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No Coherence history available.</p>
              )}
            </div>
            <div style={{ flex: "1 1 300px", height: 200 }}>
              <h6>RSA Amplitude</h6>
              {rsaHistoryData.length > 1 ? (
                <ResponsiveContainer>
                  <LineChart data={rsaHistoryData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="index"
                      label={{
                        value: "Measurement",
                        position: "insideBottomRight",
                        offset: 0,
                      }}
                    />
                    <YAxis
                      label={{
                        value: "RSA (ms)",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />
                    <Tooltip />
                    <Line
                      type="monotone"
                      dataKey="value"
                      stroke="#5856D6"
                      dot={false}
                      isAnimationActive={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <p>No RSA history available.</p>
              )}
            </div>
          </div>
        </LazyLoad>
      </div>

      <hr />

      {/* Recent BPM Readings */}
      <h4>Recent BPM Readings</h4>
      <div style={{ maxHeight: 100, overflowY: "auto" }}>
        <ul>
          {history.slice(-5).map((item, idx) => (
            <li key={idx}>
              {new Date(item.time).toLocaleTimeString()} — {Math.round(item.bpm)} BPM
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default HeartRateDisplay;

// ==================== HeartRateAnalysis.js ====================
import axios from "axios";
import { fft, util as fftUtil } from 'fft-js';
import { legacyFftCompute } from './LegacyCFFT';

const DEBUG = false;

function logEvent(msg) {
  if (DEBUG) {
    console.log(`[${new Date().toISOString()}] ${msg}`);
  }
}

class HeartRateAnalysis {
  constructor(sessionId, sampleRate = 1, bufferDuration = 300) {
    this.sampleRate = sampleRate;
    this.bufferSize = Math.floor(sampleRate * bufferDuration);
    this.dataBuffer = [];

    // FFT-related arrays
    this.freqSpectrumInterpolated = [];
    this.legacyFFT = [];
    this.legacyFreqBins = [];

    // Time & data buffers
    this.timestamps = [];
    this.intervalsSec = [];
    this.bpmBuffer = [];

    // Rolling window length in ms
    this.windowMs = 64000;

    this.normalizedAvgBPM = 0;

    // Frequency analysis sampling rate
    this.samplingRate = 4;
    this.freqSpectrumInterpolated = [];
    this.freqSpectrumRaw = [];

    // LF/HF ratio & stress detection
    this.lfHfRatio = 0;
    this.lfOtherRatio = 0;
    this.stressThreshold = 2.0;

    // Time-domain HRV
    this.rmssd = 0;
    this.sdnn = 0;
    this.pnn50 = 0;

    // Nonlinear metrics
    this.approxEntropy = 0;
    this.sampleEntropy = 0;
    this.dfaAlpha1 = 0;

    // Coherence & RSA
    this.coherence = 0;
    this.rsaAmplitude = 0;

    // Acceleration/Deceleration
    this.accelerationCapacity = 0;
    this.decelerationCapacity = 0;

    // Histories for metrics
    this.rmssdHistory = [];
    this.sdnnHistory = [];
    this.pnn50History = [];
    this.approxEntropyHistory = [];
    this.sampleEntropyHistory = [];
    this.dfaAlpha1History = [];
    this.accelerationCapacityHistory = [];
    this.decelerationCapacityHistory = [];
    this.coherenceHistory = [];
    this.rsaAmplitudeHistory = [];
    this.lfHfHistory = [];
    this.lfOtherHistory = [];

    // For dynamic (session-based) min/max tracking
    this.metricRange = {
      // Calm metrics
      sdnn: { min: Infinity, max: -Infinity },
      rmssd: { min: Infinity, max: -Infinity },
      pnn50: { min: Infinity, max: -Infinity },
      decelerationCapacity: { min: Infinity, max: -Infinity },
      coherence: { min: Infinity, max: -Infinity },
      rsaAmplitude: { min: Infinity, max: -Infinity },
      dfaAlpha1: { min: Infinity, max: -Infinity },
      // Stress metrics
      approxEntropy: { min: Infinity, max: -Infinity },
      sampleEntropy: { min: Infinity, max: -Infinity },
      accelerationCapacity: { min: Infinity, max: -Infinity },
      // Also track BPM for normalization
      bpmRange: { min: Infinity, max: -Infinity }
    };

    // Our final relaxation–stress composite
    this.relaxationScore = 0;
    this.relaxationScoreHistory = [];
    // Track a personal “best” relaxation so far (lower = better if 0..1 scale)
    this.bestRelaxationScoreSoFar = Infinity;

    // ============ NEW: Excitement-based tracking ============
    this.excitementScore = 0;
    this.excitementScoreHistory = [];
    this.bestExcitementScoreSoFar = -Infinity;

    // For advanced event detection (Relaxation):
    this.eventHrHistory = [];
    this.lastEventTime = 0;
    this.lastEventType = 0;
    this.minEventInterval = 90000;   // 60 seconds (cooldown)
    this.maxEventInterval = 1200000;  // 20 minutes (forced check) / never force check
    this.recentDeltaBuffer = [];
    this.sustainedBufferSize = 3; // require 3 consecutive measures

    // Weighted activity approach
    this.metricHistories = {
      sdnn: [],
      rmssd: [],
      pnn50: [],
      decelerationCapacity: [],
      coherence: [],
      rsaAmplitude: [],
      dfaAlpha1: [],
      approxEntropy: [],
      sampleEntropy: [],
      accelerationCapacity: [],
      // Additional: raw BPM activity
      bpm: []
    };
    this.metricWeights = {
      sdnn: 1,
      rmssd: 1,
      pnn50: 1,
      decelerationCapacity: 1,
      coherence: 1,
      rsaAmplitude: 1,
      dfaAlpha1: 1,
      approxEntropy: 1,
      sampleEntropy: 1,
      accelerationCapacity: 1,
      bpm: 1
    };

    // Windows for short, mid, long
    this.shortWindowMs = 15000; // 15s
    this.midWindowMs = 30000;   // 30s
    this.longWindowMs = 60000;  // 60s

    this.avgRelaxationDelta = 0;

    // Keep last relaxation event info
    this.lastEventInfo = { event: 0, debugInfo: '', meditationMsg: '', TTSMsg: '' };

    // ============ NEW: Excitement event detection ============
    this.lastExcitementEventTime = 0;
    this.lastExcitementEventType = 0;
    this.excitementDeltaBuffer = [];
    this.excitementSustainedBufferSize = 3;
    this.excitementLastEventInfo = { event: 0, debugInfo: '', excitementMsg: '' };
    this.excitementMinEventInterval = 60000;
    this.excitementMaxEventInterval = 120000;

    // For dynamic text feedback
    this.currentSpokenText = "";
    this.currentVideoPlaying = "";
    this.currentPrompt = "";

    // Optionally store raw ECG
    this.ecgDataBuffer = [];

    // Session ID & timestamp
    this.sessionId = sessionId;
    this.sessionTimestamp = new Date().toISOString();

    this.lastNonPrioritySpokenText = "";
    this.meditationComplete = false;

    // For dynamic text feedback – keep full transcript and timeline data
    this.currentSpokenText = "";
    this.fullSpokenText = "";          // Aggregated transcript
    this.lastSpokenText = "";          // The most recent text that was appended
    this.lastSpokenTextStartTime = Date.now(); // Timestamp when the current text became active
    this.spokenTextHistory = [];       // Array of segments with metrics

    // NEW: Buffer to track relaxation scores with timestamps
    this.relaxationScoreBuffer = [];
    this.coherenceExternalBuffer = [];

    this.lastEventDirection = 0;

  }
  
  addCoherenceExternal(coherenceVal) {
    const now = Date.now();
    this.coherenceExternalBuffer.push({ time: now, val: coherenceVal });

    // Optionally, prune older data just like you prune BPM entries
    const cutoff = now - this.windowMs; 
    while (this.coherenceExternalBuffer.length && this.coherenceExternalBuffer[0].time < cutoff) {
      this.coherenceExternalBuffer.shift();
    }
  }

  // ====================================================
  // ======= ECG Handling (unchanged) ===================
  // ====================================================
  addECGSample(ecgSample) {
    const now = Date.now();
    this.ecgDataBuffer.push({ time: now, ecg: ecgSample });
    if (this.ecgDataBuffer.length > 5000) {
      this.ecgDataBuffer.shift();
    }
  }
  getECGData() {
    if (this.ecgDataBuffer.length === 0) return [];
    const t0 = this.ecgDataBuffer[0].time;
    return this.ecgDataBuffer.map((sample) => ({
      t: ((sample.time - t0) / 1000).toFixed(2),
      ecg: sample.ecg,
    }));
  }

  setLastNonPrioritySpokenText(text) {
    this.lastNonPrioritySpokenText = text;
    //logEvent("HeartRateAnalysis updated with:", text);
  }

  //set meditationComplete
  setMeditationComplete(value) {
    this.meditationComplete = value;
  }

  resetSpokenTextHistory() {
    this.currentSpokenText = "";
    this.fullSpokenText = "";
    this.lastSpokenText = "";
    this.lastSpokenTextStartTime = Date.now();
    this.spokenTextHistory = [];
    // Optionally reset the last non-priority text if needed
    this.lastNonPrioritySpokenText = "";
  }

  getReadableSpokenTextHistory() {
    let stats = '';
    let usedCoherence = false;
    if (Array.isArray(this.spokenTextHistory) && this.spokenTextHistory.length > 0) {
      stats += `\nDuring the meditation, the user had the following heart rate and relaxation scores. This information can help the user to repeat very relaxing segments (high relaxation), or focus on segments where there was higher stress (lower relaxation, which could in some cases indicate excitement, but is generally stress):\n`;
      this.spokenTextHistory.forEach(segment => {
      //stats += `From ${new Date(segment.startTime).toLocaleTimeString()} to ${new Date(segment.endTime).toLocaleTimeString()}, when "${segment.text}" was spoken, the average heart rate was ${segment.avgBPM} and the average stress score (0→100%) was ${segment.avgRelaxation}.\n`;
      if (!segment || segment.avgCoherenceExternal < 0) {
        logEvent("segment didn't have an averageCoherence!");
        stats += `When "${segment.text}" was spoken, the average heart rate was ${Math.round(segment.avgBPM)} bpm and the average relaxation score (0→100%) was ${100 - segment.avgRelaxation}%.\n`;
      } else {
        usedCoherence = true;
        stats += `When "${segment.text}" was spoken, the average heart rate was ${Math.round(segment.avgBPM)} bpm, the average Resonance was ${segment.avgCoherenceExternal}%, and the average relaxation score (0→100%) was ${100 - segment.avgRelaxation}%.\n`;
      }
     });
     if (usedCoherence) {
        stats += `\nThe relaxation score is calculated using Resonance, average heart rate, and other heart rate based stress indicators.`
     }
    }
    return stats;
  }


  // ====================================================
  // ======= App Text/Video/Prompt Integration ==========
  // ====================================================
  setSpokenText(text) {
    if (!text) {
      logEvent("setSpokenText: received falsy text, ignoring.");
      return;
    }
    //logEvent("setSpokenText in HeartRateAnalysis: " + text);
    const now = Date.now();
    // Only update if the new text differs from the last appended text
    if (this.lastSpokenText !== text) {
      // If there is an existing segment, compute averages and save it
      if (this.lastSpokenText) {
        // Compute average BPM over the interval using bpmBuffer
        const relevantBPM = this.bpmBuffer.filter(entry =>
          entry.time >= this.lastSpokenTextStartTime && entry.time <= now
        );
        const avgBPM = relevantBPM.length > 0
          ? relevantBPM.reduce((sum, entry) => sum + entry.bpm, 0) / relevantBPM.length
          : this.getAverageBPM();

        // Compute average relaxation score over the interval using relaxationScoreBuffer
        const relevantRelaxation = this.relaxationScoreBuffer.filter(entry =>
          entry.time >= this.lastSpokenTextStartTime && entry.time <= now
        );
        let avgRelaxation = relevantRelaxation.length > 0
          ? relevantRelaxation.reduce((sum, entry) => sum + entry.score, 0) / relevantRelaxation.length
          : this.relaxationScore;

        //change avgRelaxation to a %
        avgRelaxation = Math.round(avgRelaxation * 100);

        // NEW for coherence → average the values in coherenceBuffer for this segment
        const relevantCoherenceExternal = this.coherenceExternalBuffer.filter(entry =>
          entry.time >= this.lastSpokenTextStartTime && entry.time <= now
        );
        let avgCoherenceExternal = relevantCoherenceExternal.length > 0
          ? (relevantCoherenceExternal.reduce((sum, e) => sum + e.val, 0) / relevantCoherenceExternal.length)
          : -1;  // fallback if no data
          
        let avgCoherence = Math.round(avgCoherenceExternal * 100);

        // Build the segment object
        const segment = {
          text: this.lastSpokenText,
          startTime: this.lastSpokenTextStartTime,
          endTime: now,
          avgBPM,
          avgRelaxation,
          avgCoherenceExternal: avgCoherence  // store the exact field name
        };

        // Push into spokenTextHistory
        this.spokenTextHistory.push(segment);

        // ============ SAVE ROW TO CSV VIA NEW ROUTE ============
        // Only do this if we have a valid session
        if (this.sessionId && this.sessionTimestamp) {
          const payload = {
            sessionId: this.sessionId,
            sessionTimestamp: this.sessionTimestamp,
            startTime: segment.startTime,
            endTime: segment.endTime,
            text: segment.text,
            avgBPM: segment.avgBPM,
            avgRelaxation: segment.avgRelaxation,
            coherenceExternal: segment.avgCoherenceExternal
          };

          // Use fetch or axios:
          fetch('/api/saveSpokenTextAverages', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
          })
            .then((res) => res.json())
            .then((data) => logEvent("Spoken text row saved: " + data))
            .catch((err) => console.error("Error saving spoken text row:", err));
        }

        // Append a double newline before adding the new text to the full transcript
        this.fullSpokenText += "\n\n" + text;
      } else {
        // First spoken text: initialize the full transcript
        this.fullSpokenText = text;
      }
      // Update last spoken text and its start time for the new segment
      this.lastSpokenText = text;
      this.lastSpokenTextStartTime = now;
    }
    // Always update the current spoken text (even if unchanged)
    this.currentSpokenText = text;
  }

  setVideoPlaying(fileName) {
    this.currentVideoPlaying = fileName;
  }
  setCurrentPrompt(prompt) {
    this.currentPrompt = prompt;
  }

  // ====================================================
  // ======= Weighted Activity Levels ===================
  // ====================================================
  computeMetricActivityLevels() {
    const now = Date.now();
    Object.keys(this.metricHistories).forEach(metric => {
      const cutoff = now - 30000;
      const recentPoints = this.metricHistories[metric].filter(pt => pt.t >= cutoff);
      if (recentPoints.length < 5) return;
      const values = recentPoints.map(pt => pt.v);
      const stdev = this.sd(values);
      const baselineRange = (this.metricRange[metric]?.max - this.metricRange[metric]?.min) || 0;
      let activityRatio = 0;
      if (baselineRange >= 1e-6) {
        activityRatio = stdev / baselineRange;
      }
      let newWeight = this.metricWeights[metric];
      if (activityRatio > 0.2) {
        newWeight = Math.min(newWeight + 0.1, 3.0);
      } else if (activityRatio < 0.05) {
        newWeight = Math.max(newWeight - 0.05, 0.0);
      }
      this.metricWeights[metric] = newWeight;
    });
  }

  // ====================================================
  // ======= BPM / IBI Additions ========================
  // ====================================================
  addBPM(bpm) {
    if (!bpm || bpm < 1) return;
    const ibiMs = 60000 / bpm;
    this.addIBI(ibiMs);
  }
  addIBI(ibiMs) {
    const now = Date.now();
    const ibiSec = ibiMs / 1000;
    const bpm = 60 / ibiSec;

    // Append new data
    this.timestamps.push(now);
    this.intervalsSec.push(ibiSec);
    this.bpmBuffer.push({ time: now, bpm });

    // Keep event history short for state detection
    this.eventHrHistory.push({ time: now, bpm });
    const eventCutoff = now - 120000;
    while (this.eventHrHistory.length && this.eventHrHistory[0].time < eventCutoff) {
      this.eventHrHistory.shift();
    }

    // Remove old data from rolling window
    const cutoff = now - this.windowMs;
    while (this.timestamps.length && this.timestamps[0] < cutoff) {
      this.timestamps.shift();
      this.intervalsSec.shift();
      this.bpmBuffer.shift();
    }

    // Compute average BPM and update normalizedAvgBPM
    let avgBPM = 0;
    if (this.intervalsSec.length > 0) {
      const sumIBI = this.intervalsSec.reduce((sum, v) => sum + v, 0);
      const meanIBI = sumIBI / this.intervalsSec.length;
      avgBPM = 60 / meanIBI;
    }
    if (this.normalizedAvgBPM === 0) {
      this.normalizedAvgBPM = avgBPM;
    } else {
      if (avgBPM > this.normalizedAvgBPM + 2) {
        this.normalizedAvgBPM += 2;
      } else if (avgBPM < this.normalizedAvgBPM - 2) {
        this.normalizedAvgBPM -= 2;
      } else {
        this.normalizedAvgBPM = avgBPM;
      }
    }

    // Recompute analysis metrics
    this.computeFrequencySpectrumInterpolated();
    this.computeFrequencySpectrumRaw();
    this.evaluateStress();
    this.computeLegacyFFT();
    this.computeTimeDomainHRV();

    // Track LF/HF ratios and histories
    this.lfHfHistory.push(this.lfHfRatio);
    if (this.lfHfHistory.length > 50) this.lfHfHistory.shift();
    this.lfOtherHistory.push(this.lfOtherRatio);
    if (this.lfOtherHistory.length > 50) this.lfOtherHistory.shift();

    // Time-domain HRV histories
    this.rmssdHistory.push(this.rmssd);
    if (this.rmssdHistory.length > 50) this.rmssdHistory.shift();
    this.sdnnHistory.push(this.sdnn);
    if (this.sdnnHistory.length > 50) this.sdnnHistory.shift();
    this.pnn50History.push(this.pnn50);
    if (this.pnn50History.length > 50) this.pnn50History.shift();

    // Nonlinear metrics
    this.computeNonlinearMetrics();
    this.approxEntropyHistory.push(this.approxEntropy);
    if (this.approxEntropyHistory.length > 50) this.approxEntropyHistory.shift();
    this.sampleEntropyHistory.push(this.sampleEntropy);
    if (this.sampleEntropyHistory.length > 50) this.sampleEntropyHistory.shift();
    this.dfaAlpha1History.push(this.dfaAlpha1);
    if (this.dfaAlpha1History.length > 50) this.dfaAlpha1History.shift();

    // Acceleration/Deceleration
    this.computeAccelerationCapacity();
    this.accelerationCapacityHistory.push(this.accelerationCapacity);
    if (this.accelerationCapacityHistory.length > 50) this.accelerationCapacityHistory.shift();
    this.decelerationCapacityHistory.push(this.decelerationCapacity);
    if (this.decelerationCapacityHistory.length > 50) this.decelerationCapacityHistory.shift();

    // Coherence and RSA
    this.computeCoherenceAndRSA();
    this.coherenceHistory.push(this.coherence);
    if (this.coherenceHistory.length > 50) this.coherenceHistory.shift();
    this.rsaAmplitudeHistory.push(this.rsaAmplitude);
    if (this.rsaAmplitudeHistory.length > 50) this.rsaAmplitudeHistory.shift();

    // Store new metric values into histories for weighting
    const now2 = Date.now();
    const metricValues = {
      sdnn: this.sdnn,
      rmssd: this.rmssd,
      pnn50: this.pnn50,
      decelerationCapacity: this.decelerationCapacity,
      coherence: this.coherence,
      rsaAmplitude: this.rsaAmplitude,
      dfaAlpha1: this.dfaAlpha1,
      approxEntropy: this.approxEntropy,
      sampleEntropy: this.sampleEntropy,
      accelerationCapacity: this.accelerationCapacity,
      bpm: bpm
    };
    Object.keys(metricValues).forEach(m => {
      this.metricHistories[m].push({ t: now2, v: metricValues[m] });
      const pruneCutoff = now2 - 120000;
      while (this.metricHistories[m].length && this.metricHistories[m][0].t < pruneCutoff) {
        this.metricHistories[m].shift();
      }
    });
    this.computeMetricActivityLevels();

    // ========= Update Relaxation Score =========
    this.updateRelaxationScore();
    this.relaxationScoreHistory.push(this.relaxationScore);
    if (this.relaxationScoreHistory.length > 50) {
      this.relaxationScoreHistory.shift();
    }
    if (this.relaxationScore > this.bestRelaxationScoreSoFar) {
      this.bestRelaxationScoreSoFar = this.relaxationScore;
    }    
    const eventInfo = this.detectStateChangeAdvanced();
    if (eventInfo && eventInfo.event !== 0) {    
      
      logEvent(`[DEBUG] Relaxation Change Detected: ${eventInfo.debugInfo}`);
      logEvent(`[MEDITATION-RELAXATION] ${eventInfo.meditationMsg}`);
      logEvent(`[Suggested relaxation TTSMessage] ${eventInfo.ttsMessage}`);
      
      let payload = {
        sessionId: this.sessionId,
        fullSpokenText: this.fullSpokenText,
        spokenTextHistory: this.spokenTextHistory,
        meditationMsg: eventInfo.meditationMsg,
        suggestedMessage: eventInfo.ttsMessage,
        currentBPM: this.getAverageBPM(),
        currentRelaxationScore: this.relaxationScore,
        lastNonPrioritySpokenText: this.lastNonPrioritySpokenText,
        isRelaxation: eventInfo.isRelaxation,
        allIndicatorsMatch: eventInfo.allIndicatorsMatch,
        switchedBetweenStressAndRelaxation: eventInfo.switchedBetweenStressAndRelaxation
      };

      //change currentRelaxation score to a %
      payload.currentRelaxationScore = Math.round(payload.currentRelaxationScore * 100);

      logEvent("Payload being sent to /api/meditationGPTTTS: " + payload);
      
      // Send the meditation message to the server so it can generate the TTS
      axios
        .post('/api/meditationGPTTTS', payload)
        .catch(err => console.error("Error sending meditation message:", err));
    }

    
    // After computing and updating relaxationScore (in updateRelaxationScore),
    // push the new value with the current time into the relaxationScoreBuffer.
    this.relaxationScoreBuffer.push({ time: now, score: this.relaxationScore });
    
    // (Optionally, prune old entries similarly to other buffers)
    const pruneCutoff = now - 120000;
    while (this.relaxationScoreBuffer.length && this.relaxationScoreBuffer[0].time < pruneCutoff) {
      this.relaxationScoreBuffer.shift();
    }

    // ========= NEW: Excitement Score update/detection =========
    this.updateExcitementScore();
    this.excitementScoreHistory.push(this.excitementScore);
    if (this.excitementScoreHistory.length > 50) {
      this.excitementScoreHistory.shift();
    }
    if (this.excitementScore > this.bestExcitementScoreSoFar) {
      this.bestExcitementScoreSoFar = this.excitementScore;
    }
    const exciteEventInfo = this.detectExcitementChangeAdvanced();
    if (exciteEventInfo && exciteEventInfo.event !== 0) {
      logEvent(`[DEBUG] Excitement Change Detected: ${exciteEventInfo.debugInfo}`);
      logEvent(`[MEDITATION-EXCITEMENT] ${exciteEventInfo.excitementMsg}`);
    }


    // Prepare additional short-window metrics for data row
    const shortBpm = this.shortWindowAverageBPM(15000) || this.getLatestBPM();
    const shortPnn50 = this.shortWindowAverageMetric(this.metricHistories.pnn50, 15000) || this.pnn50;
    const shortCoh   = this.shortWindowAverageMetric(this.metricHistories.coherence, 15000) || this.coherence;
    const shortSampEn= this.shortWindowAverageMetric(this.metricHistories.sampleEntropy, 15000) || this.sampleEntropy;

    // We'll also store normalized forms
    if (!this.metricRange.bpmRange) {
      this.metricRange.bpmRange = { min: Infinity, max: -Infinity };
    }
    this.updateMinMax('bpmRange', shortBpm);
    const normBPM   = this.normalizeMetric(shortBpm, 'bpmRange') || 0;
    const normPnn50 = this.normalizeMetric(shortPnn50, 'pnn50')  || 0;
    const normCoh   = this.normalizeMetric(shortCoh, 'coherence')|| 0;
    const normSampEn= this.normalizeMetric(shortSampEn,'sampleEntropy')|| 0;

    // Save data row (including both relaxation and NEW excitement fields)
    if (this.sessionId && this.sessionTimestamp) {
      const elapsedTimeMs = Date.now() - new Date(this.sessionTimestamp).getTime();
      const payload = {
        sessionId: this.sessionId,
        sessionTimestamp: this.sessionTimestamp,
        timestamp: elapsedTimeMs,
        bpm: this.getLatestBPM(),
        avgBPM: this.getAverageBPM(),
        lfHfRatio: this.lfHfRatio,
        lfOtherRatio: this.lfOtherRatio,
        rmssd: this.rmssd,
        sdnn: this.sdnn,
        pnn50: this.pnn50,
        apEntropy: this.approxEntropy,
        sampEn: this.sampleEntropy,
        dfaAlpha1: this.dfaAlpha1,
        accelerationCapacity: this.accelerationCapacity,
        decelerationCapacity: this.decelerationCapacity,
        coherence: this.coherence,
        rsaAmplitude: this.rsaAmplitude,
        hrvPercent: 0,
        shortWindowBPM: shortBpm,
        shortWindowPnn50: shortPnn50,
        shortWindowCoh: shortCoh,
        shortWindowSampEn: shortSampEn,
        normBPM: normBPM,
        normPnn50: normPnn50,
        normCoh: normCoh,
        normSampEn: normSampEn,

        avgRelaxDelta: this.avgRelaxationDelta,
        relaxationScore: this.relaxationScore,
        bestRelaxationSoFar: this.bestRelaxationScoreSoFar,
        eventTrigger: (this.lastEventInfo && this.lastEventInfo.event) || 0,
        meditationMsg: (this.lastEventInfo && this.lastEventInfo.meditationMsg) || null,

        // NEW: Excitement fields
        excitementScore: this.excitementScore,
        bestExcitementSoFar: this.bestExcitementScoreSoFar,
        excitementEventTrigger: (exciteEventInfo && exciteEventInfo.event) || 0,
        excitementMsg: (exciteEventInfo && exciteEventInfo.excitementMsg) || null,

        spokenText: this.currentSpokenText || "",
        videoPlaying: this.currentVideoPlaying || "",
        currentPrompt: this.currentPrompt || ""
      };

      fetch('/api/saveData', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
      .then(response => {
        if (!response.ok) {
          console.error('Failed to save data row to CSV.');
        }
      })
      .catch(err => {
        console.error('Error sending data row:', err);
      });
    }
  }

  // ====================================================
  // ======= Short-Window Helpers =======================
  // ====================================================
  shortWindowAverageBPM(windowMs = 15000) {
    const now = Date.now();
    const cutoff = now - windowMs;
    const relevant = this.bpmBuffer.filter(pt => pt.time >= cutoff);
    if (!relevant.length) return null;
    const sum = relevant.reduce((acc, pt) => acc + pt.bpm, 0);
    return sum / relevant.length;
  }
  shortWindowAverageMetric(historyArray, windowMs = 15000) {
    const now = Date.now();
    const cutoff = now - windowMs;
    const relevant = historyArray.filter(pt => pt.t >= cutoff);
    if (!relevant.length) return null;
    const sum = relevant.reduce((acc, pt) => acc + pt.v, 0);
    return sum / relevant.length;
  }

  // ====================================================
  // ======= Relaxation Score Update ====================
  // ====================================================
  updateRelaxationScore() {
    // Ensure BPM range exists
    if (!this.metricRange.bpmRange) {
      this.metricRange.bpmRange = { min: Infinity, max: -Infinity };
    }
    const shortBpm = this.shortWindowAverageBPM(15000) || this.getLatestBPM();
    this.updateMinMax('bpmRange', shortBpm);

    const shortPnn50 = this.shortWindowAverageMetric(this.metricHistories.pnn50, 15000) || this.pnn50;
    const shortCoh   = this.shortWindowAverageMetric(this.metricHistories.coherence, 15000) || this.coherence;
    const shortSamp  = this.shortWindowAverageMetric(this.metricHistories.sampleEntropy, 15000) || this.sampleEntropy;

    this.updateMinMax('pnn50', shortPnn50);
    this.updateMinMax('coherence', shortCoh);
    this.updateMinMax('sampleEntropy', shortSamp);

    const normBPM   = this.normalizeMetric(shortBpm, 'bpmRange');
    const normPnn50 = this.normalizeMetric(shortPnn50, 'pnn50');
    const normCoh   = this.normalizeMetric(shortCoh, 'coherence');
    const normSamp  = this.normalizeMetric(shortSamp, 'sampleEntropy');

    // Example formula: calm = pnn50 + coherence + (1 - entropy); stress = BPM
    this.relaxationScore = (normBPM + (1 - ((normPnn50 + normCoh + (1 - normSamp)) / 3))) / 2;
  }

  // ====================================================
  // ======= NEW: Excitement Score Update =================
  // ====================================================
  updateExcitementScore() {
    const shortBpm = this.shortWindowAverageBPM(15000) || this.getLatestBPM();
    const shortCoh = this.shortWindowAverageMetric(this.metricHistories.coherence, 15000) || this.coherence;
    const shortSamp = this.shortWindowAverageMetric(this.metricHistories.sampleEntropy, 15000) || this.sampleEntropy;

    this.updateMinMax('bpmRange', shortBpm);
    this.updateMinMax('coherence', shortCoh);
    this.updateMinMax('sampleEntropy', shortSamp);

    const normBpm  = this.normalizeMetric(shortBpm, 'bpmRange');
    const normCoh  = this.normalizeMetric(shortCoh, 'coherence');
    const normSamp = this.normalizeMetric(shortSamp, 'sampleEntropy');

    const w1 = 0.5; // coherence weight
    const w2 = 0.4; // BPM weight
    const w3 = 0.3; // sampleEntropy penalty weight

    // Higher entropy reduces excitement
    this.excitementScore = (w1 * normCoh) + (w2 * normBpm) - (w3 * normSamp);
    // Optionally clamp to [0,1] if desired:
    // this.excitementScore = Math.max(0, Math.min(1, this.excitementScore));
  }

  // ====================================================
  // ======= NEW: Excitement Event Detection =============
  // ====================================================
  detectExcitementChangeAdvanced() {
    const now = Date.now();
    const shortMs = 15000;
    const oldMs   = 30000;
    const shortVal = this.getShortWindowExcitement(shortMs);
    const oldVal   = this.getShortWindowExcitement(shortMs + oldMs);
    if (shortVal === null || oldVal === null) {
      return { event: 0 };
    }
    const delta = (shortVal - oldVal) / (Math.abs(oldVal) + 1e-6);
    this.excitementDeltaBuffer.push(delta);
    if (this.excitementDeltaBuffer.length > this.excitementSustainedBufferSize) {
      this.excitementDeltaBuffer.shift();
    }
    if (this.excitementDeltaBuffer.length < this.excitementSustainedBufferSize) {
      return { event: 0 };
    }
    const threshold = 0.05;
    const allPos = this.excitementDeltaBuffer.every(d => d > threshold);
    const allNeg = this.excitementDeltaBuffer.every(d => d < -threshold);
    const timeSinceLast = now - this.lastExcitementEventTime;
    const allowedByTime = timeSinceLast >= this.excitementMinEventInterval;
    const forcedByTime  = timeSinceLast >= this.excitementMaxEventInterval;
    
    let eventCode = 0;
    let desc = '';
    let excitementMsg = '';
    if (allowedByTime || forcedByTime) {
      if (allPos) {
        eventCode = +1;
        desc = `Sustained excitement rise ~ +${(delta*100).toFixed(1)}% from older window.`;
        excitementMsg = this.buildExcitementMessage(eventCode, delta, forcedByTime);
      } else if (allNeg) {
        eventCode = -1;
        desc = `Sustained excitement drop ~ ${(delta*100).toFixed(1)}% from older window.`;
        excitementMsg = this.buildExcitementMessage(eventCode, delta, forcedByTime);
      } else if (forcedByTime) {
        if (delta > 0) {
          eventCode = +1;
          desc = `Mild excitement rise (forced by time). ~ +${(delta*100).toFixed(1)}%`;
          excitementMsg = this.buildExcitementMessage(eventCode, delta, true);
        } else {
          eventCode = -1;
          desc = `Mild excitement drop (forced by time). ~ ${(delta*100).toFixed(1)}%`;
          excitementMsg = this.buildExcitementMessage(eventCode, delta, true);
        }
      }
    }
    if (eventCode !== 0) {
      if (eventCode === this.lastExcitementEventType && !forcedByTime) {
        if (timeSinceLast < 60000) return { event: 0 };
      }
      this.lastExcitementEventTime = now;
      this.lastExcitementEventType = eventCode;
      this.excitementLastEventInfo = {
        event: eventCode,
        debugInfo: desc,
        excitementMsg
      };
      return this.excitementLastEventInfo;
    }
    return { event: 0 };
  }
  getShortWindowExcitement(windowMs) {
    const now = Date.now();
    const cutoff = now - windowMs;
    // For simplicity, use all excitementScoreHistory values (a more robust solution would timestamp these)
    if (!this.excitementScoreHistory.length) return null;
    const sum = this.excitementScoreHistory.reduce((a, b) => a + b, 0);
    return sum / this.excitementScoreHistory.length;
  }
  buildExcitementMessage(eventType, avgDelta, forced = false) {
    let msg = '';
    const improvedOverBest = (this.excitementScore > this.bestExcitementScoreSoFar);
    if (eventType > 0) {
      msg = "Sensing a rising positive energy. ";
      if (improvedOverBest) {
        msg += "This is the highest excitement you've reached so far. ";
      }
      msg += forced
        ? "Steadily keep this momentum—enjoy the uplifting energy!"
        : "Great job amplifying your vitality and keeping it positive.";
    } else {
      msg = "Your energetic state seems to be dipping. ";
      msg += forced
        ? "It’s been a while—try a quick breath or visualization to boost energy."
        : "If you want more spark, consider a brisk breath technique or positive imagery.";
    }
    return msg.trim();
  }

  // ====================================================
  // ======= Utility Functions ==========================
  // ====================================================
  updateMinMax(metricName, currentValue) {
    if (!this.metricRange[metricName]) {
      this.metricRange[metricName] = { min: Infinity, max: -Infinity };
    }
    const r = this.metricRange[metricName];
    if (currentValue < r.min) r.min = currentValue;
    if (currentValue > r.max) r.max = currentValue;
  }
  normalizeMetric(value, metricName) {
    const range = this.metricRange[metricName];
    if (!range) return 0.5;
    const span = range.max - range.min;
    if (span < 1e-9) return 0.5;
    return (value - range.min) / span;
  }

  // ====================================================
  // ======= Basic UI Getters ===========================
  // ====================================================
  getLatestBPM() {
    if (!this.bpmBuffer.length) return 0;
    return Math.round(this.bpmBuffer[this.bpmBuffer.length - 1].bpm);
  }
  getAverageBPM() {
    if (!this.bpmBuffer.length) return 0;
    const sum = this.bpmBuffer.reduce((acc, o) => acc + o.bpm, 0);
    return Math.round(sum / this.bpmBuffer.length);
  }
  getRelaxationScore() {
    return this.relaxationScore;
  }
  getLastEventInfo() {
    return this.lastEventInfo;
  }
  getExcitementScore() {
    return this.excitementScore;
  }
  getExcitementLastEventInfo() {
    return this.excitementLastEventInfo;
  }

  
  // ====================================================
  // ======= Compute Methods ============================
  // ====================================================
  computeFrequencySpectrumInterpolated() {
    if (this.timestamps.length < 2) {
      this.freqSpectrumInterpolated = [];
      return;
    }
    const endTime = this.timestamps[this.timestamps.length - 1];
    const startTime = Math.max(this.timestamps[0], endTime - this.windowMs);
    const windowDurationSec = (endTime - startTime) / 1000;
    const dt = 1 / this.samplingRate;
    let N = Math.round(windowDurationSec * this.samplingRate);
    if (N < 8) {
      this.freqSpectrumInterpolated = [];
      return;
    }
    let targetN = Math.pow(2, Math.ceil(Math.log2(N)));
    if (targetN < 256) targetN = 256;
    N = targetN;
    const signal = new Array(N);
    let t0Sec = endTime / 1000 - windowDurationSec;
    let beatIndex = 0;
    for (let i = 0; i < N; i++) {
      const currentTime = t0Sec + i * dt;
      while (beatIndex < this.timestamps.length && this.timestamps[beatIndex] / 1000 <= currentTime) {
        beatIndex++;
      }
      if (beatIndex === 0) {
        signal[i] = this.intervalsSec[0];
      } else if (beatIndex >= this.timestamps.length) {
        signal[i] = this.intervalsSec[this.intervalsSec.length - 1];
      } else {
        signal[i] = this.intervalsSec[beatIndex];
      }
    }
    const phasors = fft(signal);
    const freqs = fftUtil.fftFreq(phasors, this.samplingRate);
    const mags = fftUtil.fftMag(phasors);
    const half = Math.floor(freqs.length / 2);
    const spec = [];
    for (let i = 1; i <= half; i++) {
      if (freqs[i] > 0.5) break;
      spec.push({
        frequency: freqs[i],
        power: mags[i]
      });
    }
    this.freqSpectrumInterpolated = spec;
  }
  computeLegacyFFT() {
    if (this.timestamps.length < 8) {
      this.legacyFFT = [];
      return;
    }
    let N = 256;
    let sliceStart = Math.max(0, this.intervalsSec.length - N);
    let intervals = this.intervalsSec.slice(sliceStart, sliceStart + N);
    if (intervals.length < N) {
      const padNeeded = N - intervals.length;
      intervals = new Array(padNeeded).fill(intervals[0]).concat(intervals);
    }
    const baseFreq = 1.0;
    const applyCutoffs = null;
    const constantFreq = -1;
    const result = legacyFftCompute(intervals, baseFreq, applyCutoffs, constantFreq);
    let spec = [];
    for (let i = 1; i < result.fftResult.length; i++) {
      spec.push({
        frequency: result.freqArray[i],
        power: result.fftResult[i]
      });
    }
    this.legacyFFT = spec;
  }
  computeFrequencySpectrumRaw() {
    if (this.intervalsSec.length < 2) {
      this.freqSpectrumRaw = [];
      return;
    }
    const signal = this.intervalsSec.slice();
    let N = signal.length;
    const targetN = 256;
    if (N < targetN) {
      const lastVal = signal[signal.length - 1];
      while (signal.length < targetN) {
        signal.push(lastVal);
      }
      N = targetN;
    }
    const sr = 1.0;
    const phasors = fft(signal);
    const freqs = fftUtil.fftFreq(phasors, sr);
    const mags = fftUtil.fftMag(phasors);
    const half = Math.floor(freqs.length / 2);
    const spec = [];
    for (let i = 1; i <= half; i++) {
      if (freqs[i] <= 0) continue;
      if (freqs[i] > 0.5) break;
      spec.push({ frequency: freqs[i], power: mags[i] });
    }
    this.freqSpectrumRaw = spec;
  }
  evaluateStress() {
    const spec = this.freqSpectrumInterpolated;
    if (!spec.length) {
      this.lfHfRatio = 0;
      this.lfOtherRatio = 0;
      return;
    }
    let lfPower = 0, hfPower = 0, totalPower = 0;
    for (const p of spec) {
      const f = p.frequency;
      if (f >= 0.04 && f < 0.15) {
        lfPower += p.power;
      } else if (f >= 0.15 && f <= 0.4) {
        hfPower += p.power;
      }
      totalPower += p.power;
    }
    this.lfHfRatio = (hfPower > 0) ? lfPower / hfPower : Number.POSITIVE_INFINITY;
    const otherPower = totalPower - lfPower;
    this.lfOtherRatio = (otherPower > 0) ? lfPower / otherPower : Number.POSITIVE_INFINITY;
  }
  computeTimeDomainHRV() {
    const ibiArr = this.getIbiMsArray();
    const n = ibiArr.length;
    if (n < 2) {
      this.rmssd = 0;
      this.sdnn = 0;
      this.pnn50 = 0;
      return;
    }
    const mean = ibiArr.reduce((a, b) => a + b, 0) / n;
    let variance = 0;
    for (let i = 0; i < n; i++){
      let diff = ibiArr[i] - mean;
      variance += diff * diff;
    }
    this.sdnn = Math.sqrt(variance / n);
    let sumSq = 0, countDiff50 = 0, totalPairs = 0;
    for (let i = 1; i < n; i++) {
      let diff = ibiArr[i] - ibiArr[i - 1];
      sumSq += diff * diff;
      if (Math.abs(diff) > 50) countDiff50++;
      totalPairs++;
    }
    if (totalPairs > 0) {
      this.rmssd = Math.sqrt(sumSq / totalPairs);
      this.pnn50 = (countDiff50 / totalPairs) * 100.0;
    } else {
      this.rmssd = 0;
      this.pnn50 = 0;
    }
  }
  computeNonlinearMetrics() {
    const ibiArr = this.getIbiMsArray();
    if (ibiArr.length < 20) {
      this.approxEntropy = 0;
      this.sampleEntropy = 0;
      this.dfaAlpha1 = 0;
      return;
    }
    this.approxEntropy = this.computeApEn(ibiArr, 2, 0.2);
    this.sampleEntropy = this.computeSampEn(ibiArr, 2, 0.2);
    let alpha = this.computeDFAalpha1(ibiArr);
    this.dfaAlpha1 = alpha || 0;
  }
  computeApEn(dataArr, m = 2, rFactor = 0.2) {
    if (dataArr.length < m + 2) return 0;
    let r = rFactor * this.sd(dataArr);
    let phi_m  = this._phi(dataArr, m, r);
    let phi_m1 = this._phi(dataArr, m + 1, r);
    return phi_m - phi_m1;
  }
  _phi(data, m, r) {
    let N = data.length;
    let max_i = N - m + 1;
    if (max_i <= 0) return 0;
    let C = new Array(max_i).fill(0);
    for (let i = 0; i < max_i; i++){
      for (let j = 0; j < max_i; j++){
        let match = true;
        for (let k = 0; k < m; k++){
          if (Math.abs(data[i + k] - data[j + k]) > r){
            match = false; break;
          }
        }
        if (match) C[i]++;
      }
    }
    let sum_log = 0;
    for (let i = 0; i < max_i; i++){
      let ratio = C[i] / max_i;
      if (ratio > 0) sum_log += Math.log(ratio);
    }
    return sum_log / max_i;
  }
  computeSampEn(dataArr, m = 2, rFactor = 0.2) {
    let N = dataArr.length;
    if (N < m + 2) return 0;
    let r = this.sd(dataArr) * rFactor;
    let A = 0, B = 0;
    for (let i = 0; i < N - m; i++){
      for (let j = i + 1; j < N - m; j++){
        let match_m = true;
        for (let k = 0; k < m; k++){
          if (Math.abs(dataArr[i + k] - dataArr[j + k]) > r){
            match_m = false; break;
          }
        }
        if (match_m) {
          B++;
          if (Math.abs(dataArr[i + m] - dataArr[j + m]) <= r) A++;
        }
      }
    }
    if (!B) return 0;
    return -Math.log(A / B);
  }
  computeDFAalpha1(dataArr) {
    let N = dataArr.length;
    if (N < 20) return null;
    let mean = dataArr.reduce((a, b) => a + b, 0) / N;
    let y = [], cum = 0;
    for (let i = 0; i < N; i++){
      cum += (dataArr[i] - mean);
      y.push(cum);
    }
    let scales = [4, 6, 8, 10, 12, 14, 16];
    let fluct = [];
    for (let s of scales){
      if (s >= N) break;
      let segCount = 0, segRmsSum = 0;
      for (let start = 0; start < N; start += s){
        if (start + s > N) break;
        let seg = y.slice(start, start + s);
        let segLen = seg.length;
        let first = seg[0], last = seg[segLen - 1];
        let slope = (last - first) / (segLen - 1);
        for (let k = 0; k < segLen; k++){
          seg[k] -= (first + slope * k);
        }
        let sumSq = seg.reduce((sum, val) => sum + val * val, 0);
        segRmsSum += (sumSq / segLen);
        segCount++;
      }
      if (segCount > 0) {
        let rms = Math.sqrt(segRmsSum / segCount);
        fluct.push({ scale: s, rms });
      }
    }
    if (fluct.length < 2) return null;
    let sumX = 0, sumY = 0, sumXY = 0, sumX2 = 0;
    for (let f of fluct) {
      let lx = Math.log(f.scale);
      let ly = Math.log(f.rms);
      sumX += lx; sumY += ly;
      sumXY += lx * ly; sumX2 += lx * lx;
    }
    let mVal = fluct.length;
    let numerator = (mVal * sumXY - sumX * sumY);
    let denom = (mVal * sumX2 - sumX * sumX);
    if (!denom) return null;
    return numerator / denom;
  }
  computeAccelerationCapacity() {
    let ibiArr = this.getIbiMsArray();
    if (ibiArr.length < 2) {
      this.accelerationCapacity = 0;
      this.decelerationCapacity = 0;
      return;
    }
    let totalPos = 0, countPos = 0, totalNeg = 0, countNeg = 0;
    for (let i = 1; i < ibiArr.length; i++){
      let diff = ibiArr[i] - ibiArr[i - 1];
      if (diff > 0) { totalPos += diff; countPos++; }
      else if (diff < 0) { totalNeg += Math.abs(diff); countNeg++; }
    }
    this.decelerationCapacity = (countPos > 0) ? (totalPos / countPos) : 0;
    this.accelerationCapacity = (countNeg > 0) ? (totalNeg / countNeg) : 0;
  }
  computeCoherenceAndRSA() {
    if (!this.freqSpectrumInterpolated.length) {
      this.coherence = 0;
      this.rsaAmplitude = 0;
      return;
    }
    let spec = this.freqSpectrumInterpolated;
    let totalPower = 0;
    let maxPower = 0;
    let hfPower   = 0;
    for (let point of spec) {
      if (point.frequency >= 0.04 && point.frequency <= 0.4) {
        totalPower += point.power;
        if (point.power > maxPower) maxPower = point.power;
      }
      if (point.frequency >= 0.15 && point.frequency <= 0.4) {
        hfPower += point.power;
      }
    }
    if (!totalPower) {
      this.coherence = 0;
      this.rsaAmplitude = 0;
      return;
    }
    this.coherence = maxPower / totalPower;
    this.rsaAmplitude = Math.sqrt(hfPower);
  }
  getIbiMsArray() {
    return this.intervalsSec.map(sec => sec * 1000);
  }
  sd(arr) {
    if (!arr.length) return 0;
    let mean = arr.reduce((a, b) => a + b, 0) / arr.length;
    let sumSq = arr.reduce((sum, v) => {
      let d = v - mean;
      return sum + d * d;
    }, 0);
    return Math.sqrt(sumSq / arr.length);
  }

  // ====================================================
  // ======= Additional Getters (from old code) =========
  // ====================================================
  getBPMHistory() {
    return [...this.bpmBuffer];
  }
  getNormalizedAvgBPM() {
    return Math.round(this.normalizedAvgBPM);
  }
  getLFHFRatio() {
    return this.lfHfRatio;
  }
  getLFOtherRatio() {
    return this.lfOtherRatio;
  }
  getLFHFRatioHistory() {
    return this.lfHfHistory.slice();
  }
  getLFOtherHistory() {
    return this.lfOtherHistory.slice();
  }
  isStressed() {
    return this.relaxationScore > 0.6;
  }
  getRecentBPMData() {
    return this.bpmBuffer.slice();
  }
  getFrequencyDataInterpolated() {
    return this.freqSpectrumInterpolated.slice();
  }
  getFrequencyDataRaw() {
    return this.freqSpectrumRaw.slice();
  }
  getLegacyFFT() {
    return this.legacyFFT ? this.legacyFFT.slice() : [];
  }
  // Time-domain HRV getters
  getRMSSD() { return this.rmssd; }
  getSDNN() { return this.sdnn; }
  getPNN50() { return this.pnn50; }
  // Nonlinear getters
  getApproxEntropy() { return this.approxEntropy; }
  getSampleEntropy() { return this.sampleEntropy; }
  getDFAalpha1() { return this.dfaAlpha1; }
  // Accel/Decel getters
  getAccelerationCapacity() { return this.accelerationCapacity; }
  getDecelerationCapacity() { return this.decelerationCapacity; }
  // Coherence / RSA getters
  getCoherenceScore() { return this.coherence; }
  getRSAAmplitude() { return this.rsaAmplitude; }
  // Histories
  getRMSSDHistory() { return this.rmssdHistory.slice(); }
  getSDNNHistory() { return this.sdnnHistory.slice(); }
  getPNN50History() { return this.pnn50History.slice(); }
  getApproxEntropyHistory() { return this.approxEntropyHistory.slice(); }
  getSampleEntropyHistory() { return this.sampleEntropyHistory.slice(); }
  getDFAalpha1History() { return this.dfaAlpha1History.slice(); }
  getAccelerationCapacityHistory() { return this.accelerationCapacityHistory.slice(); }
  getDecelerationCapacityHistory() { return this.decelerationCapacityHistory.slice(); }
  getCoherenceHistory() { return this.coherenceHistory.slice(); }
  getRSAAmplitudeHistory() { return this.rsaAmplitudeHistory.slice(); }
  getRelaxationScoreHistory() { return this.relaxationScoreHistory.slice(); }
  getExcitementScoreHistory() { return this.excitementScoreHistory.slice(); }

  // ====================================================
  // ======= Additional Window Computations =============
  // ====================================================
  computeLFOtherRatioForWindow(windowMs) {
    if (this.timestamps.length < 2) return 0;
    const endTime = this.timestamps[this.timestamps.length - 1];
    const startTime = Math.max(this.timestamps[0], endTime - windowMs);
    const windowDurationSec = (endTime - startTime) / 1000;
    const dt = 1 / this.samplingRate;
    let N = Math.round(windowDurationSec * this.samplingRate);
    if (N < 8) return 0;
    let targetN = Math.pow(2, Math.ceil(Math.log2(N)));
    if (targetN < 256) targetN = 256;
    N = targetN;
    const signal = new Array(N);
    let t0Sec = endTime / 1000 - windowDurationSec;
    let beatIndex = 0;
    for (let i = 0; i < N; i++) {
      const currentTime = t0Sec + i * dt;
      while (beatIndex < this.timestamps.length && this.timestamps[beatIndex] / 1000 <= currentTime) {
        beatIndex++;
      }
      if (beatIndex === 0) {
        signal[i] = this.intervalsSec[0];
      } else if (beatIndex >= this.timestamps.length) {
        signal[i] = this.intervalsSec[this.intervalsSec.length - 1];
      } else {
        signal[i] = this.intervalsSec[beatIndex];
      }
    }
    const phasors = fft(signal);
    const freqs = fftUtil.fftFreq(phasors, this.samplingRate);
    const mags = fftUtil.fftMag(phasors);
    const half = Math.floor(freqs.length / 2);
    const spec = [];
    for (let i = 1; i <= half; i++) {
      if (freqs[i] > 0.5) break;
      spec.push({ frequency: freqs[i], power: mags[i] });
    }
    let lfPower = 0, totalPower = 0;
    for (const p of spec) {
      const f = p.frequency;
      if (f >= 0.04 && f < 0.15) lfPower += p.power;
      totalPower += p.power;
    }
    const otherPower = totalPower - lfPower;
    return (otherPower > 0) ? lfPower / otherPower : Number.POSITIVE_INFINITY;
  }
  computeCoherenceForWindow(windowMs) {
    if (this.timestamps.length < 2) return 0;
    const endTime = this.timestamps[this.timestamps.length - 1];
    const startTime = Math.max(this.timestamps[0], endTime - windowMs);
    const windowDurationSec = (endTime - startTime) / 1000;
    const dt = 1 / this.samplingRate;
    let N = Math.round(windowDurationSec * this.samplingRate);
    if (N < 8) return 0;
    let targetN = Math.pow(2, Math.ceil(Math.log2(N)));
    if (targetN < 256) targetN = 256;
    N = targetN;
    const signal = new Array(N);
    let t0Sec = endTime / 1000 - windowDurationSec;
    let beatIndex = 0;
    for (let i = 0; i < N; i++) {
      const currentTime = t0Sec + i * dt;
      while (beatIndex < this.timestamps.length && this.timestamps[beatIndex] / 1000 <= currentTime) {
        beatIndex++;
      }
      if (beatIndex === 0) {
        signal[i] = this.intervalsSec[0];
      } else if (beatIndex >= this.timestamps.length) {
        signal[i] = this.intervalsSec[this.intervalsSec.length - 1];
      } else {
        signal[i] = this.intervalsSec[beatIndex];
      }
    }
    const phasors = fft(signal);
    const freqs = fftUtil.fftFreq(phasors, this.samplingRate);
    const mags = fftUtil.fftMag(phasors);
    const half = Math.floor(freqs.length / 2);
    const spec = [];
    for (let i = 1; i <= half; i++) {
      if (freqs[i] > 0.5) break;
      spec.push({ frequency: freqs[i], power: mags[i] });
    }
    let totalPower = 0, maxPower = 0;
    for (let point of spec) {
      if (point.frequency >= 0.04 && point.frequency <= 0.4) {
        totalPower += point.power;
        if (point.power > maxPower) maxPower = point.power;
      }
    }
    return totalPower ? (maxPower / totalPower) : 0;
  }
// ======= Main advanced state-change detection =======
detectStateChangeAdvanced() {
  const now = Date.now();
  // Clean up old
  const cutoff = now - 120000;
  while (this.eventHrHistory.length && this.eventHrHistory[0].time < cutoff) {
    this.eventHrHistory.shift();
  }
  if (!this.eventHrHistory.length) return { event: 0 };

  // Short vs old
  const shortMs = 15000;
  const oldMs   = 30000;
  const bpmDelta   = this.getMetricChange('bpm', shortMs, oldMs);
  const pnn50Delta = this.getMetricChange('pnn50', shortMs, oldMs);
  const cohDelta   = this.getMetricChange('coherence', shortMs, oldMs);
  const sampDelta  = this.getMetricChange('sampleEntropy', shortMs, oldMs);

  let netDelta = 0, count = 0;
  if (bpmDelta !== null)  { netDelta -= bpmDelta;  count++; }
  if (pnn50Delta !== null){ netDelta += pnn50Delta;count++; }
  if (cohDelta !== null)  { netDelta += cohDelta;  count++; }
  if (sampDelta !== null) { netDelta += sampDelta; count++; }
  if (!count) return { event: 0 };
  const avgDelta = netDelta / count;
  this.avgRelaxationDelta = avgDelta;
  
  // Use updated thresholds
  const relaxationThreshold = 0.2;
  const tensionThreshold    = -0.1;

  // Push into our “sustained” buffer
  this.recentDeltaBuffer.push(avgDelta);
  if (this.recentDeltaBuffer.length > this.sustainedBufferSize) {
    this.recentDeltaBuffer.shift();
  }
  // Check if all have same sign & exceed threshold
  const threshold = 0.05; 
  if (this.recentDeltaBuffer.length < this.sustainedBufferSize) {
    // not enough consecutive data to confirm
    return { event: 0 };
  }
  // require sign consistency: e.g., all positive or all negative
  const allPos = this.recentDeltaBuffer.every(d => d > threshold);
  const allNeg = this.recentDeltaBuffer.every(d => d < -threshold);

  // Check time constraints
  const timeSinceLast = (now - this.lastEventTime);
  const allowedByTime = (timeSinceLast >= this.minEventInterval);
  const forcedByTime  = (timeSinceLast >= this.maxEventInterval);

  let eventCode = 0;
  let desc = '';
  let meditationMsg = '';
  let messages = [];  
  let isRelaxation = true;
  let allIndicatorsMatch = true;
  let switchedBetweenStressAndRelaxation = false;

  //if we haven't spoken anything yet, don't send anything.  in fact we should have spoken at least two things
  if (this.spokenTextHistory.length >= 2 && !this.meditationComplete) {
    if (allowedByTime || forcedByTime) {
      if (allPos) {
        if (this.lastEventDirection != 1) {
          switchedBetweenStressAndRelaxation = true;
        }
        allIndicatorsMatch = true;
        isRelaxation = true;
        this.lastEventDirection = 1;
        eventCode = +1;
        desc = `Sustained relaxation: allDeltas ~ +${(avgDelta * 100).toFixed(1)}%`;
        meditationMsg = this.buildMeditationMessage(1, avgDelta);

        messages = [
          "You've been resting calmly for a while now; you're doing great.",
          "Feel how deeply relaxed your body is.",
          "You're fully at ease; just continue to rest in this stillness.",
          "There's nothing else you need to do right now — just be.",
          "Feel free to stay with this peaceful feeling.",
          "Simply remain in this quiet moment.",
          "Continue enjoying this tranquility.",
          "As you stay in this peaceful state, your relaxation naturally deepens."
        ];
      }
      else if (allNeg) {
        if (this.lastEventDirection != -1) {
          switchedBetweenStressAndRelaxation = true;
        }
        allIndicatorsMatch = true;
        isRelaxation = false;
        this.lastEventDirection = -1;
        eventCode = -1;
        desc = `Sustained stress: allDeltas ~ ${(avgDelta * 100).toFixed(1)}%`;
        meditationMsg = this.buildMeditationMessage(-1, avgDelta);
        
          
        messages = [
          "You are safe. Let’s take a deep breath together, and feel the ground steady beneath you.",
          "Let's focus on relaxing a little deeper. Stay with your breath now. Inhale deeply — fill your lungs with calm — and exhale slowly, releasing the tension.",
          "Stress can be intense, but it will pass. Feel your feet on the floor. Take another deep breath, and let the heaviness flow out on the exhale.",
          "You’re not alone in this moment. Breathe in strength, and breathe out any fear or strain, one breath at a time.",
          "Keep breathing steadily. With each inhale, invite in peace; with each exhale, imagine the stress melting away.",
          "If your mind wanders, gently bring your attention back to your breath.",
          "Notice the air entering your lungs, and let each exhale release a bit more tension.",
          "Allow any thoughts to simply drift by, without attaching to them.",
          "With each inhale, imagine calm filling you; with each exhale, imagine stress melting away.",
          "Allow your shoulders and jaw to soften a bit more on the next out-breath.",
          "Stay present with whatever you’re feeling right now, welcoming each experience.",
          "Direct kind attention to yourself, as you would to a dear friend, in this practice.",
          "Feel free to adjust your posture anytime to stay comfortable and relaxed.",
        ];
      }
      else if (avgDelta > relaxationThreshold) {        
        if (this.lastEventDirection != 1) {
          switchedBetweenStressAndRelaxation = true;
        }
        allIndicatorsMatch = false;
        isRelaxation = true;
        this.lastEventDirection = 1;
        eventCode = +1;
        desc = `Mild relaxation (forced by time). avgΔ=+${(avgDelta*100).toFixed(1)}%`;
        meditationMsg = this.buildMeditationMessage(1, avgDelta, true);
        messages = [
          "You're beginning to relax; this is a great start.",
          "Your body is starting to let go of tension.",
          "Feel any tension melting away, bit by bit.",
          "Your breathing is calm and steady.",
          "Feel the first wave of calm washing over you.",
          "A gentle stillness is filling your mind; you're doing well.",
          "Take your time to settle into this calm moment.",
          "Allow this newfound ease to gently deepen."
        ];
      } else if (avgDelta < tensionThreshold) {
        if (this.lastEventDirection != -1) {
          switchedBetweenStressAndRelaxation = true;
        }
        allIndicatorsMatch = false;
        isRelaxation = false;
        this.lastEventDirection = -1;
        eventCode = -1;
        desc = `Mild stress (forced by time). avgΔ=${(avgDelta*100).toFixed(1)}%`;
        meditationMsg = this.buildMeditationMessage(-1, avgDelta, true);
        messages = [
          "It’s okay to feel a little tense. Take a calm, slow breath and allow that tension to soften.",
          "Notice any small discomfort, and let it be. Gently inhale peace, and exhale any worry.",
          "You might sense some stress – that’s alright. As you breathe out slowly, imagine your body loosening and relaxing.",
          "Everything is fine. Give yourself permission to pause, and let your shoulders drop with each gentle breath.",
          "If your mind wanders, gently bring your attention back to your breath.",
          "Notice the air entering your lungs, and let each exhale release a bit more tension.",
          "Allow any thoughts to simply drift by, without attaching to them.",
          "Feel free to adjust your posture anytime to stay comfortable and relaxed.",
          "Tune in to the sensations in your body, just observing them softly.",
          "Invite a sense of warmth and compassion into your heart as you breathe.",
          "You are doing well – just continue to follow the guidance at your own pace.",
          "Sense the gentle rise and fall of your belly with every breath, anchoring you here.",
          "Stay present with whatever you’re feeling right now, welcoming each experience.",
          "Direct kind attention to yourself, as you would to a dear friend, in this practice."
        ];
      }
    }

    let ttsMessage = messages[Math.floor(Math.random() * messages.length)];

    if (eventCode !== 0) {
      // Simple hysteresis: if it's the same event type as last time, we might skip 
      // (unless forced or big difference). For simplicity, we proceed if forcedByTime or sign changed
      if (eventCode === this.lastEventType && !forcedByTime) {
        // If the user is continuing the same direction, decide if that’s redundant
        // Could skip or refine. For now, we’ll just handle it in the “desc” and proceed if we want less spam.
        // Example: skip if same event repeated:
        if (timeSinceLast < 60000) {
          return { event: 0 }; // skip if <1 min from last same direction
        }
      }
      // Accept the event
      this.lastEventTime = now;
      this.lastEventType = eventCode;
      this.lastEventInfo = {
        event: eventCode,
        debugInfo: desc,
        meditationMsg,
        ttsMessage,
        isRelaxation,
        allIndicatorsMatch,
        switchedBetweenStressAndRelaxation
      };
      return this.lastEventInfo;
    }
  } else {
    //we haven't gone on long enough in the meditation to start timers
    this.lastEventTime = now;
  }

  return { event: 0 };
}

  // Helper: compute metric change (relative) 
 
getMetricChange(metric, shortMs, oldMs) {
  let shortVal = null, oldVal = null;
  if (metric === 'bpm') {
    shortVal = this.shortWindowAverageBPM(shortMs);
    oldVal   = this.shortWindowAverageBPM(shortMs + oldMs);
  } else if (metric === 'pnn50') {
    shortVal = this.shortWindowAverageMetric(this.metricHistories.pnn50, shortMs);
    oldVal   = this.shortWindowAverageMetric(this.metricHistories.pnn50, shortMs + oldMs);
  } else if (metric === 'coherence') {
    shortVal = this.shortWindowAverageMetric(this.metricHistories.coherence, shortMs);
    oldVal   = this.shortWindowAverageMetric(this.metricHistories.coherence, shortMs + oldMs);
  } else if (metric === 'sampleEntropy') {
    shortVal = this.shortWindowAverageMetric(this.metricHistories.sampleEntropy, shortMs);
    oldVal   = this.shortWindowAverageMetric(this.metricHistories.sampleEntropy, shortMs + oldMs);
  }
  if (shortVal === null || oldVal === null) return null;
  return (shortVal - oldVal) / (Math.abs(oldVal) + 1e-6);
}



  // Build a short message for GPT referencing user’s best so far
  
  
  buildMeditationMessage(eventType, avgDelta, forced = false) {
    let msg = '';
    const improvementOverBest = (this.relaxationScore < (this.bestRelaxationScoreSoFar || Infinity) - 0.01);
    if (eventType > 0) {
      msg = "Signs of deeper calm detected. ";
      if (improvementOverBest) msg += "This looks even calmer than your previous best moment. ";
      msg += forced
        ? "Mild improvement recognized over time – maintain your gentle focus."
        : "Keep breathing slowly and enjoy this deeper ease.";
    } else {
      msg = "We sense rising tension. ";
      msg += forced
        ? "It’s been a while without improvement, let's refocus on slow breathing."
        : "Consider slowing the breath and softening any tightness in your body.";
    }
    return msg.trim();
  }
  // ======= Utility =======
  getIbiMsArray() {
    return this.intervalsSec.map(sec => sec * 1000);
  }
  sd(arr) {
    if (!arr.length) return 0;
    let mean = arr.reduce((a, b) => a + b, 0) / arr.length;
    let sumSq = arr.reduce((sum, v) => {
      let d = v - mean;
      return sum + d * d;
    }, 0);
    return Math.sqrt(sumSq / arr.length);
  }
}

export default HeartRateAnalysis;
